import {
  FindPatientOrders,
  CreateOrder,
  FindPatientOrdersWithObservations,
  OrderFragment,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'

import { ATTACHMENT_FRAGMENT } from 'src/components/atoms/AttachmentDisplay/AttachmentDisplay'
import { orderIsPended } from 'src/data/orders'
import {
  OBSERVATION_FOR_ORDER_FRAGMENT,
  ORDER_SCHEDULING_CASE_FRAGMENT,
} from 'src/pages/PatientChartsPage/PatientVisits/fragments'
import {
  PATIENT_GUARDIAN_DETAILS_FRAGMENT,
  SIGNING_PRACTITIONER_FRAGMENT,
  TENANT_LETTERHEAD_FRAGMENT,
} from 'src/pdf/fragments'

export const ORDER_FRAGMENT = gql`
  fragment OrderFragment on Order {
    id
    category
    isPotentiallyBillable
    name
    code
    encounterReference
    encounterId
    freeTextCode
    codeDisplay
    status
    intent
    reason
    performer
    existingContactPerformer {
      contactId
      display
      organization
      address {
        line1
        line2
        city
        district
        state
        postalCode
      }
      emailAddress
      faxNumber
      workNumber
      display
    }
    doNotPerform
    expiresAt
    isUrgent
    comments
    createdAt
    patient {
      id
      birthDate
    }
    requestGroup {
      id
      presentedForm {
        ...AttachmentFragment
      }
    }
    diagnosisCodes {
      id
      code
      description
    }
    requester {
      id
      givenName
      familyName
    }
    schedulingCase {
      ...OrderSchedulingCaseFragment
    }
    supportingInfo
    immunization {
      id
      status
    }
    observationDefinitions {
      id
      label
      display
      system
      orderCode
      questionCode
      permittedDataType
      permittedUnit
      validCodedValueSet {
        system
        code
        display
      }
    }
    questionnaires
    scoringDetailsForQuestionnaires {
      header
      instructions
    }
    resultsForQuestionnaires {
      develoQuestionnaire
      questionnaireCode
      overall {
        isForTotal
        header
        score
        interpretation
      }
      detailed
      notes
      authorId
    }
    questionnaire
    questionnaireResults {
      develoQuestionnaire
      questionnaireCode
      overall {
        isForTotal
        header
        score
        interpretation
        answerDisplay
      }
      detailed
      notes
      authorId
    }
    questionnaireScoringDetails {
      header
      instructions
    }
    resultsReviewCase {
      id
    }
    refusalReasons {
      refusalByPatient
      refusalByCaregiver
      uncooperativePatient
      contraindicatedForPatient
      underCareOfAnotherProvider
    }
  }
  ${ATTACHMENT_FRAGMENT}
  ${ORDER_SCHEDULING_CASE_FRAGMENT}
`

export const FIND_PATIENT_ORDERS_QUERY = gql`
  query FindPatientOrders($id: String!) {
    patient(id: $id) {
      id
      orders {
        ...OrderFragment
      }
    }
  }
  ${ORDER_FRAGMENT}
`

const FIND_ORDER_TO_PRINT_QUERY = gql`
  query FindOrderToPrint($id: String!) {
    order(id: $id) {
      ...OrderFragment
      requester {
        ...SigningPractitionerFragment
        tenant {
          ...TenantLetterheadFragment
        }
      }
      patient {
        ...PatientGuardianDetailsFragment
      }
    }
  }
  ${ORDER_FRAGMENT}
  ${TENANT_LETTERHEAD_FRAGMENT}
  ${PATIENT_GUARDIAN_DETAILS_FRAGMENT}
  ${SIGNING_PRACTITIONER_FRAGMENT}
`

const CREATE_ORDER_MUTATION = gql`
  mutation CreateOrder($input: CreateOrderInput!) {
    createOrder(input: $input) {
      id
      orders {
        ...OrderFragment
      }
    }
  }
  ${ORDER_FRAGMENT}
`

const UPDATE_ORDER_MUTATION = gql`
  mutation UpdateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      id
      orders {
        ...OrderFragment
      }
    }
  }
  ${ORDER_FRAGMENT}
`

export const ORDER_WITH_OBSERVATIONS_FRAGMENT = gql`
  fragment OrderWithObservationsFragment on Order {
    ...OrderFragment
    resultInterpretation
    observations {
      ...ObservationForOrderFragment
    }
    diagnosticReport {
      id
    }
  }
  ${ORDER_FRAGMENT}
  ${OBSERVATION_FOR_ORDER_FRAGMENT}
`

const FIND_PATIENT_ORDERS_WITH_OBSERVATIONS_QUERY = gql`
  query FindPatientOrdersWithObservations($id: String!) {
    patient(id: $id) {
      id
      orders {
        ...OrderWithObservationsFragment
      }
    }
  }
  ${ORDER_WITH_OBSERVATIONS_FRAGMENT}
`

export const usePatientOrdersWithObservationsQuery = (
  patientId: string,
  encounterId?: string
) => {
  const { data, loading, error } = useQuery<FindPatientOrdersWithObservations>(
    FIND_PATIENT_ORDERS_WITH_OBSERVATIONS_QUERY,
    {
      variables: { id: patientId },
      skip: !patientId,
    }
  )

  const ordersWithObservations = data?.patient?.orders?.filter((order) =>
    encounterId ? order.encounterReference?.includes(encounterId) : true
  )

  const nonCancelledOrders = ordersWithObservations?.filter(
    ({ intent, status }) =>
      intent === 'ORDER' || (intent === 'PROPOSAL' && status !== 'REVOKED')
  )

  const pendedOrders = ordersWithObservations?.filter((order) =>
    orderIsPended(order)
  )
  const activeOrders = ordersWithObservations?.filter(
    ({ status, intent }) => intent === 'ORDER' && status === 'ACTIVE'
  )
  const completedOrders = ordersWithObservations?.filter(
    ({ status, intent }) => intent === 'ORDER' && status === 'COMPLETED'
  )
  const revokedOrders = ordersWithObservations?.filter(
    ({ status, intent }) => intent === 'ORDER' && status === 'REVOKED'
  )

  return {
    ordersWithObservations,
    nonCancelledOrders,
    pendedOrders,
    activeOrders,
    completedOrders,
    revokedOrders,
    loading,
    error,
  }
}

export const usePatientOrdersQuery = (
  patientId: string,
  encounterId?: string
) => {
  const { data, loading, error } = useQuery<FindPatientOrders>(
    FIND_PATIENT_ORDERS_QUERY,
    {
      variables: { id: patientId },
      skip: !patientId,
    }
  )

  const orders =
    data?.patient?.orders?.filter((order) =>
      encounterId ? order.encounterReference?.includes(encounterId) : true
    ) ?? []

  const nonCancelledOrders = orders?.filter(
    ({ intent, status }) =>
      intent === 'ORDER' || (intent === 'PROPOSAL' && status !== 'REVOKED')
  )

  const pendedOrders = orders?.filter(
    ({ intent, status }) => intent === 'PROPOSAL' && status !== 'REVOKED'
  )
  const activeOrders = orders?.filter(
    ({ status, intent }) => intent === 'ORDER' && status === 'ACTIVE'
  )
  const completedOrders = orders?.filter(
    ({ status, intent }) => intent === 'ORDER' && status === 'COMPLETED'
  )
  const revokedOrders = orders?.filter(
    ({ status, intent }) => intent === 'ORDER' && status === 'REVOKED'
  )

  return {
    orders,
    nonCancelledOrders,
    pendedOrders,
    activeOrders,
    completedOrders,
    revokedOrders,
    loading,
    error,
  }
}

export const useFindOrderToPrintQuery = (orderId: string) => {
  const { data, loading, error } = useQuery(FIND_ORDER_TO_PRINT_QUERY, {
    variables: { id: orderId },
  })

  return {
    order: data?.order,
    loading,
    error,
  }
}

export const useCreateOrderMutation = () => {
  return useMutation<CreateOrder>(CREATE_ORDER_MUTATION, {
    onCompleted: () => {
      toast.success('Order saved')
    },
  })
}

export const useUpdateOrderMutation = () => {
  return useMutation<CreateOrder>(UPDATE_ORDER_MUTATION, {
    onCompleted: () => {
      toast.success('Order updated')
    },
  })
}

export const isRelevantForEncounter = (
  encounterId: string,
  order: OrderFragment
) => {
  return (
    order.encounterId === encounterId ||
    (order.intent === 'PROPOSAL' && order.status !== 'REVOKED') ||
    (order.intent === 'ORDER' && order.status === 'ACTIVE')
  )
}
