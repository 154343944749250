import { FamilyMessageCaseSubject } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const familyMessageSubjectDisplay: {
  [Subject in FamilyMessageCaseSubject]: string
} = {
  GENERAL: 'General',
  SCHEDULING: 'Scheduling',
  MEDICATIONS: 'Clinical - Medication',
  ILLNESS: 'Clinical - Illness',
  CLINICAL_RESULTS: 'Clinical - Results',
  CLINICAL_OTHER: 'Clinical - Other',
  BILLING: 'Billing',
  OTHER: 'Other',
}

export const familyMessageSubjects =
  nameValueArrayFromObject<FamilyMessageCaseSubject>(
    familyMessageSubjectDisplay
  )
