import {
  GetSupervisingPractitionerSidepanelData,
  GetSupervisingPractitionerSidepanelDataVariables,
} from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

const QUERY = gql`
  query GetSupervisingPractitionerSidepanelData(
    $date: Date!
    $locationId: String!
  ) {
    supervisingPractitionerAssignments(date: $date, locationId: $locationId) {
      id
      supervisingPractitionerClinicalDetail {
        id
        practitioner {
          id
          givenName
          familyName
        }
      }
      midLevelPractitionerClinicalDetails {
        id
        practitioner {
          id
          givenName
          familyName
        }
      }
    }
    supervisingPractitionerOptions {
      id
      practitioner {
        id
        givenName
        familyName
        user {
          id
          userType {
            id
            name
          }
        }
      }
    }
    midLevelPractitionerOptions {
      id
      practitioner {
        id
        givenName
        familyName
        user {
          id
          userType {
            id
            name
          }
        }
      }
    }
    locations {
      id
      name
    }
  }
`

export const useSupervisingPractitionerAssignmentSidepanelData = (
  variables: GetSupervisingPractitionerSidepanelDataVariables,
  opts?: {
    skip: boolean
  }
) =>
  useQuery<
    GetSupervisingPractitionerSidepanelData,
    GetSupervisingPractitionerSidepanelDataVariables
  >(QUERY, {
    variables,
    skip: opts?.skip,
  })
