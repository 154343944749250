import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'

export const Footer = ({
  pageNumber,
  totalPages,
}: {
  pageNumber: number
  totalPages: number
}) => {
  return (
    <StackView
      direction="row"
      justifyContent="end"
      className="border-t-core-border-width-20 border-base-color-border-default pt-core-space-100"
    >
      <Typography
        textStyle="interface-default-xs"
        color="text-base-color-fg-muted"
      >
        Page {pageNumber} of {totalPages}
      </Typography>
    </StackView>
  )
}
