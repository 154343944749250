import { useContext } from 'react'

import { useLazyQuery } from '@apollo/client'
import { format, isSameDay } from 'date-fns'
import {
  GetAppointmentForStartVisitWarning,
  GetAppointmentForStartVisitWarningVariables,
} from 'types/graphql'

import { useConfirmation } from 'src/hooks/useConfirmation/useConfirmation'
import { createNamedContext } from 'src/utils'

import Typography from '../atoms/Typography'
import Modal from '../molecules/Modal'

const GET_APPOINTMENT_QUERY = gql`
  query GetAppointmentForStartVisitWarning($id: String!) {
    appointment(id: $id) {
      id
      start
    }
  }
`

const StartVisitWarningContext = createNamedContext<{
  checkForStartVisitWarnings: (data: {
    appointmentId: string
  }) => Promise<{ confirmed: boolean }>
  isCheckingForWarnings: boolean
}>('StartVisitWarningContext')

export const useStartVisitWarningContext = () =>
  useContext(StartVisitWarningContext)

export const StartVisitWarningProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [getAppointment, { data }] = useLazyQuery<
    GetAppointmentForStartVisitWarning,
    GetAppointmentForStartVisitWarningVariables
  >(GET_APPOINTMENT_QUERY)

  const [differentDayModalState, waitForDifferentDayConfirmation] =
    useConfirmation()

  const checkIfAppointmentIsToday = async ({
    appointmentId,
  }: {
    appointmentId: string
  }) => {
    const appointmentResult = await getAppointment({
      variables: {
        id: appointmentId,
      },
    })
    const { appointment } = appointmentResult.data

    const appointmentIsToday = isSameDay(
      new Date(),
      new Date(appointment.start)
    )

    if (!appointmentIsToday) {
      const { confirmed } = await waitForDifferentDayConfirmation()

      if (!confirmed) return { confirmed }
    }

    return { confirmed: true }
  }

  const appointmentStart = data?.appointment?.start
    ? new Date(data.appointment.start)
    : null

  return (
    <StartVisitWarningContext.Provider
      value={{
        checkForStartVisitWarnings: checkIfAppointmentIsToday,
        isCheckingForWarnings: differentDayModalState.isConfirming,
      }}
    >
      {children}
      <Modal
        isOpen={differentDayModalState.isConfirming}
        title="Start visit"
        content={
          <Typography textStyle="body-s" color="text-base-color-fg-subtle">
            {appointmentStart
              ? `This visit is scheduled to be on ${format(
                  appointmentStart,
                  'MM/dd/yyyy'
                )} which is different from today's date. Please make sure that this is the correct visit to start.`
              : ''}
          </Typography>
        }
        modalStyle="warning"
        onConfirm={differentDayModalState.confirm}
        confirmText="Start visit"
        setIsOpen={(isOpen) => {
          if (isOpen) {
            return
          }

          differentDayModalState.cancel()
        }}
      />
    </StartVisitWarningContext.Provider>
  )
}
