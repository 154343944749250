import { useState } from 'react'

import { ArrowLeftIcon, PrinterIcon } from '@heroicons/react/24/solid'
import { chiefComplaintDisplay } from 'common/data/chiefComplaints'
import { getTargetDateDisplay } from 'common/targetDate'
import { assertUnreachable, formatDisplayName } from 'common/utils'
import { parseISO } from 'date-fns'
import capitalize from 'lodash/capitalize'
import compact from 'lodash/compact'
import { useParams } from 'react-router-dom'
import { FindOrderToPrint, OrderFragment } from 'types/graphql'

import { navigate, useLocation } from '@redwoodjs/router'

import Button from 'src/components/atoms/Button'
import FileDisplay from 'src/components/atoms/FileDisplay/FileDisplay'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { Data, DataDisplayList } from 'src/components/molecules/DataDisplayList'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { orderCategoryConfig } from 'src/data/orderCategories'
import { formatDateDisplay } from 'src/lib/formatters'
import { sidepanelRoute } from 'src/lib/routes'
import { useFindOrderToPrintQuery } from 'src/pages/PatientChartsPage/PatientOrders/usePatientOrders'
import PrintOrderDocument from 'src/pdf/components/PrintOrderDocument'
import { useGeneratePDF } from 'src/pdf/useGeneratePDF'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

import SidepanelNotAvailable from '../SidepanelNotAvailable/SidepanelNotAvailable'

import FaxOrderButton from './FaxOrderButton'

const commonFields = (order: OrderFragment): Data[] =>
  compact([
    {
      value: (
        <StackView>
          {order.diagnosisCodes?.map(({ code, description }) => (
            <span key={code}>
              <Typography fontWeight="medium">{code}: </Typography>
              <Typography textStyle="description">{description}</Typography>
            </span>
          ))}
        </StackView>
      ),
      label: 'Linked diagnosis code(s)',
    },
    {
      value: formatDateDisplay(order.createdAt),
      label: 'Ordered at',
    },
    {
      value: formatDateDisplay(order.expiresAt),
      label: 'Order expiration',
    },
    {
      value: order.isUrgent,
      label: 'Is urgent',
    },
    {
      value: order.comments,
      label: 'Order comments',
    },
    order.resultsReviewCase
      ? {
          value: (
            <Button
              buttonStyle="ghost"
              onClick={() =>
                navigate(
                  `/cases/results-review/${order.resultsReviewCase.id}/details`
                )
              }
            >
              Click to view reviewed results
            </Button>
          ),
          label: 'Results review',
        }
      : undefined,
  ])

const deriveProviderPreferenceOptionDisplay = (
  order: OrderFragment
): string => {
  if (!order) return ''
  return order.existingContactPerformer?.contactId
    ? 'Existing contact provider'
    : order.performer
    ? 'Custom provider'
    : 'Any provider'
}

const referralFields = (order: OrderFragment): Data[] => {
  return [
    {
      value: order.reason,
      label: 'Referral reason',
    },
    {
      value: deriveProviderPreferenceOptionDisplay(order),
      label: 'Referred provider preference',
    },
    {
      value: order.performer,
      label: 'Provider name',
      hide: !order.performer,
    },
  ]
}

const buildOrderDataDisplayList = (order: OrderFragment): Data[] => {
  switch (order.category) {
    case 'RAD':
      return [
        {
          value: order.codeDisplay,
          label: 'Imaging study',
        },
        ...referralFields(order),
        ...commonFields(order),
      ]
    case 'REF':
      return [
        {
          value: order.codeDisplay,
          label: 'Specialty or procedure',
        },
        {
          value: order.freeTextCode,
          label: 'Free text specialty',
          hide: order.code !== 'OTHER',
        },
        ...referralFields(order),
        ...commonFields(order),
      ]
    case 'DME':
      return [
        {
          value: order.codeDisplay,
          label: 'Order type',
        },
        ...commonFields(order),
      ]
    case 'HG_LAB':
    case 'LAB':
      return [
        {
          value: order.codeDisplay,
          label: 'Order type',
        },
        ...commonFields(order),
      ]
    case 'IHT':
      return [
        {
          value: order.codeDisplay,
          label: 'Order type',
        },
        ...commonFields(order),
      ]
    // case 'PTB':
    //   return [
    //     {
    //       value: order.codeDisplay,
    //       label: 'order type',
    //     },
    //     ...commonFields(order),
    //   ]
    case 'PRO':
      return [
        {
          value: order.codeDisplay,
          label: 'Order type',
        },
        ...commonFields(order),
      ]
    case 'SCN':
      return [
        {
          value: order.codeDisplay,
          label: 'Order type',
        },
        ...commonFields(order),
      ]
    case 'VAX':
      return [
        {
          value: order.codeDisplay,
          label: 'Vaccine Order Name',
        },
        ...commonFields(order),
      ]
    case 'SCH':
      return [
        {
          label: 'Target visit timing',
          value: capitalize(
            getTargetDateDisplay({
              baseDate: parseISO(order.schedulingCase.baseCase.createdAt),
              targetDate: order.schedulingCase.targetDate ?? {
                value: order.schedulingCase.targetTime,
                unit: order.schedulingCase.targetTimeUnit,
                prefix: 'AFTER',
              },
              patientBirthDate: parseISO(order.patient.birthDate),
            })
          ),
        },
        {
          label: 'Visit type',
          value: order.schedulingCase.appointmentDefinitions
            ?.map(({ code, duration }) => `${code}${duration}`)
            .join(', '),
        },
        {
          label: 'Chief complaints',
          value: order.schedulingCase.chiefComplaints
            .map((complaint) => chiefComplaintDisplay[complaint])
            .join(', '),
        },
        {
          label: 'Visit practitioner',
          value: formatDisplayName(order.schedulingCase.visitPractitioner),
        },
        {
          label: 'Location',
          value: order.schedulingCase.location.name,
        },
        {
          label: 'Order comments',
          value: order.schedulingCase.baseCase.lastCaseNote?.text,
        },
      ]
    case 'OTHER':
      return commonFields(order)
    default:
      assertUnreachable(order.category)
  }
}

const OrderDetails = ({ order }: { order: FindOrderToPrint['order'] }) => {
  const [generatedPDF, setGeneratedPDF] = useState<string>()
  const [isGenerating, setIsGenerating] = useState(false)
  const [showPDF, setShowPDF] = useState(false)
  const generatePDF = useGeneratePDF()
  const orderDetails = React.useMemo(() => {
    return buildOrderDataDisplayList(order)
  }, [order])
  const { sidepanelContext } = useSidepanel()
  const location = useLocation()

  const pdfFromServer = order.requestGroup?.presentedForm

  const resizeSidepanel = (width: 'small' | 'medium') => {
    const overlay = width === 'medium'
    navigate(
      sidepanelRoute(
        {
          ...sidepanelContext,
          route: sidepanelContext.route,
          width,
          overlay,
        },
        location
      )
    )
  }

  return (
    <StackView className="grow px-6 pt-4" space={50}>
      {order.status === 'ACTIVE' && order.intent === 'ORDER' && (
        <StackView direction="row" space={50}>
          {showPDF ? (
            <Button
              buttonStyle="secondary"
              icon={ArrowLeftIcon}
              text="Back"
              onClick={() => {
                setGeneratedPDF(undefined)
                setShowPDF(false)
                resizeSidepanel('small')
              }}
            />
          ) : (
            <Button
              buttonStyle="secondary"
              icon={PrinterIcon}
              loading={isGenerating}
              text="Print preview"
              onClick={() => {
                if (pdfFromServer) {
                  setShowPDF(true)
                  resizeSidepanel('medium')
                  return
                }

                setIsGenerating(true)
                void generatePDF({
                  component: <PrintOrderDocument order={order} />,
                  callback: (doc) => {
                    setIsGenerating(false)
                    setGeneratedPDF(doc.output('datauristring'))
                    setShowPDF(true)
                    resizeSidepanel('medium')
                  },
                })
              }}
            />
          )}
          <FaxOrderButton order={order} />
        </StackView>
      )}
      {showPDF ? (
        pdfFromServer ? (
          <FileDisplay type="application/pdf" url={pdfFromServer.url} />
        ) : (
          <FileDisplay type="application/pdf" url={generatedPDF} />
        )
      ) : (
        <DataDisplayList data={orderDetails} />
      )}
    </StackView>
  )
}

const SidepanelOrderView = () => {
  const { orderId } = useParams()
  const { order } = useFindOrderToPrintQuery(orderId)

  if (!order) return <SidepanelNotAvailable />

  return (
    <SidepanelPage
      testId="sidepanel-order-view"
      header={`${orderCategoryConfig[order.category].display} order details`}
      subHeader={order.name}
    >
      <OrderDetails key={order.id} order={order} />
    </SidepanelPage>
  )
}

export default SidepanelOrderView
