import { useEffect, useState } from 'react'

import { getBase64ContentFromDataUri } from 'common/utils'
import { useParams } from 'react-router-dom'
import {
  GetPatientDemographicsForPrinting,
  GetPatientDemographicsForPrintingVariables,
  UploadPatientDemographicsPrintoutBinary,
  UploadPatientDemographicsPrintoutBinaryVariables,
} from 'types/graphql'

import { navigate } from '@redwoodjs/router'
import { useMutation, useQuery } from '@redwoodjs/web'

import Button from 'src/components/atoms/Button/Button'
import FileDisplay from 'src/components/atoms/FileDisplay/FileDisplay'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import StackView from 'src/components/atoms/StackView'
import EFaxIcon from 'src/components/icons/EFaxIcon/EFaxIcon'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { ON_DEMAND_DEMOGRAPHICS_FRAGMENT } from 'src/hooks/useOnDemandMedicalRecordsQuery/useOnDemandMedicalRecordsQuery'
import { sidepanelRoute } from 'src/lib/routes'
import { PrintPatientDemographics } from 'src/pdf/components/PrintPatientDemographics/PrintPatientDemographics'
import { useGeneratePDF } from 'src/pdf/useGeneratePDF'

export const UPLOAD_DOCUMENT_MUTATION = gql`
  mutation UploadPatientDemographicsPrintoutBinary($binaryString: String!) {
    savePatientDemographicsPrintoutBinary(binaryString: $binaryString) {
      id
    }
  }
`

const QUERY = gql`
  query GetPatientDemographicsForPrinting($patientId: String!) {
    patient(id: $patientId) {
      ...OnDemandDemographicsFragment
    }
  }
  ${ON_DEMAND_DEMOGRAPHICS_FRAGMENT}
`

const SidepanelPrintPatientDemographics = () => {
  const { patientId } = useParams()

  const generatePDF = useGeneratePDF()
  const [isGenerating, setIsGenerating] = useState(false)
  const [fileUrl, setFileUrl] = useState<URL | null>(null)
  const [binaryString, setBinaryString] = useState<string | null>(null)

  const [uploadDocumentBinary, { loading: isUploading }] = useMutation<
    UploadPatientDemographicsPrintoutBinary,
    UploadPatientDemographicsPrintoutBinaryVariables
  >(UPLOAD_DOCUMENT_MUTATION)

  const { data } = useQuery<
    GetPatientDemographicsForPrinting,
    GetPatientDemographicsForPrintingVariables
  >(QUERY, {
    variables: {
      patientId,
    },
  })

  useEffect(() => {
    if (!data) return
    if (isGenerating) return
    if (fileUrl) return

    setIsGenerating(true)

    void generatePDF({
      component: <PrintPatientDemographics patient={data.patient} />,
      callback: (doc) => {
        setFileUrl(doc.output('bloburl'))
        setBinaryString(
          getBase64ContentFromDataUri(doc.output('datauristring'))
        )
        setIsGenerating(false)
      },
    })
  }, [data, generatePDF, fileUrl, isGenerating])

  const openFaxSidepanel = async () => {
    const mutationResult = await uploadDocumentBinary({
      variables: {
        binaryString,
      },
    })
    const binaryId =
      mutationResult.data.savePatientDemographicsPrintoutBinary.id

    navigate(
      sidepanelRoute(
        {
          route: '/send-fax',
          primaryDocument: JSON.stringify({
            name: 'Patient demographics',
            binaryId,
            documentType: 'BINARY',
          }),
          patientId,
        },
        location
      )
    )
  }

  return (
    <SidepanelPage header="Print demographics">
      <StackView className="h-full pt-4" space={50}>
        {!fileUrl ? (
          <LoadingSpinner />
        ) : (
          <>
            <StackView direction="row" className="px-core-space-100">
              <Button
                icon={EFaxIcon}
                buttonStyle="secondary"
                text="E-Fax"
                onClick={openFaxSidepanel}
                loading={isUploading}
                disabled={isUploading}
              />
            </StackView>
            <FileDisplay
              name="Patient demographics"
              type="application/pdf"
              url={fileUrl}
              showDownloadLink={false}
            />
          </>
        )}
      </StackView>
    </SidepanelPage>
  )
}

export default SidepanelPrintPatientDemographics
