import { useEffect } from 'react'

import { XMarkIcon } from '@heroicons/react/24/solid'
import { formatDollarsToCents } from 'common/utils'
import { useParams } from 'react-router-dom'
import { PaymentLineItemType } from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { toast } from '@redwoodjs/web/dist/toast'

import PaymentLineItemSelectField from 'src/components/atoms/PaymentLineItemSelectField.tsx/PaymentLineItemSelectField'
import TextAreaField from 'src/components/atoms/TextAreaField/TextAreaField'
import BillingEncounterSelectField, {
  getEncounter,
  getEncounterType,
} from 'src/components/molecules/BillingEncounterSelectField/BillingEncounterSelectField'
import { DatePickerField } from 'src/components/molecules/DatePicker'
import DestructiveAction from 'src/components/molecules/DestructiveAction/DestructiveAction'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import { InputFieldWithUnit } from 'src/components/molecules/InputFieldWithUnits/InputFieldWithUnits'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import {
  useDeletePatientPaymentMutation,
  usePatientBillingEncountersQuery,
  usePatientPaymentQuery,
  useUpdatePatientPaymentMutation,
} from 'src/hooks/usePatientPayments/usePatientPayments'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

type UpdatePaymentFormProps = {
  amount: number | string
  paidAt: string
  lineItemId: string
  lineItemType: PaymentLineItemType
  comments?: string
}

const SidepanelPatientPaymentEdit = () => {
  const { patientId, patientPaymentId } = useParams()
  const { closeSidePanel } = useSidepanel()

  const { payment } = usePatientPaymentQuery(patientPaymentId)
  const {
    billingEncounters,
    appointmentsWithoutBillingEncounters,
    loading: billingEncountersLoading,
  } = usePatientBillingEncountersQuery({ patientId })
  const [updatePatientPayment, { loading: updating }] =
    useUpdatePatientPaymentMutation()
  const [deletePatientPayment, { loading: deleting }] =
    useDeletePatientPaymentMutation()

  const onSubmit = async (data: UpdatePaymentFormProps) => {
    const encounter = getEncounter({
      encounters: billingEncounters,
      lineItemId: data.lineItemId,
    })

    const encounterType = getEncounterType(encounter)

    await updatePatientPayment({
      variables: {
        id: patientPaymentId,
        input: {
          amountCents: formatDollarsToCents(data.amount),
          paidAt: data.paidAt,
          lineItemType: data.lineItemType,
          appointmentId:
            encounterType === 'UNSUBMITTED_CLAIM' ? data.lineItemId : undefined,
          directPatientChargeEncounterId:
            encounterType === 'DIRECT_PATIENT_CHARGE_ENCOUNTER'
              ? data.lineItemId
              : undefined,
          appointmentEncounterId:
            encounterType === 'APPOINTMENT_ENCOUNTER'
              ? data.lineItemId
              : undefined,
          comments: data.comments,
        },
      },
      onCompleted: () => {
        toast.success('Payment updated')
        closeSidePanel()
      },
    })
  }

  const formMethods = useForm<UpdatePaymentFormProps>()

  useEffect(() => {
    if (!billingEncountersLoading && billingEncounters.length && payment) {
      const directPatientChargeEncounter = getEncounter({
        encounters: billingEncounters,
        lineItemId: payment.directPatientChargeEncounter?.id,
      })

      const appointmentEncounter = getEncounter({
        encounters: billingEncounters,
        lineItemId: payment.encounterId,
      })

      const lineItemId =
        directPatientChargeEncounter?.id ??
        appointmentEncounter?.id ??
        payment.appointment?.id

      formMethods.reset({
        amount: (payment.amountCents > 0
          ? payment.amountCents / 100
          : 0
        ).toFixed(2),
        paidAt: payment.paidAt,
        lineItemId,
        lineItemType: payment.paymentLineItemType,
        comments: payment.comments,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingEncounters, billingEncountersLoading, payment])

  if (!payment) return null

  return (
    <SidepanelPage
      testId="patient-payment-edit"
      header="Edit patient payment"
      key={patientPaymentId}
      loading={billingEncountersLoading}
    >
      <SidepanelForm
        footerProps={{
          submitText: 'Save',
          submitting: updating,
        }}
        onSubmit={onSubmit}
        formMethods={formMethods}
      >
        <FormInputList
          title="Details"
          divider={false}
          className="py-core-space-150"
          items={[
            {
              name: 'amount',
              label: 'Amount',
              required: true,
              formInputComponent: InputFieldWithUnit,
              inputProps: {
                unit: 'USD',
                type: 'number',
                min: 0,
                step: 0.01,
                placeholder: '0.00',
              },
              direction: 'col',
            },
            {
              name: 'paidAt',
              label: 'Paid on',
              required: true,
              formInputComponent: DatePickerField,
              direction: 'col',
            },
            {
              name: 'lineItemId',
              label: 'Charge',
              formInputComponent: BillingEncounterSelectField,
              required: true,
              inputProps: {
                patientId,
                filteredEncounters: billingEncounters,
                filteredAppointments: appointmentsWithoutBillingEncounters,
              },
              direction: 'col',
            },
            {
              name: `lineItemType`,
              label: 'Line item',
              formInputComponent: PaymentLineItemSelectField,
              required: true,
              direction: 'col',
            },
            {
              name: `comments`,
              label: 'Comments',
              formInputComponent: TextAreaField,
              direction: 'col',
            },
          ]}
        />
        <DestructiveAction
          title="Destructive actions"
          description="These changes are permanent, use caution when removing."
          buttonText="Delete payment"
          buttonIcon={XMarkIcon}
          doDestructiveAction={async () => {
            await deletePatientPayment({
              variables: { id: patientPaymentId },
              onCompleted: () => {
                toast.success('Patient payment deleted')
                closeSidePanel()
              },
            })
          }}
          destructiveActionIsLoading={deleting}
        />
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelPatientPaymentEdit
