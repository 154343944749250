import {
  CreatePatientCharges,
  DeletePatientChargeById,
  GetPatientChargeById,
  PatientCharges,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'

import { paymentRefetchQueries } from '../usePatientPayments/usePatientPayments'

const PATIENT_CHARGES_QUERY = gql`
  query PatientCharges($patientId: String!) {
    candidDirectPatientChargeEncountersByPatient(patientId: $patientId) {
      id
      directPatientChargeBillingCode {
        id
        code
        description
      }
      createdAt
      chargeAmountCents
      balanceAmountCents
      paymentStatus
    }
  }
`

const PATIENT_CHARGE_BY_ID_QUERY = gql`
  query GetPatientChargeById($id: String!) {
    candidDirectPatientChargeEncounter(id: $id) {
      id
      directPatientChargeBillingCode {
        id
        code
      }
      description
      createdAt
      paymentLineItemType
      chargeAmountCents
      paidAmountCents
      paymentStatus
      candidPatientPayments {
        id
        candidPatientPaymentId
      }
    }
  }
`

const DELETE_PATIENT_CHARGE_BY_ID_MUTATION = gql`
  mutation DeletePatientChargeById($id: String!) {
    deleteCandidDirectPatientCharge(id: $id) {
      id
    }
  }
`

const CREATE_PATIENT_CHARGES_MUTATION = gql`
  mutation CreatePatientCharges($input: CandidDirectPatientChargesInput!) {
    createCandidDirectPatientCharges(input: $input) {
      chargeEncounter {
        id
      }
      status
      errorReason
    }
  }
`

export const usePatientChargesQuery = (patientId: string) => {
  const { data, loading, error } = useQuery<PatientCharges>(
    PATIENT_CHARGES_QUERY,
    {
      variables: { patientId },
    }
  )

  const charges = data?.candidDirectPatientChargeEncountersByPatient

  return {
    charges,
    loading,
    error,
  }
}

export const usePatientChargeByIdQuery = (id: string) => {
  const { data, loading, error } = useQuery<GetPatientChargeById>(
    PATIENT_CHARGE_BY_ID_QUERY,
    {
      variables: { id },
    }
  )

  const charge = data?.candidDirectPatientChargeEncounter

  return {
    charge,
    loading,
    error,
  }
}

export const useDeletePatientChargeByIdMutation = (id: string) => {
  const [deleteCharge, { loading, error }] =
    useMutation<DeletePatientChargeById>(DELETE_PATIENT_CHARGE_BY_ID_MUTATION, {
      variables: { id },
      refetchQueries: paymentRefetchQueries,
    })

  return {
    deleteCharge,
    loading,
    error,
  }
}

export const useCreatePatientChargesMutation = () => {
  const [createCharges, { data, loading, error }] =
    useMutation<CreatePatientCharges>(CREATE_PATIENT_CHARGES_MUTATION, {
      refetchQueries: paymentRefetchQueries,
    })

  const charges = data?.createCandidDirectPatientCharges

  return {
    createCharges,
    charges,
    loading,
    error,
  }
}
