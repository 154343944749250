import {
  AppointmentFiltersInput,
  FindPractitionerScheduleQuery,
  StartVisitMutation,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'

import { VISIT_PATIENT_EDUCATION_DOCUMENTS_FRAGMENT } from 'src/pages/PatientChartsPage/PatientVisits/fragments'
import { VISIT_TEMPLATE_FRAGMENT } from 'src/pages/VisitTemplatePage/useVisitTemplates'

export const QUERY = gql`
  query FindPractitionerScheduleQuery($filters: AppointmentFiltersInput) {
    appointments(filters: $filters) {
      id
      start
      end
      status
      confirmedAt
      statusUpdatedAt
      chiefComplaints
      chartingStatus
      isPatientRegistered
      isFirstAppointmentForPatient
      visitComment
      location {
        id
        name
      }
      room {
        id
        name
      }
      appointmentDefinitions {
        id
        type
        name
      }
      patient {
        id
        namePrefix
        nameSuffix
        givenName
        middleName
        familyName
        sexAtBirth
        birthDate
        insuranceOptOutStatus
        activeInsuranceCoverages {
          id
          status
          coordinationOfBenefitsType
          payer {
            id
            displayName
          }
          mostRecentEligibility {
            id
            status
          }
        }
      }
      patientRegistrationIntent {
        id
        givenName
        familyName
        sexAtBirth
        birthDate
        payer {
          id
          displayName
        }
        mostRecentEligibility {
          id
          status
        }
      }
      practitioner {
        id
        namePrefix
        givenName
        middleName
        familyName
        nameSuffix
      }
      claim {
        id
      }
      location {
        id
        timezone
      }
      mostRecentVistFlowTab
    }
  }
`

const START_VISIT_MUTATION = gql`
  mutation StartVisitMutation($id: String!) {
    startVisit(id: $id) {
      id
      attestation
      historyPresentIllness
      developmentalSurveillance
      assessment
      planOfCare
      patientEducationAdditionalNotes
      patientEducationAdolescentConfidential
      documentationAdolescentConfidential
      reviewOfSystems
      physicalExam
      mostRecentVistFlowTab
      encounter {
        id
      }
      visitTemplates {
        ...VisitTemplateFragment
      }
      ...VisitPatientEducationDocumentsFragment
    }
  }
  ${VISIT_TEMPLATE_FRAGMENT}
  ${VISIT_PATIENT_EDUCATION_DOCUMENTS_FRAGMENT}
`

const oneMinuteInMs = 60_000
export const useAppointmentScheduleQuery = (
  filters: AppointmentFiltersInput,
  opts?: {
    shouldPoll?: boolean
  }
) => {
  const shouldPoll = opts?.shouldPoll ?? true

  return useQuery<FindPractitionerScheduleQuery>(QUERY, {
    variables: { filters },
    pollInterval: shouldPoll ? oneMinuteInMs : undefined,
  })
}

export const useStartVisit = () => {
  return useMutation<StartVisitMutation>(START_VISIT_MUTATION)
}
