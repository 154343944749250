import { ApolloClient } from '@apollo/client'
import { relationshipTypeToGuardianshipStatus } from 'common/data/relationshipTypes'
import {
  FetchAppointmentNotification,
  InsuranceOptOutStatus,
  PatientRegistrationCaregiverInput,
  PatientRegistrationInput,
} from 'types/graphql'
import { useLocalStorage } from 'usehooks-ts'

import { FieldValues, UseFormReturn } from '@redwoodjs/forms'
import { useParams } from '@redwoodjs/router'

import { Item } from 'src/components/molecules/FormInputList'
import { combineImagesInStack } from 'src/utils/combineImagesInStack'

export const selfRegistrationSteps = [
  'verification',
  'are-you-the-patient',
  'patient-demographics',
  'guardianship-status',
  'caregiver-relationship-to-patient',
  'caregiver-is-guardian-or-guarantor',
  'patient-address',
  'dependent-patient-address',
  'patient-contact-information',
  'does-patient-have-health-insurance',
  'patient-health-insurance-type',
  'caregiver-demographics',
  'caregiver-address',
  'caregiver-contact-information',
  'add-another-caregiver',
  'primary-guardian-and-guarantor',
  'patient-health-insurance-policy-holder',
  'patient-health-insurance-details',
  'patient-health-insurance-image',
  'add-another-insurance',
] as const

export type SelfRegistrationStep = (typeof selfRegistrationSteps)[number]

export const isSelfRegistrationStep = (
  step: string
): step is SelfRegistrationStep =>
  selfRegistrationSteps.map(String).includes(step)

export const repeatingFlows = ['caregiver', 'insurance'] as const

export type RepeatingFlow = (typeof repeatingFlows)[number]

export type SelfRegistrationStepConfig = {
  repeatingFlow?: {
    name: RepeatingFlow
    position?: 'start' | 'end'
  }
  title:
    | string
    | ((context: {
        formProgress: SelfRegistrationFormProgress
        repeatingFlowIndex?: number
      }) => string)
  description?:
    | string
    | ((context: { formProgress: SelfRegistrationFormProgress }) => string)
  next: (context: {
    notificationId: string
    patientRegistrationIntentId: string
    client: ApolloClient<object>
    formData: FieldValues
    formProgress?: SelfRegistrationFormProgress
    repeatingFlowIndex?: number
    appointmentConfirmation: FetchAppointmentNotification['appointmentConfirmation']
  }) => Promise<SelfRegistrationStep | 'sign-practice-forms' | null>
  back: (context: {
    notificationId: string
    repeatingFlowIndex?: number
    formProgress?: SelfRegistrationFormProgress
  }) => SelfRegistrationStep | null
  navTitle: string
  formFields: (context: {
    tenantId: string
    formData: FieldValues
    formProgress?: SelfRegistrationFormProgress
    formMethods?: UseFormReturn
  }) => Item[]
  formDefaultValues?: (context: {
    client: ApolloClient<object>
    patientRegistrationIntentId: string
    patientBirthDate: string
  }) => Promise<FieldValues>
  submitButton?: {
    text?: string
    disabled?: boolean
  }
}

export type SelfRegistrationConfig = {
  [page in SelfRegistrationStep]: SelfRegistrationStepConfig
}

export interface SelfRegistrationFormProgress {
  steps: {
    [step in SelfRegistrationStep]?: FieldValues
  }
}

const CURRENT_STEP_KEY_PREFIX = 'patientSelfRegistrationCurrentStep'

const currentStepKey = (notificationId: string): string =>
  [CURRENT_STEP_KEY_PREFIX, notificationId].join(':')

export const useLastSavedStep = ({
  notificationId,
}: {
  notificationId: string
}) =>
  useLocalStorage<SelfRegistrationStep | 'sign-practice-forms' | null>(
    currentStepKey(notificationId),
    null
  )

const FORM_PROGRESS_KEY_PREFIX = 'patientSelfRegistrationFormProgress'
const formProgressKey = (notificationId: string) =>
  [FORM_PROGRESS_KEY_PREFIX, notificationId].join(':')

export const useFormProgress = ({
  notificationId,
}: {
  notificationId: string
}) =>
  useLocalStorage<SelfRegistrationFormProgress | null>(
    formProgressKey(notificationId),
    null
  )

const REPEATING_FLOW_PROGRESS_KEY = 'patientSelfRegistrationRepeatingFlows'
const repeatingFlowProgressKey = (notificationId: string) =>
  [REPEATING_FLOW_PROGRESS_KEY, notificationId].join(':')

export const useRepeatingFlowProgress = ({
  notificationId,
}: {
  notificationId: string
}) => {
  const [repeatingFlowProgress, saveRepeatingFlowProgress] = useLocalStorage<{
    [flow in RepeatingFlow]: { currentIndex: number }
  }>(repeatingFlowProgressKey(notificationId), {
    caregiver: { currentIndex: 0 },
    insurance: { currentIndex: 0 },
  })

  const updateFlowProgress = (amount: number) => (flowName: RepeatingFlow) =>
    saveRepeatingFlowProgress((currentState) => ({
      ...currentState,
      [flowName]: {
        currentIndex: currentState[flowName].currentIndex + amount,
      },
    }))
  const incrementProgress = updateFlowProgress(1)
  const decrementProgress = updateFlowProgress(-1)

  return [
    repeatingFlowProgress,
    { incrementProgress, decrementProgress },
  ] as const
}

export const useCurrentStep = (): SelfRegistrationStep | null => {
  const { step } = useParams()

  if (!isSelfRegistrationStep(step)) return null

  return step
}

export const repeatingFlowFormProgressToArray = (
  formProgress: FieldValues
): FieldValues[] =>
  Object.entries(formProgress ?? {})
    .sort(([a], [b]) => Number(a) - Number(b))
    .map(([_, formData]) => formData)

export const getCaregiversFromFormProgress = (
  formProgress: SelfRegistrationFormProgress
): PatientRegistrationCaregiverInput['newCaregivers'] => {
  const addAnotherCaregiverAnswers = repeatingFlowFormProgressToArray(
    formProgress?.steps?.['add-another-caregiver']
  )

  const caregiverCount =
    addAnotherCaregiverAnswers.findIndex(
      (formData) => formData.addAnother === 'no'
    ) + 1

  const caregiverInfo: PatientRegistrationCaregiverInput['newCaregivers'] =
    new Array(caregiverCount).fill('').map((_, i) => {
      const relationship =
        formProgress?.steps?.['caregiver-relationship-to-patient'][i]
      const demographics = formProgress?.steps?.['caregiver-demographics'][i]
      const address = formProgress?.steps?.['caregiver-address'][i]
      const contactInformation =
        formProgress?.steps?.['caregiver-contact-information'][i]
      const primaryGuardianAndGuarantor =
        formProgress?.steps?.['primary-guardian-and-guarantor']
      const patientAddress = formProgress?.steps?.['dependent-patient-address']
      const isGuardianOrGuarantor =
        formProgress?.steps?.['caregiver-is-guardian-or-guarantor']

      const reference = String(i)
      return {
        reference,
        givenName: demographics.givenName,
        familyName: demographics.familyName,
        birthDate: demographics.birthDate,
        maritalStatus: demographics.maritalStatus,
        occupation: demographics.occupation,
        contactInformation: {
          emailAddress: contactInformation.emailAddress,
          mobileNumber: contactInformation.mobilePhoneNumber,
          homeNumber: contactInformation.homePhoneNumber,
          workNumber: contactInformation.workPhoneNumber,
          homeAddress: address.homeAddress,
          useHomeAddressAsMailingAddress:
            address.useHomeAddressAsMailingAddress,
          mailingAddress: !address.useHomeAddressAsMailingAddress
            ? address.mailingAddress
            : undefined,
          primaryLanguage: demographics.primaryLanguage,
        },
        relationship: {
          guardianshipType:
            isGuardianOrGuarantor?.isGuardian ||
            primaryGuardianAndGuarantor?.primaryGuardian === reference
              ? relationshipTypeToGuardianshipStatus[
                  relationship.relationshipType
                ]
              : 'NON_GUARDIAN',
          relationshipType: relationship.relationshipType,
          isGuarantor:
            isGuardianOrGuarantor?.isGuarantor === 'yes' ||
            primaryGuardianAndGuarantor?.primaryGuarantor === reference,
          doesResideWith:
            !!patientAddress?.livesWithCaregivers?.includes(reference),
        },
      }
    })

  return caregiverInfo
}

export const getInsuranceCoveragesFromFormProgress = async ({
  formProgress,
  repeatingFlowIndex,
}: {
  formProgress: SelfRegistrationFormProgress
  repeatingFlowIndex: number | undefined
}): Promise<PatientRegistrationInput['insurance']['insurances']> => {
  const index = repeatingFlowIndex ?? 0
  const insuranceCount = index + 1

  const insuranceInfo: PatientRegistrationInput['insurance']['insurances'] =
    await Promise.all(
      new Array(insuranceCount).fill('').map(async (_, i) => {
        const coordinationOfBenefitsType =
          formProgress?.steps?.['patient-health-insurance-type']?.[i]
            ?.coordinationOfBenefitsType

        if (!coordinationOfBenefitsType) return

        const policyHolder =
          formProgress?.steps?.['patient-health-insurance-policy-holder'][i]
            .policyHolder
        const insuranceDetails =
          formProgress?.steps?.['patient-health-insurance-details'][i]

        const cardImagesStep =
          formProgress?.steps?.['patient-health-insurance-image'][i]

        const cardImageUrls = [
          cardImagesStep.insuranceBinaries?.front?.url,
          cardImagesStep.insuranceBinaries?.back?.url,
        ].filter(Boolean)

        const combinedImage = await (cardImageUrls.length
          ? combineImagesInStack({
              imageUrls: cardImageUrls,
            }).catch(() => null)
          : null)

        return {
          payerId: insuranceDetails.insuranceProvider,
          subscriberRef: policyHolder !== 'patient' ? policyHolder : undefined,
          beneficiaryIsSubscriber: policyHolder === 'patient',
          planMemberIdentifier: insuranceDetails.memberId,
          coordinationOfBenefitsType,
          insuranceCardBinary: combinedImage
            ? {
                content: combinedImage.content,
                contentType: combinedImage.contentType,
              }
            : undefined,
          status: 'ACTIVE' as const,
        }
      })
    )

  return insuranceInfo.filter(Boolean)
}

export const userIsThePatient = ({
  formProgress,
}: {
  formProgress: SelfRegistrationFormProgress
}) => formProgress.steps?.['are-you-the-patient']?.isThePatient === 'yes'

export const isEnteringOwnCaregiverInformation = ({
  formProgress,
  repeatingFlowIndex,
}: {
  formProgress: SelfRegistrationFormProgress
  repeatingFlowIndex?: number
}) => !userIsThePatient({ formProgress }) && repeatingFlowIndex === 0

export const convertFormProgressToRegistrationInput = ({
  patientRegistrationIntentId,
  formProgress,
  insuranceCoverages,
  insuranceOptOutStatus,
}: {
  patientRegistrationIntentId: string
  formProgress: SelfRegistrationFormProgress
  insuranceCoverages: PatientRegistrationInput['insurance']['insurances']
  insuranceOptOutStatus?: InsuranceOptOutStatus
}): PatientRegistrationInput => {
  const isThePatient = userIsThePatient({ formProgress })
  const demographics = formProgress.steps['patient-demographics']
  const contactInformation = formProgress.steps['patient-contact-information']
  const address =
    formProgress.steps['patient-address'] ??
    formProgress.steps['dependent-patient-address']
  const guardianshipStatus = formProgress.steps['guardianship-status']
  const caregivers = getCaregiversFromFormProgress(formProgress)
  const primaryGuardianAndGuarantor =
    formProgress.steps['primary-guardian-and-guarantor']

  const livesWithCaregiverReferences = address?.livesWithCaregivers ?? []
  const livesWithCaregiver = caregivers.find((caregiver) =>
    livesWithCaregiverReferences.includes(caregiver.reference)
  )

  const patientHomeAddress =
    livesWithCaregiver?.contactInformation?.homeAddress ?? address?.homeAddress
  const patientMailingAddress =
    livesWithCaregiver?.contactInformation?.mailingAddress ??
    address?.mailingAddress

  return {
    patientRegistrationIntentId,
    patient: {
      givenName: demographics.givenName,
      familyName: demographics.familyName,
      preferredName: demographics.preferredName,
      birthDate: demographics.birthDate,
      sexAtBirth: demographics.sexAtBirth,
      primaryGuardianReference: primaryGuardianAndGuarantor?.primaryGuardian,
      primaryGuarantorReference: primaryGuardianAndGuarantor?.primaryGuarantor,
      contactInformation: contactInformation
        ? {
            emailAddress: contactInformation.emailAddress,
            mobileNumber: contactInformation.mobilePhoneNumber,
            homeNumber: contactInformation.homePhoneNumber,
            homeAddress: patientHomeAddress,
            useHomeAddressAsMailingAddress:
              address.useHomeAddressAsMailingAddress,
            mailingAddress: !address.useHomeAddressAsMailingAddress
              ? patientMailingAddress
              : undefined,
            primaryLanguage: demographics.primaryLanguage,
          }
        : undefined,
    },
    caregiver: {
      caregiverOptOut: !caregivers?.length,
      legalStatus: !isThePatient
        ? 'DEPENDENT'
        : guardianshipStatus?.guardianshipStatus,
      newCaregivers: caregivers,
    },
    insurance: {
      insuranceOptOut: !insuranceCoverages.length,
      insuranceOptOutStatus: insuranceOptOutStatus,
      insurances: insuranceCoverages,
    },
  }
}

export const SELF_REGISTER_PATIENT = gql`
  mutation SelfRegisterPatient(
    $input: PatientRegistrationInput!
    $registrationIntentBirthDate: Date!
    $appointmentNotificationId: String!
    $practiceForms: SelfRegistrationPracticeFormsInput
  ) {
    selfRegisterPatient(
      input: $input
      registrationIntentBirthDate: $registrationIntentBirthDate
      appointmentNotificationId: $appointmentNotificationId
      practiceForms: $practiceForms
    ) {
      id
    }
  }
`
