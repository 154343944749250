import { BellAlertIcon } from '@heroicons/react/24/solid'
import { differenceInDays, format, isBefore, parseISO } from 'date-fns'

import Badge from 'src/components/atoms/Badge'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'

type DateWithAlarmProps = {
  date: string
  dateFormat?: string
  alarmAt: number
  direction?: 'row' | 'col'
}

const DateWithAlarm = ({
  date,
  dateFormat = 'M/d/yyyy',
  alarmAt,
  direction = 'row',
}: DateWithAlarmProps) => {
  const diffDays = differenceInDays(parseISO(date), new Date())
  const shouldShowAlarm =
    diffDays <= alarmAt || isBefore(parseISO(date), new Date())
  return (
    <StackView
      direction={direction}
      alignItems="center"
      space={50}
      fullWidth={false}
    >
      <Typography fontWeight="bold">
        {format(parseISO(date), dateFormat)}
      </Typography>

      {shouldShowAlarm && (
        <Badge
          color="red"
          size="s"
          testId="expiration-badge"
          icon={BellAlertIcon}
          text={`${diffDays} Days`}
        />
      )}
    </StackView>
  )
}

export default DateWithAlarm
