import { useState } from 'react'

import { GetAllPossibleImmunizationsForVaccineCVXSelectField } from 'types/graphql'

import { useFormContext } from '@redwoodjs/forms'
import { useQuery } from '@redwoodjs/web'

import {
  ComboboxField,
  ComboboxFieldProps,
} from 'src/components/atoms/ComboboxField'

import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import StackView from '../StackView'
import Typography from '../Typography/Typography'

export type VaccineCVXSelectFieldProps = {
  showCvxDescription?: boolean
} & Omit<ComboboxFieldProps, 'options'>

const GET_POSSIBLE_IMMUNIZATIONS = gql`
  query GetAllPossibleImmunizationsForVaccineCVXSelectField {
    possibleImmunizations {
      cvxCode
      develoVaccineName
      brands
      cvxDescription
    }
  }
`

export const VaccineCVXSelectField = ({
  name,
  showCvxDescription = false,
  ...props
}: VaccineCVXSelectFieldProps) => {
  const { setValue } = useFormContext()
  const [cvxData, setCvxData] = useState(null)
  const { data, loading } =
    useQuery<GetAllPossibleImmunizationsForVaccineCVXSelectField>(
      GET_POSSIBLE_IMMUNIZATIONS
    )

  if (loading) return <LoadingSpinner />

  const options = data?.possibleImmunizations.map((pi) => {
    const synonyms = [...pi.brands, pi.cvxCode, pi.cvxDescription]
    return {
      name: pi.develoVaccineName,
      value: pi.cvxCode,
      description: (
        <StackView space={0} className="overflow-hidden">
          <Typography>{pi.brands?.join(' \u2022 ').toUpperCase()}</Typography>
          <Typography>
            CVX code: {pi.cvxCode} - {pi.cvxDescription}
          </Typography>
        </StackView>
      ),
      synonyms,
    }
  })

  return (
    <>
      <ComboboxField
        name={name}
        {...props}
        options={options}
        onChange={(val) => {
          setValue(name, val)
          setCvxData(data?.possibleImmunizations.find((x) => x.cvxCode === val))
        }}
      />

      {showCvxDescription && cvxData && (
        <Typography
          color="text-base-color-fg-muted"
          size="xs"
          className="italic"
        >
          CVX code: {cvxData.cvxCode} - {cvxData.cvxDescription}
        </Typography>
      )}
    </>
  )
}

export default VaccineCVXSelectField
