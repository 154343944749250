import { useEffect, useState } from 'react'

import { CalendarDaysIcon } from '@heroicons/react/24/solid'
import {
  CreatableDocumentSubType,
  CreatableDocumentType,
  creatableDocumentTypes,
} from 'common/cdr/concepts/documents'
import { getBase64ContentFromDataUri } from 'common/utils'
import { format } from 'date-fns'
import { useParams } from 'react-router-dom'
import { SelectActivePractitioners } from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { routes } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'

import { useEmrAuth } from 'src/auth'
import { QUERY as ACTIVE_PRACTITIONERS_QUERY } from 'src/components/ActivePractitionerSelectCell'
import ActivePractitionerSelectCell from 'src/components/ActivePractitionerSelectCell'
import Button, { Submit } from 'src/components/atoms/Button'
import Divider from 'src/components/atoms/Divider/Divider'
import { DropdownField } from 'src/components/atoms/Dropdown'
import InputField from 'src/components/atoms/InputField/InputField'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import WysiwygField from 'src/components/atoms/WysiwygField/WysiwygField'
import FormInputList from 'src/components/molecules/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { referenceToId } from 'src/components/Order/CompleteQuestionnaireOrderSidepanelForm/CompleteQuestionnaireOrderSidepanelForm'
import { GET_SIGNING_PRACTITIONER_FOR_PDF } from 'src/hooks/useCreateDocumentCaseSignaturePdf/useCreateDocumentCaseSignaturePdf'
import { useCreateGenericPdf } from 'src/hooks/useCreateGenericPdf/useCreateGenericPdf'
import useIsPatientChart from 'src/hooks/useIsPatientChart/useIsPatientChart'
import {
  useCreateDocument,
  useFindPatientDocumentQuery,
  useUpdateDocument,
} from 'src/pages/PatientChartsPage/PatientDocuments/usePatientDocuments'
import { useSidepanel } from 'src/providers/context/SidepanelContext'
import { openNewTab } from 'src/utils'

import SidepanelNotAvailable from '../SidepanelNotAvailable/SidepanelNotAvailable'

const SidepanelFooter = ({
  onCancel,
  onSaveAsDraft,
  submitting,
  savingDraft,
  disabled,
}: {
  onCancel: () => void
  onSaveAsDraft: () => void
  submitting: boolean
  savingDraft: boolean
  disabled: boolean
}) => {
  return (
    <StackView
      space={50}
      direction="row"
      justifyContent="between"
      data-testid="sidepanel-button-footer"
    >
      <Button
        data-testid="sidepanel-cancel-btn"
        buttonStyle="ghost"
        onClick={onCancel}
      >
        Cancel
      </Button>

      <StackView space={50} direction="row" fullWidth={false}>
        <Button
          data-testid="sidepanel-save-as-draft-btn"
          buttonStyle="secondary"
          onClick={onSaveAsDraft}
          loading={savingDraft}
          disabled={disabled}
        >
          Save as draft
        </Button>

        <Submit
          data-testid="sidepanel-confirm-btn"
          buttonStyle="primary"
          loading={submitting}
          disabled={disabled}
        >
          Save and sign
        </Submit>
      </StackView>
    </StackView>
  )
}

const getPdfTitle = ({
  title,
  selectedType,
  selectedSubType,
}: {
  title?: string
  selectedType: CreatableDocumentType
  selectedSubType: CreatableDocumentSubType
}) => {
  return selectedType && selectedSubType
    ? creatableDocumentTypes[selectedType].subTypes[selectedSubType]
    : selectedType && selectedType !== 'OTHER'
    ? creatableDocumentTypes[selectedType].display
    : title
}

const SidepanelPatientDocumentCreate = () => {
  const { documentId } = useParams()
  const [submitting, setSubmitting] = useState<'DRAFT' | 'FINAL'>(null)
  const { isPatientChart, patientId } = useIsPatientChart()
  const { isGenerating, generateGenericPdf } = useCreateGenericPdf()
  const [createPatientDocument] = useCreateDocument()
  const [updatePatientDocument] = useUpdateDocument()
  const { data, loading } = useFindPatientDocumentQuery(documentId)
  const { currentUser } = useEmrAuth()

  const {
    sidepanelContext: { type: typeParameter, subType: subTypeParameter },
    closeSidePanel,
  } = useSidepanel()

  const { data: practitionersData } = useQuery<SelectActivePractitioners>(
    ACTIVE_PRACTITIONERS_QUERY
  )

  const activePractitioners = practitionersData?.activePractitioners
  const formMethods = useForm({
    defaultValues: {
      type: typeParameter,
      subType: subTypeParameter,
      content: '',
      title: '',
      signedByPractitionerId: currentUser.practitionerId,
    },
  })

  const { data: practitionerData } = useQuery(
    GET_SIGNING_PRACTITIONER_FOR_PDF,
    {
      variables: {
        id: formMethods.watch('signedByPractitionerId'),
      },
    }
  )

  const authenticator = data?.document?.authenticatedByReference
    ? activePractitioners?.find(
        (practitioner) =>
          practitioner.medplumId ===
          referenceToId(data?.document?.authenticatedByReference)
      )
    : undefined

  useEffect(() => {
    if (data?.document) {
      formMethods.reset({
        type: data.document.type.code,
        subType: data.document.subType?.code,
        content: data.document.textContent,
        title: data.document.title,
        signedByPractitionerId: authenticator?.id,
      })
    }
  }, [data, formMethods, authenticator])

  const { watch, getValues } = formMethods

  const selectedType = watch('type') as CreatableDocumentType
  const selectedSubType = watch('subType') as CreatableDocumentSubType
  const signedByPractitionerId = watch('signedByPractitionerId')
  const content = watch('content')

  const typeOptions = Object.keys(creatableDocumentTypes).map((key) => {
    return {
      name: creatableDocumentTypes[key].display,
      value: key,
    }
  })

  const typeHasSubTypes = selectedType
    ? Object.keys(creatableDocumentTypes[selectedType]?.subTypes).length > 0
    : false

  const subTypeOptions = typeHasSubTypes
    ? Object.keys(creatableDocumentTypes[selectedType].subTypes).map((key) => {
        return {
          name: creatableDocumentTypes[selectedType].subTypes[key],
          value: key,
        }
      })
    : undefined

  const contentLabel =
    selectedType && selectedSubType
      ? creatableDocumentTypes[selectedType].subTypes[selectedSubType]
      : selectedType && selectedType !== 'OTHER'
      ? creatableDocumentTypes[selectedType].display
      : 'Content'

  const onCompleted = () => {
    setSubmitting(null)
    closeSidePanel()
  }

  const onError = () => {
    if (submitting === 'DRAFT') {
      toast.error('Failed to save draft')
    } else {
      toast.error('Failed to sign document')
    }
    setSubmitting(null)
  }
  const saveAsDraft = async () => {
    setSubmitting('DRAFT')
    const title = getValues('title')
    const generated = await generateGenericPdf({
      patientId,
      title: getPdfTitle({ title, selectedType, selectedSubType }),
      content: content,
      addSignature: formMethods.watch('signedByPractitionerId') ? true : false,
      signedByPractitioner: practitionerData?.practitioner,
    })

    const input = {
      patientId,
      type: selectedType,
      subType: selectedSubType,
      docStatus: 'PRELIMINARY',
      binary: {
        content: getBase64ContentFromDataUri(generated),
        contentType: 'application/pdf',
      },
      textContent: content,
      title,
      signedByPractitionerId,
    }

    if (documentId) {
      await updatePatientDocument({
        variables: {
          id: documentId,
          input,
        },
        onCompleted,
        onError,
      })
    } else {
      await createPatientDocument({
        variables: {
          input,
        },
        onCompleted,
        onError,
      })
    }
  }

  const onSubmit = async () => {
    const title = getValues('title')
    setSubmitting('FINAL')
    const generated = await generateGenericPdf({
      patientId,
      title: getPdfTitle({ title, selectedType, selectedSubType }),
      content: content,
      addSignature: true,
      signedByPractitioner: practitionerData?.practitioner,
    })

    const input = {
      patientId,
      type: selectedType,
      subType: selectedSubType,
      docStatus: 'FINAL',
      binary: {
        content: getBase64ContentFromDataUri(generated),
        contentType: 'application/pdf',
      },
      textContent: content,
      title,
      signedByPractitionerId,
    }

    if (documentId) {
      await updatePatientDocument({
        variables: {
          id: documentId,
          input,
        },
        onCompleted,
        onError,
      })
    } else {
      await createPatientDocument({
        variables: {
          input,
        },
        onCompleted,
        onError,
      })
    }
  }

  if (!isPatientChart) {
    return <SidepanelNotAvailable />
  }

  return (
    <SidepanelPage
      header="Create document"
      description="Complete necessary details below."
      loading={loading}
    >
      <SidepanelForm
        footerElement={
          <SidepanelFooter
            onCancel={closeSidePanel}
            onSaveAsDraft={saveAsDraft}
            disabled={!!submitting || isGenerating}
            savingDraft={submitting === 'DRAFT'}
            submitting={submitting === 'FINAL'}
          />
        }
        formMethods={formMethods}
        onSubmit={onSubmit}
      >
        <StackView space={150}>
          <FormInputList
            divider={false}
            items={[
              {
                name: 'type',
                label: 'Type',
                formInputComponent: DropdownField,
                required: true,
                direction: 'col',
                inputProps: {
                  options: typeOptions,
                  onSelect: (selected) => {
                    formMethods.setValue('type', selected)
                    formMethods.setValue('subType', undefined)
                  },
                },
              },
              {
                name: 'subType',
                label: 'Sub-type',
                formInputComponent: DropdownField,
                required: typeHasSubTypes,
                hide: !typeHasSubTypes,
                direction: 'col',
                inputProps: {
                  options: subTypeOptions,
                },
              },
              {
                name: 'title',
                label: 'Document title',
                formInputComponent: InputField,
                required: false,
                direction: 'col',
              },
              {
                name: 'content',
                label: contentLabel,
                formInputComponent: WysiwygField,
                required: true,
                direction: 'col',
                inputProps: {
                  testId: 'create-patient-document-content',
                  macroPhraseSection: 'DOCUMENTS',
                  defaultValue: content,
                },
              },
            ]}
          />
          <Divider />
          <FormInputList
            items={[
              {
                name: 'signedByPractitionerId',
                label: 'Signing individual',
                formInputComponent: ActivePractitionerSelectCell,
                direction: 'col',
                inputProps: {
                  validation: {
                    shouldUnregister: true,
                  },
                },
                required: true,
              },
            ]}
          />
          <StackView space={100} direction="row">
            <StackView space={25}>
              <Typography textStyle="title-xs">Schedule a visit</Typography>
              <Typography textStyle="body-s" color="text-base-color-fg-muted">
                If applicable, open up a new tab to schedule a visit for the
                patient.
              </Typography>
            </StackView>
            <Button
              buttonStyle="secondary"
              text="New visit"
              icon={CalendarDaysIcon}
              onClick={() =>
                openNewTab(
                  routes.schedule({
                    scheduleDateFilter: format(new Date(), 'yyyy-MM-dd'),
                    scheduleViewType: 'CALENDAR',
                    sidepanelContext: JSON.stringify({
                      route: `/appointments/new`,
                      patientId,
                    }),
                  })
                )
              }
            />
          </StackView>
        </StackView>
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelPatientDocumentCreate
