import { isAfter, parseISO, startOfToday } from 'date-fns'
import {
  GetVaccineInventoryForVaccineInventorySelectField,
  NdcSaleDetails,
  VaccineFundingSource,
} from 'types/graphql'

import { useFormContext } from '@redwoodjs/forms'
import { useQuery } from '@redwoodjs/web'

import DateWithAlarm from 'src/components/molecules/DateWithAlarm/DateWithAlarm'
import {
  fundingSourceColorMap,
  fundingSourceDescriptionMap,
} from 'src/pages/Sidepanel/SidepanelVaccineInventory/AddInventoryTable'

import Badge from '../Badge'
import { DropdownField, DropdownFieldProps } from '../Dropdown'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import StackView from '../StackView/StackView'
import Typography from '../Typography/Typography'

export type VaccineInventorySelectFieldProps = {
  cvxCode: string
  locationId: string
  onSelection?: (selection) => void
  hideExpired?: boolean
  recreateDeletedInventory?: boolean
  deletedInventory?: {
    id: string
    lotNumber: string
    expirationDate: string
    location: {
      id: string
    }
    fundingSource: VaccineFundingSource
    currentDoses: number
    status: string
    ndcDetails: NdcSaleDetails
  }
} & Omit<DropdownFieldProps, 'options'>

const GET_VACCINE_INVENTORY = gql`
  query GetVaccineInventoryForVaccineInventorySelectField {
    getVaccineInventory {
      id
      lotNumber
      expirationDate
      status
      comment
      ndcSale
      ndcDetails {
        cvxCode
        brandName
        manufacturer
        ndcSale
        ndcUse
        cvxCodeAndDescription
        defaultDoseAmount
        defaultDoseUnit
        defaultRoute
      }
      vaccineInventoryLocations {
        id
        location {
          id
          name
        }
        fundingSource
        currentDoses
      }
    }
  }
`

export const VaccineInventorySelectField = ({
  name,
  cvxCode,
  locationId,
  onSelection,
  hideExpired,
  recreateDeletedInventory,
  deletedInventory,
  ...props
}: VaccineInventorySelectFieldProps) => {
  const { data, loading } =
    useQuery<GetVaccineInventoryForVaccineInventorySelectField>(
      GET_VACCINE_INVENTORY
    )

  const { watch } = useFormContext()
  const selectedId = watch(name)

  if (loading) return <LoadingSpinner />

  let options = []
  if (recreateDeletedInventory) {
    options.push(deletedInventory)
  }

  data?.getVaccineInventory.forEach((inventory) => {
    inventory.vaccineInventoryLocations.forEach((vaccineInventoryLocation) => {
      options.push({
        ...inventory,
        ...vaccineInventoryLocation,
      })
    })
  })

  options = options
    .filter((inventory) => inventory.ndcDetails.cvxCode === cvxCode)
    .filter((inventory) => inventory.location.id === locationId)
    .filter((inventory) => ['ACTIVE', 'DELETED'].includes(inventory.status))
    .filter(
      (inventory) => inventory.currentDoses > 0 || inventory.id === selectedId
    )
    .filter((inventory) =>
      hideExpired
        ? isAfter(parseISO(inventory.expirationDate), startOfToday())
        : true
    )
    .map((inventory) => {
      return {
        name: inventory.id,
        value: inventory.id,
        data: inventory,
        render: () => (
          <StackView
            direction="row"
            alignItems="center"
            justifyContent="between"
          >
            <Typography>{inventory.lotNumber}</Typography>
            <div className="w-40">
              <DateWithAlarm date={inventory.expirationDate} alarmAt={60} />
            </div>
            <Badge
              color={fundingSourceColorMap[inventory.fundingSource]}
              size="s"
            >
              {fundingSourceDescriptionMap[inventory.fundingSource]}
            </Badge>
            <Typography fontWeight="bold">{inventory.currentDoses}</Typography>
          </StackView>
        ),
      }
    })

  return (
    <DropdownField
      name={name}
      {...props}
      options={options}
      onSelection={(value) => {
        onSelection &&
          onSelection(options.find((x) => x.value === value?.value)?.data)
      }}
    />
  )
}

export default VaccineInventorySelectField
