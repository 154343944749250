import { useMemo } from 'react'

import { OrderCategory, OrderType } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import { ComboboxOption } from '../atoms/ComboboxField'

const ORDER_TYPES_QUERY = gql`
  query GetOrderTypesForCategory($category: OrderCategory!) {
    orderTypes(category: $category) {
      id
      code
      display
      synonyms
      defaultICDCode
      defaultICD {
        id
        code
        description
      }
      defaultCPT {
        id
        code
        description
      }
    }
  }
`
export const useOrderTypes = (category: OrderCategory) => {
  return useQuery(ORDER_TYPES_QUERY, {
    variables: {
      category,
    },
  })
}

export const useGetOrderType = (category: OrderCategory, code: string) => {
  const { data: orderTypes } = useOrderTypes(category)
  return orderTypes?.orderTypes?.find((option) => option.code === code)
}

export const useOrderTypeOptions = (
  category: OrderCategory
): ComboboxOption[] => {
  const { data: orderTypes } = useOrderTypes(category)
  return useMemo(() => {
    if (!orderTypes) return []
    return orderTypes.orderTypes.map((orderType) => ({
      value: orderType.code,
      name: orderType.display,
      synonyms: orderType.synonyms,
    }))
  }, [orderTypes])
}

export const getOrderTypeDefaults = (
  category: OrderCategory,
  orderType: OrderType,
  formMethods
) => {
  if (category === 'VAX') return

  if (!orderType) return
  const { defaultICD, defaultCPT } = orderType

  if (defaultICD) {
    formMethods.setValue('orderInput.diagnosisCodes', [
      {
        code: {
          id: defaultICD.id,
          code: defaultICD.code,
          description: defaultICD.description,
        },
      },
    ])
  }
  if (defaultCPT) {
    // TODO: fix naming. This is really a billing code. Not just CPT.
    formMethods.setValue('orderInput.cptCode', defaultCPT)
  }
}
