import { useLazyQuery } from '@apollo/client'

import { PATIENT_RELATED_PERSON_RELATIONSHIPS_FRAGMENT } from 'src/pages/PatientChartsPage/PatientVisits/useVisit'
import {
  PATIENT_DETAILS_FRAGMENT,
  TENANT_LETTERHEAD_FRAGMENT,
} from 'src/pdf/fragments'

export const ON_DEMAND_IMMUNIZATIONS_FRAGMENT = gql`
  fragment OnDemandImmunizationFragment on Patient {
    id
    ...PatientDetailsFragment
    ...PatientRelatedPersonRelationshipsFragment
    immunizations {
      externalId
      code {
        code
        display
        system
      }
      occurredAt
      develoVaccineName
    }
    primaryProvider {
      id
      givenName
      familyName
    }
    tenant {
      ...TenantLetterheadFragment
    }
  }
  ${PATIENT_DETAILS_FRAGMENT}
  ${PATIENT_RELATED_PERSON_RELATIONSHIPS_FRAGMENT}
  ${TENANT_LETTERHEAD_FRAGMENT}
`

const IMMUNIZATIONS_ON_DEMAND_MEDICAL_RECORDS_QUERY = gql`
  query OnDemandPatientImmunizationsMedicalRecord($patientId: String!) {
    patientPortalPatient(patientId: $patientId) {
      ...OnDemandImmunizationFragment
    }
  }
  ${ON_DEMAND_IMMUNIZATIONS_FRAGMENT}
`

export const useOnDemandImmunizationsMedicalRecordsQuery = ({
  patientId,
}: {
  patientId: string
}) => {
  return useLazyQuery(IMMUNIZATIONS_ON_DEMAND_MEDICAL_RECORDS_QUERY, {
    variables: {
      patientId,
    },
  })
}

export const CONTACT_INFORMATION_FRAGMENT = gql`
  fragment ContactInformationFragment on ContactInformation {
    id
    mobileNumber
    homeNumber
    emailAddress
    faxNumber
    primaryLanguage
    secondaryLanguage
    homeAddress {
      id
      line1
      line2
      city
      postalCode
      state
    }
    mailingAddress {
      id
      line1
      line2
      city
      postalCode
      state
    }
  }
`

export const ON_DEMAND_DEMOGRAPHICS_FRAGMENT = gql`
  fragment OnDemandDemographicsFragment on Patient {
    id
    mrn
    active
    deceased
    inCollections
    givenName
    familyName
    namePrefix
    nameSuffix
    middleName
    preferredName
    birthDate
    sexAtBirth
    legalStatus
    contactInformation {
      ...ContactInformationFragment
    }
    primaryProvider {
      id
      givenName
      familyName
    }
    defaultPharmacy {
      id
      name
      phone
      fax
      address1
      address2
      city
      state
      postalCode
    }
    primaryGuardianId
    primaryGuarantorId
    patientRelatedPersonRelationships {
      id
      doesResideWith
      guardianshipType
      isGuarantor
      relationshipType
      relatedPerson {
        id
        givenName
        familyName
        namePrefix
        nameSuffix
        middleName
        preferredName
        birthDate
        occupation
        employer
        maritalStatus
        contactInformation {
          ...ContactInformationFragment
        }
      }
    }
    insuranceOptOutStatus
    insuranceCoverages {
      id
      status
      coordinationOfBenefitsType
      beneficiaryIsSubscriber
      subscriberId
      planMemberIdentifier
      planType
      payer {
        id
        displayName
      }
    }
    tenant {
      ...TenantLetterheadFragment
    }
  }
  ${TENANT_LETTERHEAD_FRAGMENT}
  ${CONTACT_INFORMATION_FRAGMENT}
`

const DEMOGRAPHICS_ON_DEMAND_MEDICAL_RECORDS_QUERY = gql`
  query OnDemandPatientDemographicsMedicalRecord($patientId: String!) {
    patientPortalPatient(patientId: $patientId) {
      ...OnDemandDemographicsFragment
    }
  }
  ${ON_DEMAND_DEMOGRAPHICS_FRAGMENT}
`

export const useOnDemandDemographicsMedicalRecordsQuery = ({
  patientId,
}: {
  patientId: string
}) => {
  return useLazyQuery(DEMOGRAPHICS_ON_DEMAND_MEDICAL_RECORDS_QUERY, {
    variables: {
      patientId,
    },
  })
}

export const GROWTH_CHARTS_ON_DEMAND_MEDICAL_RECORDS_QUERY = gql`
  query OnDemandPatientGrowthChartsMedicalRecord($patientId: String!) {
    patientPortalPatient(patientId: $patientId) {
      id
      defaultGrowthChartDataset
    }
  }
`

export const useOnDemandGrowthChartsMedicalRecordsQuery = ({
  patientId,
}: {
  patientId: string
}) => {
  return useLazyQuery(GROWTH_CHARTS_ON_DEMAND_MEDICAL_RECORDS_QUERY, {
    variables: {
      patientId,
    },
  })
}
