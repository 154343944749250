import { ReactElement } from 'react'

import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/20/solid'

import Box from 'src/components/atoms/Box/Box'
import Button from 'src/components/atoms/Button/Button'
import Card from 'src/components/atoms/Card/Card'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'

import BaseModal from './BaseModal'

interface ModalProps {
  title: string
  content: string | React.ReactNode
  confirmText: string
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  modalStyle?: 'warning' | 'danger'
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  testId?: string
  onConfirm?: () => any | (() => Promise<any>)
  onClose?: () => void
  hideIcon?: boolean
}

export default function Modal({
  title,
  content,
  confirmText,
  isOpen,
  modalStyle,
  setIsOpen,
  onConfirm,
  onClose,
  testId,
  hideIcon = false,
}: ModalProps): ReactElement {
  const isWarning = modalStyle === 'warning'
  return (
    <BaseModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      testId={testId}
      onClose={onClose}
    >
      <Card
        className="max-w-md"
        footer={
          <StackView direction="row" space={100} justifyContent="end">
            <Button
              type="button"
              testId="modal-cancel-btn"
              buttonStyle="secondary"
              onClick={() => {
                setIsOpen(false)
              }}
            >
              Cancel
            </Button>
            <Button
              type="button"
              data-testid="modal-confirm-btn"
              buttonStyle={isWarning ? 'primary' : 'danger'}
              onClick={() => {
                if (onConfirm) {
                  onConfirm()
                }
                setIsOpen(false)
              }}
            >
              {confirmText}
            </Button>
          </StackView>
        }
      >
        <Box padding={100}>
          <StackView space={75} alignItems="start">
            {!hideIcon && (
              <Box
                className="flex h-16 w-16 items-center justify-center rounded-full"
                color={`${
                  isWarning
                    ? 'bg-base-color-bg-attention'
                    : 'bg-base-color-bg-danger'
                }`}
              >
                {isWarning ? (
                  <ExclamationCircleIcon className="h-8 w-8 text-warning" />
                ) : (
                  <ExclamationTriangleIcon className="h-8 w-8 text-danger" />
                )}
              </Box>
            )}
            <Box className="flex justify-start">
              <Typography textStyle="title-xs" className="text-left">
                {title}
              </Typography>
            </Box>
            <Box className="text-left">
              {typeof content === 'string' ? (
                <Typography
                  textStyle="body-s"
                  color="text-base-color-fg-subtle"
                >
                  {content}
                </Typography>
              ) : (
                content
              )}
            </Box>
          </StackView>
        </Box>
      </Card>
    </BaseModal>
  )
}
