import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'

import Button from 'src/components/atoms/Button'
import StackView from 'src/components/atoms/StackView'

const PaginationNumber = ({
  pageNumber,
  active,
  onClickPaginationButton,
}: {
  pageNumber: number
  active: boolean
  onClickPaginationButton: (page: number) => void
}) => {
  return (
    <Button
      testId={`pagination-number-${pageNumber + 1}`}
      buttonStyle="ghost"
      className={clsx(
        active && [
          'bg-comp-button-color-ghost-pressed-bg',
          'border-comp-button-color-ghost-pressed-border',
          'text-comp-button-color-ghost-pressed-fg',
          'pointer-events-none',
        ]
      )}
      text={(pageNumber + 1).toString()}
      onClick={() => onClickPaginationButton(pageNumber)}
    />
  )
}

const PaginationEllipsis = () => {
  return (
    <Button buttonStyle="ghost" disabled>
      ...
    </Button>
  )
}

const PaginationNumberGroup = ({
  page,
  pages,
  onClickPaginationButton,
}: {
  page: number
  pages: number
  onClickPaginationButton: (page: number) => void
}) => {
  let isPageNumberOutOfRange = false

  const pageGroup = [...new Array(pages)].map((_, index) => {
    const pageNumber = index
    const isPageNumberFirst = pageNumber === 0
    const isPageNumberLast = pageNumber === pages - 1
    const isCurrentPageWithinOnePageNumbers = Math.abs(pageNumber - page) <= 1

    if (
      isPageNumberFirst ||
      isPageNumberLast ||
      isCurrentPageWithinOnePageNumbers
    ) {
      isPageNumberOutOfRange = false
      return (
        <PaginationNumber
          key={pageNumber}
          pageNumber={pageNumber}
          active={pageNumber === page}
          onClickPaginationButton={onClickPaginationButton}
        />
      )
    }

    if (!isPageNumberOutOfRange) {
      isPageNumberOutOfRange = true
      return <PaginationEllipsis key={pageNumber} />
    }
  })

  return (
    <StackView
      testId="pagination-numbers-group"
      direction="row"
      alignItems="center"
      justifyContent="center"
      space={25}
      className="grow"
    >
      {pageGroup}
    </StackView>
  )
}

const CustomPaginationPanel = ({
  page,
  totalPages,
  onClickPaginationButton,
}: {
  page: number
  totalPages: number
  onClickPaginationButton: (page: number) => void
}) => {
  const showPreviousButton = page !== 0
  const showNextButton = totalPages > 0 && page + 1 !== totalPages
  return (
    <StackView
      testId="custom-pagination-panel"
      direction="row"
      alignItems="center"
      justifyContent="between"
      className={clsx([
        'absolute',
        'border-x-base-border-width-selectable-s',
        'border-b-base-border-width-selectable-s',
        'border-base-color-border-subtle',
        'rounded-b-base-border-radius-container-m',
        'p-core-space-100',
      ])}
    >
      <StackView
        direction="row"
        justifyContent="start"
        className="h-core-size-200 w-core-size-800"
      >
        {showPreviousButton && (
          <Button
            testId="pagination-previous-button"
            text="Previous"
            icon={ArrowLeftIcon}
            buttonStyle="secondary"
            iconPlacement="left"
            onClick={() => onClickPaginationButton(page - 1)}
            className="w-fit"
          />
        )}
      </StackView>

      <PaginationNumberGroup
        page={page}
        pages={totalPages}
        onClickPaginationButton={onClickPaginationButton}
      />

      <StackView
        direction="row"
        justifyContent="end"
        className="h-core-size-200 w-core-size-800"
      >
        {showNextButton && (
          <Button
            testId="pagination-next-button"
            text="Next"
            icon={ArrowRightIcon}
            buttonStyle="secondary"
            iconPlacement="right"
            disabled={page + 1 === totalPages || totalPages === 0}
            onClick={() => onClickPaginationButton(page + 1)}
            className="w-fit"
          />
        )}
      </StackView>
    </StackView>
  )
}

export default CustomPaginationPanel
