import { Option } from 'src/components/atoms/Select/Select'

export const VISIT_AVAILABILITY = 'visit-availability'
export const OFFICE_TIME = 'office-time'

export const AVAILABILITY_TYPES_OBJ = {
  [VISIT_AVAILABILITY]: {
    value: VISIT_AVAILABILITY,
    name: 'Visit availability',
  },

  [OFFICE_TIME]: {
    value: OFFICE_TIME,
    name: 'Office time',
  },
}

export const AVAILABILITY_EXCEPTION_TYPES_OBJ = {
  ...AVAILABILITY_TYPES_OBJ,
}

export const AVAILABILITY_TYPES: Option[] = Object.values(
  AVAILABILITY_TYPES_OBJ
)

export const AVAILABILITY_EXCEPTION_TYPES: Option[] = Object.values(
  AVAILABILITY_EXCEPTION_TYPES_OBJ
)
