import { PlusIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { DateTimeFormatter, LocalDate } from '@js-joda/core'
import { Locale } from '@js-joda/locale_en-us'
import { formatDisplayName } from 'common/utils'
import { useParams as useSidepanelParams } from 'react-router-dom'
import {
  GetSupervisingPractitionerSidepanelData,
  SetSupervisingPractitionerAssignments,
  SetSupervisingPractitionerAssignmentsVariables,
} from 'types/graphql'

import { useFieldArray, useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { useEmrAuth } from 'src/auth'
import Box from 'src/components/atoms/Box/Box'
import Button from 'src/components/atoms/Button/Button'
import { MultiCheckboxField } from 'src/components/atoms/Checkbox'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import SelectField from 'src/components/atoms/SelectField/SelectField'
import StackView from 'src/components/atoms/StackView/StackView'
import { FormInputList } from 'src/components/molecules/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import TabNav from 'src/components/molecules/TabNav/TabNav'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

import { useSupervisingPractitionerAssignmentSidepanelData } from './useSupervisingPractitionerAssignmentSidepanelData'

const MUTATION = gql`
  mutation SetSupervisingPractitionerAssignments(
    $date: Date!
    $locationId: String!
    $assignments: [SetSupervisingPractitionerAssignmentInput!]!
  ) {
    setSupervisingPractitionerAssignments(
      date: $date
      locationId: $locationId
      assignments: $assignments
    ) {
      id
      supervisingPractitionerClinicalDetail {
        id
        practitioner {
          id
          givenName
          familyName
        }
      }
      midLevelPractitionerClinicalDetails {
        id
        practitioner {
          id
          givenName
          familyName
        }
      }
    }
  }
`

const SidepanelSupervisingPractitionerAssignmentHelper = ({
  data,
}: {
  data: GetSupervisingPractitionerSidepanelData
}) => {
  const { hasRole } = useEmrAuth()
  const isEditable = hasRole('ADMIN')

  const { closeSidePanel } = useSidepanel()
  const { date, locationId } = useSidepanelParams()

  const [setSupervisingPractitionerAssignments, { loading }] = useMutation<
    SetSupervisingPractitionerAssignments,
    SetSupervisingPractitionerAssignmentsVariables
  >(MUTATION, {
    refetchQueries: ['GetSupervisingPractitionerSidepanelData'],
    onCompleted: () => {
      toast.success('Supervising providers updated')
      closeSidePanel()
    },
  })
  const formMethods = useForm({
    defaultValues: {
      assignments: data.supervisingPractitionerAssignments.map(
        (assignment) => ({
          supervisingPractitionerClinicalDetailId:
            assignment.supervisingPractitionerClinicalDetail.id,
          midLevelPractitionerClinicalDetailIds:
            assignment.midLevelPractitionerClinicalDetails.map(({ id }) => id),
        })
      ),
    },
  })
  const assignments = formMethods.watch('assignments')

  const supervisingPractitionerIdToSelectedIndex = assignments.reduce(
    (acc, assignment, i) => {
      if (!assignment.supervisingPractitionerClinicalDetailId) return acc

      return {
        ...acc,
        [assignment.supervisingPractitionerClinicalDetailId]: i,
      }
    },
    {}
  )

  const midLevelPractitionerIdToSelectedIndex = assignments.reduce(
    (acc, assignment, i) => {
      const selectedAtThisIndex =
        assignment.midLevelPractitionerClinicalDetailIds.reduce(
          (acc, id) => ({
            ...acc,
            [id]: i,
          }),
          {}
        )

      return {
        ...acc,
        ...selectedAtThisIndex,
      }
    },
    {}
  )

  const { fields, append, remove } = useFieldArray({
    name: 'assignments',
    control: formMethods.control,
  })

  return (
    <SidepanelForm
      divider={false}
      onSubmit={(formData) => {
        void setSupervisingPractitionerAssignments({
          variables: {
            date,
            locationId,
            assignments: formData.assignments.map((assignment) => ({
              supervisingPractitionerClinicalDetailId:
                assignment.supervisingPractitionerClinicalDetailId,
              midLevelPractitionerClinicalDetailIds:
                assignment.midLevelPractitionerClinicalDetailIds,
            })),
          },
        })
      }}
      footerProps={{
        submitText: 'Save',
        submitting: loading,
        disabled: !isEditable || loading,
      }}
      formMethods={formMethods}
    >
      <StackView>
        {data.locations.length > 1 ? (
          <TabNav
            id="supervising-practitioner-location-tabs"
            rootPath=""
            sidepanel
            tabs={data.locations.map((loc) => ({
              name: loc.name,
              to: `/supervising-practitioner-assignment/${loc.id}/${date}`,
            }))}
          />
        ) : null}
        <StackView divider>
          {fields.map((field, supervisingPractitionerIndex) => {
            return (
              <FormInputList
                testId={`supervising-practitioner-form-section-${supervisingPractitionerIndex}`}
                className="py-core-size-150"
                key={field.id}
                divider={false}
                title={[
                  fields.length > 1
                    ? `${supervisingPractitionerIndex + 1}.`
                    : undefined,
                  'Supervising provider',
                ]
                  .filter(Boolean)
                  .join(' ')}
                action={
                  <Button
                    buttonStyle="secondary"
                    text="Remove"
                    icon={XMarkIcon}
                    testId="supervising-practitioner-remove-btn"
                    onClick={() => remove(supervisingPractitionerIndex)}
                    disabled={!isEditable}
                  />
                }
                items={[
                  {
                    label: 'Supervising practitioner',
                    name: `assignments.${supervisingPractitionerIndex}.supervisingPractitionerClinicalDetailId`,
                    formInputComponent: SelectField,
                    required: true,
                    direction: 'col',
                    inputProps: {
                      disabled: !isEditable,
                      options: data.supervisingPractitionerOptions
                        .filter((practitioner) => {
                          const selectedAtIndex =
                            supervisingPractitionerIdToSelectedIndex[
                              practitioner.id
                            ]

                          return (
                            selectedAtIndex === supervisingPractitionerIndex ||
                            selectedAtIndex == null
                          )
                        })
                        .map((option) => ({
                          value: option.id,
                          display: `${formatDisplayName(
                            option.practitioner
                          )} (${option.practitioner.user.userType.name})`,
                        })),
                    },
                  },
                  {
                    label: 'Mid-level members',
                    subtitle:
                      'Select all the mid-level team members that are linked to the supervising provider. A mid-level team member can not be linked to more than one supervising provider.',
                    name: `assignments.${supervisingPractitionerIndex}.midLevelPractitionerClinicalDetailIds`,
                    formInputComponent: MultiCheckboxField,
                    direction: 'col',
                    inputProps: {
                      selectAllDisabled: !isEditable,
                      values: data.midLevelPractitionerOptions
                        .filter((midLevelPractitioner) => {
                          const selectedAtIndex =
                            midLevelPractitionerIdToSelectedIndex[
                              midLevelPractitioner.id
                            ]

                          return (
                            selectedAtIndex === supervisingPractitionerIndex ||
                            selectedAtIndex == null
                          )
                        })
                        .map((midLevelPractitioner) => ({
                          disabled: !isEditable,
                          value: midLevelPractitioner.id,
                          label: formatDisplayName(
                            midLevelPractitioner.practitioner
                          ),
                          description:
                            midLevelPractitioner.practitioner.user.userType
                              .name,
                        })),
                      checkboxPosition: 'right',
                      checkboxStyle: 'card',
                      showSelectAll: true,
                      selectAllLabel: 'Select all available mid-level members',
                      validation: {
                        validate: (value) =>
                          value.length
                            ? true
                            : 'Must select at least one mid-level member',
                      },
                    },
                  },
                ]}
              />
            )
          })}
          <Box verticalPadding={150} fullWidth>
            <Button
              icon={PlusIcon}
              buttonStyle="secondary"
              text="Add supervising practitioner"
              className="w-full"
              testId="supervising-practitioner-add-btn"
              disabled={!isEditable}
              onClick={() =>
                append({
                  supervisingPractitionerClinicalDetailId: undefined,
                  midLevelPractitionerClinicalDetailIds: [],
                })
              }
            />
          </Box>
        </StackView>
      </StackView>
    </SidepanelForm>
  )
}

const SidepanelSupervisingPractitionerAssignment = () => {
  const { date: dateString, locationId } = useSidepanelParams()

  const { data, loading } = useSupervisingPractitionerAssignmentSidepanelData({
    date: dateString,
    locationId,
  })

  const date = LocalDate.parse(dateString)

  return (
    <SidepanelPage
      header="Set supervising provider(s)"
      description={`Modify and set the supervising provider(s) for ${date.format(
        DateTimeFormatter.ofPattern('EEEE, LLLL d, yyyy').withLocale(Locale.US)
      )}`}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <SidepanelSupervisingPractitionerAssignmentHelper data={data} />
      )}
    </SidepanelPage>
  )
}

export default SidepanelSupervisingPractitionerAssignment
