import { useEffect } from 'react'

import { ArrowUturnLeftIcon, PencilIcon } from '@heroicons/react/24/solid'
import { format, parseISO } from 'date-fns'
import { useParams } from 'react-router-dom'

import { Form, useForm } from '@redwoodjs/forms'
import { navigate } from '@redwoodjs/router'

import Button from 'src/components/atoms/Button'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import DataDisplayList from 'src/components/molecules/DataDisplayList'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { routeOptions } from 'src/data/vaccineRouteOptions'
import { siteOptions } from 'src/data/vaccineSiteOptions'
import { vfcOptions } from 'src/data/VFCOptions'
import useIsPatientChart from 'src/hooks/useIsPatientChart/useIsPatientChart'
import { sidepanelRoute } from 'src/lib/routes'
import { usePatientOrdersQuery } from 'src/pages/PatientChartsPage/PatientOrders/usePatientOrders'

import SidepanelNotAvailable from '../SidepanelNotAvailable/SidepanelNotAvailable'
import { fundingSourceDescriptionMap } from '../SidepanelVaccineInventory/AddInventoryTable'

import { VaccineRefusalCheckboxes } from './SidepanelVisitVaccineAdministrationEdit'
import { useGetImmunizationToAdminister } from './useGetImmunizationToAdminister'

const VaccinationCoreDetails = ({ immunizationData }) => {
  return (
    <DataDisplayList
      title="Vaccination core details"
      data={[
        {
          label: 'Patient eligibility for Vaccines for Children (VFC)',
          value: (
            <Typography>
              {vfcOptions.find(
                (vfc) =>
                  vfc.value ===
                  immunizationData?.immunization?.patientEligibilityForVFC
              )?.name || '-'}
            </Typography>
          ),
        },
      ]}
    />
  )
}

export const InventoryDetails = ({ inventory }) => {
  return (
    <DataDisplayList
      testId="inventory-details"
      title="Inventory details"
      data={[
        {
          label: 'Lot number',
          hide: !inventory?.lotNumber,
          value: <Typography>{inventory?.lotNumber}</Typography>,
        },
        {
          label: 'Expiration date',
          hide: !inventory?.expirationDate,
          value: (
            <Typography>
              {inventory?.expirationDate
                ? format(parseISO(inventory?.expirationDate), 'M/d/yyyy')
                : null}
            </Typography>
          ),
        },
        {
          label: 'Funding source',
          hide: !inventory?.fundingSource,
          value: (
            <Typography>
              {fundingSourceDescriptionMap[inventory?.fundingSource]}
            </Typography>
          ),
        },
        {
          label: 'Brand name',
          value: (
            <Typography>{inventory?.ndcDetails?.brandName || '-'}</Typography>
          ),
        },
        {
          label: 'Manufacturer',
          value: (
            <Typography>
              {inventory?.ndcDetails?.manufacturer || '-'}
            </Typography>
          ),
        },
        {
          label: 'National Drug Code, sale',
          value: (
            <Typography>{inventory?.ndcDetails?.ndcSale || '-'}</Typography>
          ),
        },
        {
          label: 'National Drug Code, use',
          value: (
            <Typography>
              {inventory?.ndcDetails?.ndcUse.join(', ') || '-'}
            </Typography>
          ),
        },
        {
          label: 'CVX code & description',
          value: (
            <Typography>
              {inventory?.ndcDetails?.cvxCodeAndDescription || '-'}
            </Typography>
          ),
        },
      ]}
    />
  )
}

const PreVaccinationChecks = ({ immunizationData }) => {
  return (
    <DataDisplayList
      title="Pre-vaccination checks"
      data={[
        {
          label: 'Verbal vaccine consent was obtained',
          value: (
            <Typography>
              {immunizationData?.immunization?.verbalVaccineConsentObtained
                ? 'Yes'
                : 'No'}
            </Typography>
          ),
        },
        {
          label:
            'CDC vaccine information statement (VIS) was offered and/or provided',
          value: (
            <Typography>
              {immunizationData?.immunization?.visProvided ? 'Yes' : 'No'}
            </Typography>
          ),
        },
        {
          label:
            'Patient and/or caregiver received counseling from a physician or qualified healthcare professional',
          value: (
            <Typography>
              {immunizationData?.immunization?.receivedCounseling
                ? 'Yes'
                : 'No'}
            </Typography>
          ),
        },
        {
          label:
            'Patient was healthy and not sick with moderate or severe illness at time of vaccine administration',
          value: (
            <Typography>
              {immunizationData?.immunization?.patientWasHealthy ? 'Yes' : 'No'}
            </Typography>
          ),
        },
        {
          label: 'Vaccine consent form completed',
          value: (
            <Typography>
              {immunizationData?.immunization?.vaccineConsentFormCompleted
                ? 'Yes'
                : 'No'}
            </Typography>
          ),
        },
      ]}
    />
  )
}

const VaccineAdministrationDetails = ({ immunizationData }) => {
  return (
    <DataDisplayList
      title="Vaccine administration details"
      data={[
        {
          label: 'Practice staff',
          value: (
            <Typography>
              {immunizationData?.immunization?.performerName || '-'}
            </Typography>
          ),
        },
        {
          label: 'Clinic location',
          value: (
            <Typography>
              {immunizationData?.immunization?.locationName || '-'}
            </Typography>
          ),
        },
        {
          label: 'Date and time',
          value: (
            <Typography>
              {immunizationData?.immunization?.occurredAt
                ? format(
                    parseISO(immunizationData?.immunization?.occurredAt),
                    'MM/dd/yyyy \u2022 h:mm:aaa'
                  )
                : '-'}
            </Typography>
          ),
        },
        {
          label: 'Dose amount',
          value: (
            <Typography>
              {immunizationData?.immunization?.doseQuantity
                ? `${immunizationData.immunization.doseQuantity.value}${immunizationData.immunization.doseQuantity.unit}`
                : '-'}
            </Typography>
          ),
        },
        {
          label: 'Route',
          value: (
            <Typography>
              {routeOptions.find(
                (route) => immunizationData?.immunization?.route === route.code
              )?.display || '-'}
            </Typography>
          ),
        },
        {
          label: 'Site',
          value: (
            <Typography>
              {siteOptions.find(
                (site) => immunizationData?.immunization?.site === site.code
              )?.display || '-'}
            </Typography>
          ),
        },
      ]}
    />
  )
}

const SidepanelVisitVaccineAdministrationView = () => {
  const { patientId, isPatientChart } = useIsPatientChart()
  const { orderId, encounterId, locationId } = useParams()
  const { orders } = usePatientOrdersQuery(patientId)
  const order = orders?.find((order) => order.id === orderId)

  const immunizationId = order?.supportingInfo?.[0]?.split('/')[1]

  const { data: immunizationData, loading: immunizationLoading } =
    useGetImmunizationToAdminister(immunizationId)

  const formMethods = useForm({
    defaultValues: {
      verbalRefusalByPatient: false,
      verbalRefusalByCaregiver: false,
      refusalFormCompleted: false,
    },
  })

  useEffect(() => {
    if (immunizationData) {
      const { immunization } = immunizationData
      formMethods.reset({
        verbalRefusalByPatient: immunization.verbalRefusalByPatient,
        verbalRefusalByCaregiver: immunization.verbalRefusalByCaregiver,
        refusalFormCompleted: immunization.refusalFormCompleted,
      })
    }
  }, [immunizationData, formMethods])

  if (!isPatientChart || !order) {
    return <SidepanelNotAvailable />
  }

  const vaccineWasRefused =
    immunizationData?.immunization.verbalRefusalByPatient ||
    immunizationData?.immunization.verbalRefusalByCaregiver ||
    immunizationData?.immunization.refusalFormCompleted

  return (
    <SidepanelPage
      testId="sidepanel-vaccine-administration"
      header={order.codeDisplay}
      description={order.name}
      loading={immunizationLoading}
    >
      <StackView className="px-6 py-4" space={50}>
        {order.status !== 'REVOKED' && (
          <StackView direction="row" space={50}>
            <Button
              testId="vaccine-admin-edit"
              buttonStyle="secondary"
              text="Edit"
              icon={PencilIcon}
              onClick={() =>
                navigate(
                  sidepanelRoute(
                    {
                      route: `/encounters/${encounterId}/location/${locationId}/vaccine-admin/${order.id}/edit`,
                    },
                    location
                  )
                )
              }
            />
            <Button
              testId="vaccine-admin-undo"
              buttonStyle="secondary"
              text="Undo"
              icon={ArrowUturnLeftIcon}
              onClick={() =>
                navigate(
                  sidepanelRoute(
                    {
                      route: `/encounters/${encounterId}/location/${locationId}/vaccine-admin/${order.id}/undo`,
                    },
                    location
                  )
                )
              }
            />
          </StackView>
        )}

        <Form formMethods={formMethods}>
          <VaccineRefusalCheckboxes
            immunizationData={immunizationData}
            disabled={true}
          />
        </Form>

        {!vaccineWasRefused && typeof vaccineWasRefused !== 'undefined' && (
          <>
            <VaccinationCoreDetails immunizationData={immunizationData} />
            <InventoryDetails inventory={immunizationData.immunization} />
            <PreVaccinationChecks immunizationData={immunizationData} />
            <VaccineAdministrationDetails immunizationData={immunizationData} />
          </>
        )}
      </StackView>
    </SidepanelPage>
  )
}

export default SidepanelVisitVaccineAdministrationView
