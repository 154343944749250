import type { FeatureFlag, OrderCategory } from 'types/graphql'

import { BadgeColor } from 'src/components/atoms/Badge'

export type InternalOrderCategories = 'VAX' | 'SCN' | 'IHT' | 'SCH' | 'PRO'
export type ExternalOrderCategories = 'REF' | 'RAD' | 'DME' | 'LAB' | 'HG_LAB'

export const orderCategoryConfig: {
  [key in OrderCategory]: {
    category: OrderCategory
    display: string
    type: 'internal' | 'external'
    procedureLinked?: boolean
    color: BadgeColor
    canBeSignedByNonPractitioner: (data: {
      featureFlags: FeatureFlag[]
    }) => boolean
  }
} = {
  VAX: {
    display: 'Vaccine',
    type: 'internal',
    category: 'VAX',
    color: 'blue',
    canBeSignedByNonPractitioner: () => false,
  },
  SCN: {
    display: 'Screening',
    type: 'internal',
    category: 'SCN',
    procedureLinked: true,
    color: 'cyan',
    canBeSignedByNonPractitioner: () => false,
  },
  IHT: {
    display: 'In-house test',
    type: 'internal',
    category: 'IHT',
    procedureLinked: true,
    color: 'purple',
    canBeSignedByNonPractitioner: () => false,
  },
  SCH: {
    display: 'Scheduling',
    type: 'internal',
    category: 'SCH',
    color: 'teal',
    canBeSignedByNonPractitioner: () => true,
  },
  PRO: {
    display: 'Procedure',
    type: 'internal',
    category: 'PRO',
    procedureLinked: true,
    color: 'dark-gray',
    canBeSignedByNonPractitioner: () => false,
  },
  OTHER: {
    display: 'Other',
    type: 'internal',
    category: 'OTHER',
    color: 'light-gray',
    canBeSignedByNonPractitioner: () => false,
  },
  // PTB: {
  //   display: 'Patient bill',
  //   type: 'internal',
  //   category: 'PTB',
  // },
  REF: {
    display: 'Referral',
    type: 'external',
    category: 'REF',
    color: 'yellow',
    canBeSignedByNonPractitioner: ({ featureFlags }) =>
      featureFlags.includes('NON_PRACTITIONER_SIGN_REFERRAL'),
  },
  RAD: {
    display: 'Radiology',
    type: 'external',
    category: 'RAD',
    color: 'orange',
    canBeSignedByNonPractitioner: ({ featureFlags }) =>
      featureFlags.includes('NON_PRACTITIONER_SIGN_REFERRAL'),
  },
  DME: {
    display: 'DME',
    type: 'external',
    category: 'DME',
    color: 'violet',
    canBeSignedByNonPractitioner: ({ featureFlags }) =>
      featureFlags.includes('NON_PRACTITIONER_SIGN_REFERRAL'),
  },
  LAB: {
    display: 'External lab',
    type: 'external',
    category: 'LAB',
    color: 'red',
    canBeSignedByNonPractitioner: () => true,
  },
  HG_LAB: {
    display: 'E-lab',
    type: 'external',
    category: 'HG_LAB',
    color: 'rose',
    canBeSignedByNonPractitioner: () => false,
  },
}

export const internalOrderCategories = Object.keys(orderCategoryConfig).filter(
  (key) => orderCategoryConfig[key].type === 'internal'
) as OrderCategory[]

export const externalOrderCategories = Object.keys(orderCategoryConfig).filter(
  (key) => orderCategoryConfig[key].type === 'external'
) as OrderCategory[]
