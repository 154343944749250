import { useState, useRef, useEffect, useCallback } from 'react'

import { Combobox } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { isEqual } from 'lodash'

import { useFormContext } from '@redwoodjs/forms'
import { navigate, useLocation, useParams } from '@redwoodjs/router'

import Checkbox from 'src/components/atoms/Checkbox/Checkbox'
import Divider from 'src/components/atoms/Divider'
import { Option } from 'src/components/atoms/Select/Select'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'

const baseFilterClasses = [
  'relative',
  'inline-flex',
  'items-center',
  'text-sm',
  'text-gray-700',
  'font-medium',
  'bg-white',
  'hover:bg-gray-300',
  'border ',
  'border-gray-300',
  'focus:z-10',
  'focus:ring-0',
  'focus:outline-none',
  'focus-visible:ring-1',
  'focus-visible:ring-primary',
  'cursor-pointer',
]

interface MultiSelectDropdownFieldProps {
  options: Option[]
  emptyDisplayText: string
  multiSelectDisplayText?: string
  button?: React.FunctionComponent
  urlParamName?: string
  name: string
  icon: React.FunctionComponent<React.ComponentProps<'svg'>>
  defaultValue?: string[]
  open?: boolean
  onToggle?: (open: boolean) => void
  onToggleFilter?: (values: string[]) => void
  testId?: string
  disabled?: boolean
  showSelectAll?: boolean
  hideClearMultiSelect?: boolean
  onClearFilter?: () => void
  optionsClassName?: string
}

const MultiSelectDropdownField = ({
  options = [],
  multiSelectDisplayText = 'selected',
  emptyDisplayText,
  open: isOpen,
  icon: IconComponent,
  urlParamName,
  defaultValue = [],
  onToggle,
  onToggleFilter,
  name,
  testId,
  disabled = false,
  showSelectAll = false,
  hideClearMultiSelect = false,
  onClearFilter = () => {},
  optionsClassName,
}: MultiSelectDropdownFieldProps) => {
  const { watch, setValue, getValues } = useFormContext()
  const filterRef = useRef(null)
  const [open, setOpen] = useState(false)
  const params = useParams()
  const location = useLocation()
  const parameterValue = params[urlParamName]
  const optionIds = options.map(({ value }) => value)
  const selectedValues = watch(
    name,
    parameterValue
      ? parameterValue.split(',').filter((v) => v && optionIds.includes(v))
      : defaultValue
  )
  const allSelected = optionIds.every((option) =>
    selectedValues.includes(option)
  )
  const handleClickOutsideFilter = useCallback(
    (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setOpen(false)
        onToggle && onToggle(false)
      }
    },
    [onToggle]
  )
  useEffect(() => {
    if (urlParamName) {
      const newValues = selectedValues.filter((v) => optionIds.includes(v))
      if (!isEqual(newValues, selectedValues)) {
        return setValue(name, newValues)
      }
      const parameterValue = params[urlParamName]
      const newParams = new URLSearchParams({
        ...params,
        [urlParamName]: newValues.join(','),
      })
      if (newParams.get('glob')) {
        newParams.delete('glob')
      }
      const parameterValues = !parameterValue
        ? []
        : parameterValue.split(',').filter((v) => v)
      if (!isEqual(parameterValues, newValues)) {
        navigate(`${location.pathname}?${newParams.toString()}`)
      }
    }
  }, [
    selectedValues,
    urlParamName,
    params,
    location.pathname,
    defaultValue,
    optionIds,
    setValue,
    name,
  ])
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideFilter)
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideFilter)
    }
  }, [handleClickOutsideFilter])

  const clearFilter = () => {
    setValue(name, [])
    onClearFilter()
  }

  const toggleFilter = (optionValue: string) => {
    setValue(
      name,
      selectedValues.includes(optionValue)
        ? selectedValues.filter((value) => value != optionValue)
        : selectedValues.concat([optionValue])
    )
    onToggleFilter && onToggleFilter(getValues(name))
  }

  const renderDisplayText = () => {
    if (selectedValues.length === 0) {
      return emptyDisplayText
    }
    if (selectedValues.length === 1) {
      return options.find(({ value }) => selectedValues.includes(value))?.name
    }
    return `${selectedValues.length} ${multiSelectDisplayText}`
  }

  const toggleSelectAll = () => {
    if (allSelected) {
      setValue(name, [])
    } else {
      setValue(name, optionIds)
    }
  }

  return (
    <Combobox as="div" data-testid={testId}>
      <div className={clsx('relative')} ref={filterRef}>
        <span className="isolate inline-flex rounded-md shadow-sm">
          <button
            onClick={() => {
              setOpen(true)
              onToggle && onToggle(true)
            }}
            type="button"
            className={clsx(
              ...baseFilterClasses,
              'px-3',
              'py-1',
              'h-base-size-selectable-m',
              hideClearMultiSelect ? 'rounded-md' : 'rounded-l-md'
            )}
            disabled={disabled}
            data-testid="toggle-open-btn"
          >
            <IconComponent
              className="-ml-1 mr-2 h-5 w-5 text-gray-500"
              aria-hidden="true"
            />
            <Typography noWrap>{renderDisplayText()}</Typography>
          </button>
          {!hideClearMultiSelect && (
            <button
              data-testid="clear-multiselect-dropdown"
              type="button"
              onClick={clearFilter}
              className={clsx(
                ...baseFilterClasses,
                'px-2',
                'py-1',
                '-ml-px',
                'h-base-size-selectable-m',
                'rounded-r-md'
              )}
              disabled={disabled}
            >
              <XMarkIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </button>
          )}
        </span>
        {(isOpen || open) && (
          <Combobox.Options
            static
            className={clsx(
              'absolute',
              'bg-white',
              'right-0',
              'z-10',
              'py-1',
              'mt-2',
              'w-56',
              'overflow-auto',
              'rounded-md',
              'shadow-lg',
              'max-h-96',
              'overflow-auto',
              optionsClassName
            )}
          >
            {showSelectAll && (
              <>
                <StackView direction="row" className="px-3 py-1">
                  <Checkbox
                    name="selectAll"
                    disabled={disabled}
                    label="Select All"
                    checked={allSelected}
                    indeterminate={selectedValues.length > 0 && !allSelected}
                    onChange={() => toggleSelectAll()}
                  />
                </StackView>

                <Divider />
              </>
            )}
            {options.map(({ value, name: optionName, disabled }) => {
              return (
                <Combobox.Option
                  disabled={disabled}
                  key={value}
                  value={value}
                  onClick={() => !disabled && toggleFilter(value)}
                  className={({ active }) =>
                    clsx(
                      'relative',
                      'py-1',
                      'px-3',
                      'cursor-default',
                      disabled &&
                        'pb-base-space-selectable-inset-xs pt-base-space-selectable-inset-m',
                      active ? 'bg-gray-300 text-gray-700' : 'text-gray-700'
                    )
                  }
                >
                  <StackView direction="row">
                    {!disabled && (
                      <Checkbox
                        id={`cb-${value}`}
                        name={`cb-${value}`}
                        checked={selectedValues.includes(value)}
                        value={value}
                      />
                    )}
                    <Typography
                      className="-ml-1"
                      textStyle={
                        disabled ? 'interface-strong-xs' : 'interface-default-s'
                      }
                      color={
                        disabled
                          ? 'text-base-color-fg-subtle'
                          : 'text-base-color-fg-muted'
                      }
                    >
                      {optionName}
                    </Typography>
                  </StackView>
                </Combobox.Option>
              )
            })}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}

export default MultiSelectDropdownField
