import { useEffect, useMemo } from 'react'

import compact from 'lodash/compact'
import isEmpty from 'lodash/isEmpty'
import maxBy from 'lodash/maxBy'
import { useParams as useNestedParams } from 'react-router-dom'
import {
  AddVisitDiagnosis,
  CompleteInHouseTestOrder,
  CompleteScreeningOrder,
  CompleteVisionScreeningOrder,
  CompleteHearingScreeningOrder,
  CompleteQuestionnaireOrder,
  DeleteVisitDiagnosis,
  FindPatientVisit,
  FindPatientVisitFromEncounter,
  UpdateVisitClinicalReview,
  UpdateVisitDiagnosis,
  UpdateVisitIntakeVitals,
  UpdateAppointmentDocumentation,
  UpdateAppointmentInput,
  UpdateVisitPatientEducationDocuments,
  UpsertAppointmentCaregiverInput,
  DeleteAppointmentCaregiverInput,
  UpdateVisitFlowTabForAppointment,
  VisitFlowTab,
} from 'types/graphql'

import { navigate, routes, useParams } from '@redwoodjs/router'
import { useMutation, useQuery } from '@redwoodjs/web'

import { useEmrAuth } from 'src/auth'
import { VISIT_TEMPLATE_FRAGMENT } from 'src/pages/VisitTemplatePage/useVisitTemplates'
import {
  PATIENT_DETAILS_FRAGMENT,
  SIGNING_PRACTITIONER_FRAGMENT,
  TENANT_LETTERHEAD_FRAGMENT,
} from 'src/pdf/fragments'
import { openNewTab } from 'src/utils'

import { FAMILY_MEMBER_HISTORY_FRAGMENT } from '../PatientHistory/FamilyHistory/useFamilyHistory'

import {
  ENCOUNTER_BILLING_FRAGMENT,
  ENCOUNTER_DIAGNOSES_FRAGMENT,
  ENCOUNTER_ORDER_FRAGMENT,
  OBSERVATION_FOR_ORDER_FRAGMENT,
  PATIENT_ALLERGY_FRAGMENT,
  PATIENT_CONDITION_FRAGMENT,
  PATIENT_PRESCRIPTIONS_FRAGMENT,
  PATIENT_SELF_REPORTED_MEDICATIONS_FRAGMENT,
  PATIENT_SOCIAL_HISTORY_FRAGMENT,
  VISIT_SIGNATURES_FRAGMENT,
  VISIT_CLINICAL_REVIEW_FRAGMENT,
  VISIT_INTAKE_VITALS_FRAGMENT,
  VISIT_PATIENT_EDUCATION_DOCUMENTS_FRAGMENT,
  VISIT_AUDIO_RECORDINGS_FRAGMENT,
  MEDICATION_FRAGMENT,
} from './fragments'
import { VisitFlowRoute } from './VisitFlowLayout'

export const RELATED_PERSON_NAME_FRAGMENT = gql`
  fragment RelatedPersonNameFragment on RelatedPerson {
    familyName
    middleName
    givenName
    maidenName
    namePrefix
    nameSuffix
  }
`

export const PATIENT_RELATED_PERSON_RELATIONSHIP_FRAGMENT = gql`
  fragment PatientRelatedPersonRelationshipFragment on PatientRelatedPersonRelationship {
    id
    relationshipType
    relatedPerson {
      id
      ...RelatedPersonNameFragment
    }
  }
`
export const PATIENT_RELATED_PERSON_RELATIONSHIPS_FRAGMENT = gql`
  fragment PatientRelatedPersonRelationshipsFragment on Patient {
    patientRelatedPersonRelationships {
      ...PatientRelatedPersonRelationshipFragment
    }
  }
  ${RELATED_PERSON_NAME_FRAGMENT}
  ${PATIENT_RELATED_PERSON_RELATIONSHIP_FRAGMENT}
`

const FIND_PATIENT_VISIT_QUERY = gql`
  query FindPatientVisit(
    $id: String!
    $practitionerId: String!
    $shouldUseDoctorFirst: Boolean!
  ) {
    appointment(id: $id) {
      id
      start
      end
      status
      attestation
      historyPresentIllness
      developmentalSurveillance
      assessment
      planOfCare
      patientEducationAdditionalNotes
      patientEducationAdolescentConfidential
      documentationAdolescentConfidential
      reviewOfSystems
      physicalExam
      chiefComplaints
      chartingStatus
      mostRecentVistFlowTab
      appointmentDefinitions {
        id
        type
      }
      caregiversPresent {
        id
        createdAt
        relationshipType
        patientRelatedPersonRelationship {
          id
          relationshipType
          relatedPerson {
            id
            ...RelatedPersonNameFragment
          }
        }
      }
      patient {
        id
        insuranceOptedOutAt
        familyMemberHistories {
          ...FamilyMemberHistoryFragment
        }
        ...PatientDetailsFragment
        ...PatientAllergyFragment
        ...PatientConditionFragment
        ...PatientSocialHistoryFragment
        ...PatientPrescriptionsFragment @skip(if: $shouldUseDoctorFirst)
        ...PatientSelfReportedMedicationsFragment
          @skip(if: $shouldUseDoctorFirst)
        medications @include(if: $shouldUseDoctorFirst) {
          ...MedicationFragment
        }
        ...PatientRelatedPersonRelationshipsFragment
        pastMedicalHistory
      }
      practitioner {
        id
        namePrefix
        givenName
        middleName
        familyName
        nameSuffix
        practitionerClinicalDetail {
          id
          npi
        }
      }
      encounter {
        id
        ...EncounterDiagnosesFragment
        ...VisitIntakeVitalsFragment
        ...EncounterBillingFragment
        ...EncounterOrderFragment
        ...VisitAudioRecordingsFragment
      }
      location {
        id
        name
        rooms {
          id
          name
        }
        placeOfService
      }
      room {
        id
        name
      }
      tenant {
        ...TenantLetterheadFragment
      }
      visitTemplates {
        ...VisitTemplateFragment
      }
      ...VisitClinicalReviewFragment
      ...VisitPatientEducationDocumentsFragment
      ...VisitSignaturesFragment
    }
    practitioner(id: $practitionerId) {
      ...SigningPractitionerFragment
    }
  }
  ${ENCOUNTER_DIAGNOSES_FRAGMENT}
  ${VISIT_CLINICAL_REVIEW_FRAGMENT}
  ${VISIT_INTAKE_VITALS_FRAGMENT}
  ${ENCOUNTER_BILLING_FRAGMENT}
  ${TENANT_LETTERHEAD_FRAGMENT}
  ${PATIENT_DETAILS_FRAGMENT}
  ${PATIENT_ALLERGY_FRAGMENT}
  ${ENCOUNTER_ORDER_FRAGMENT}
  ${PATIENT_CONDITION_FRAGMENT}
  ${PATIENT_SOCIAL_HISTORY_FRAGMENT}
  ${PATIENT_PRESCRIPTIONS_FRAGMENT}
  ${PATIENT_SELF_REPORTED_MEDICATIONS_FRAGMENT}
  ${MEDICATION_FRAGMENT}
  ${VISIT_PATIENT_EDUCATION_DOCUMENTS_FRAGMENT}
  ${PATIENT_RELATED_PERSON_RELATIONSHIPS_FRAGMENT}
  ${FAMILY_MEMBER_HISTORY_FRAGMENT}
  ${VISIT_SIGNATURES_FRAGMENT}
  ${SIGNING_PRACTITIONER_FRAGMENT}
  ${VISIT_TEMPLATE_FRAGMENT}
  ${VISIT_AUDIO_RECORDINGS_FRAGMENT}
  ${SIGNING_PRACTITIONER_FRAGMENT}
`

const FIND_PATIENT_VITALS_FROM_ENCOUNTER_QUERY = gql`
  query FindPatientVisitFromEncounter($encounterId: String) {
    appointmentFromEncounter(encounterId: $encounterId) {
      id
      start
      appointmentDefinitions {
        id
        type
      }
      patient {
        id
        ...PatientDetailsFragment
      }
      encounter {
        id
        ...VisitIntakeVitalsFragment
      }
    }
  }
  ${PATIENT_DETAILS_FRAGMENT}
  ${VISIT_INTAKE_VITALS_FRAGMENT}
`
export const useVisitVitalsQuery = (
  encounterId: string
): {
  visit: FindPatientVisitFromEncounter['appointmentFromEncounter']
  visitType: FindPatientVisitFromEncounter['appointmentFromEncounter']['appointmentDefinitions'][0]['type']
  intakeVitalsLastUpdated: string
} => {
  if (!encounterId) {
    return {
      visit: undefined,
      visitType: undefined,
      intakeVitalsLastUpdated: undefined,
    }
  }

  const { data } = useQuery<FindPatientVisitFromEncounter>(
    FIND_PATIENT_VITALS_FROM_ENCOUNTER_QUERY,
    {
      variables: { encounterId },
    }
  )

  const visit = data?.appointmentFromEncounter

  const intakeVitalsLastUpdated = useMemo(() => {
    const latestIntakeVitals = compact([
      visit?.encounter?.bodyHeight,
      visit?.encounter?.bodyWeight,
      visit?.encounter?.headCircumference,
      visit?.encounter?.bodyTemperature,
      visit?.encounter?.bloodPressure,
      visit?.encounter?.heartRate,
      visit?.encounter?.respiratoryRate,
      visit?.encounter?.bmi,
    ])

    return latestIntakeVitals?.length > 0
      ? maxBy(latestIntakeVitals, (vital) => vital.updatedAt).updatedAt
      : null
  }, [visit])

  const visitType = useMemo(() => {
    if (isEmpty(visit) || isEmpty(visit.appointmentDefinitions)) return
    return visit.appointmentDefinitions[0].type
  }, [visit])

  return {
    visit,
    visitType,
    intakeVitalsLastUpdated,
  }
}

const UPDATE_APPOINTMENT_DOCUMENTATION_MUTATION = gql`
  mutation UpdateAppointmentDocumentation(
    $input: UpdateAppointmentDocumentationInput!
  ) {
    updateAppointmentDocumentation(input: $input) {
      id
      attestation
      assessment
      historyPresentIllness
      planOfCare
      developmentalSurveillance
      patientEducationAdditionalNotes
      patientEducationAdolescentConfidential
      documentationAdolescentConfidential
      reviewOfSystems
      physicalExam
    }
  }
`

const ADD_VISIT_DIAGNOSIS_MUTATION = gql`
  mutation AddVisitDiagnosis(
    $encounterId: String!
    $diagnosisCodeId: String!
    $isPrimary: Boolean!
  ) {
    addVisitDiagnosis(
      encounterId: $encounterId
      diagnosisCodeId: $diagnosisCodeId
      isPrimary: $isPrimary
    ) {
      id
      ...EncounterDiagnosesFragment
    }
  }
  ${ENCOUNTER_DIAGNOSES_FRAGMENT}
`

const UPDATE_VISIT_DIAGNOSIS_MUTATION = gql`
  mutation UpdateVisitDiagnosis(
    $encounterId: String!
    $conditionId: String!
    $diagnosisCodeId: String
    $isPrimary: Boolean
  ) {
    updateVisitDiagnosis(
      encounterId: $encounterId
      conditionId: $conditionId
      diagnosisCodeId: $diagnosisCodeId
      isPrimary: $isPrimary
    ) {
      id
      ...EncounterDiagnosesFragment
    }
  }
  ${ENCOUNTER_DIAGNOSES_FRAGMENT}
`

const DELETE_VISIT_DIAGNOSIS_MUTATION = gql`
  mutation DeleteVisitDiagnosis($conditionId: String!) {
    deleteVisitDiagnosis(conditionId: $conditionId) {
      id
      ...EncounterDiagnosesFragment
    }
  }
  ${ENCOUNTER_DIAGNOSES_FRAGMENT}
`

const UPDATE_VISIT_INTAKE_VITALS_MUTATION = gql`
  mutation UpdateVisitIntakeVitals(
    $encounterId: String!
    $input: UpsertIntakeVitalsSummaryInput!
  ) {
    updateVisitIntakeVitals(encounterId: $encounterId, input: $input) {
      id
      appointmentId
      patientReference
      ...VisitIntakeVitalsFragment
    }
  }
  ${VISIT_INTAKE_VITALS_FRAGMENT}
`

const ORDER_FOR_PATIENT_FRAGMENT = gql`
  fragment OrderForPatientFragment on Order {
    id
    externalId
    status
    intent
    name
    patientReference
    encounterReference
    encounterId
    requesterReference
    category
    code
    codeDisplay
    freeTextCode
    reason
    performer
    existingContactPerformer {
      contactId
      display
      organization
      address {
        line1
        line2
        city
        district
        state
        postalCode
      }
      emailAddress
      faxNumber
      workNumber
      display
    }
    doNotPerform
    resultInterpretation
    refusalReasons {
      refusalByPatient
      refusalByCaregiver
      uncooperativePatient
      contraindicatedForPatient
      underCareOfAnotherProvider
    }
    resultsForQuestionnaires {
      develoQuestionnaire
      questionnaireCode
      overall {
        isForTotal
        header
        score
        interpretation
        answerDisplay
      }
      detailed
      notes
      authorId
    }
    questionnaireResults {
      develoQuestionnaire
      questionnaireCode
      overall {
        isForTotal
        header
        score
        interpretation
        answerDisplay
      }
      detailed
      notes
      authorId
    }
  }
`

const COMPLETE_IN_HOUSE_TEST_ORDER_MUTATION = gql`
  mutation CompleteInHouseTestOrder(
    $encounterId: String
    $input: CompleteInHouseTestOrderInput!
  ) {
    completeInHouseTestOrder(input: $input) {
      id
      orders(encounterId: $encounterId) {
        ...OrderForPatientFragment
        observations {
          ...ObservationForOrderFragment
        }
      }
    }
  }
  ${ORDER_FOR_PATIENT_FRAGMENT}
  ${OBSERVATION_FOR_ORDER_FRAGMENT}
`

const COMPLETE_SCREENING_ORDER_MUTATION = gql`
  mutation CompleteScreeningOrder(
    $encounterId: String
    $input: CompleteScreeningOrderInput!
  ) {
    completeScreeningOrder(input: $input) {
      id
      orders(encounterId: $encounterId) {
        ...OrderForPatientFragment
        observations {
          ...ObservationForOrderFragment
        }
      }
    }
  }
  ${ORDER_FOR_PATIENT_FRAGMENT}
  ${OBSERVATION_FOR_ORDER_FRAGMENT}
`

const COMPLETE_VISION_SCREENING_ORDER_MUTATION = gql`
  mutation CompleteVisionScreeningOrder(
    $encounterId: String
    $input: CompleteVisionScreeningOrderInput!
  ) {
    completeVisionScreeningOrder(input: $input) {
      patient: id
      orders(encounterId: $encounterId) {
        ...OrderForPatientFragment
        observations {
          ...ObservationForOrderFragment
        }
      }
    }
  }
  ${ORDER_FOR_PATIENT_FRAGMENT}
  ${OBSERVATION_FOR_ORDER_FRAGMENT}
`

const COMPLETE_HEARING_SCREENING_ORDER_MUTATION = gql`
  mutation CompleteHearingScreeningOrder(
    $encounterId: String
    $input: CompleteHearingScreeningOrderInput!
  ) {
    completeHearingScreeningOrder(input: $input) {
      patient: id
      orders(encounterId: $encounterId) {
        ...OrderForPatientFragment
        observations {
          ...ObservationForOrderFragment
        }
      }
    }
  }
  ${ORDER_FOR_PATIENT_FRAGMENT}
  ${OBSERVATION_FOR_ORDER_FRAGMENT}
`

const COMPLETE_QUESTIONNAIRE_ORDER_MUTATION = gql`
  mutation CompleteQuestionnaireOrder(
    $encounterId: String
    $input: CompleteQuestionnaireOrderInput!
  ) {
    completeQuestionnaireOrder(input: $input) {
      patient: id
      orders(encounterId: $encounterId) {
        ...OrderForPatientFragment
        observations {
          ...ObservationForOrderFragment
        }
      }
    }
  }
  ${ORDER_FOR_PATIENT_FRAGMENT}
  ${OBSERVATION_FOR_ORDER_FRAGMENT}
`

const UPDATE_VISIT_CLINICAL_REVIEW_MUTATION = gql`
  mutation UpdateVisitClinicalReview($input: ClinicalReviewInput!) {
    updateVisitClinicalReview(input: $input) {
      id
      ...VisitClinicalReviewFragment
    }
  }
  ${VISIT_CLINICAL_REVIEW_FRAGMENT}
`

const UPDATE_VISIT_PATIENT_EDUCATION_DOCUMENTS_MUTATION = gql`
  mutation UpdateVisitPatientEducationDocuments(
    $visitId: String!
    $documentIds: [String!]!
  ) {
    updateVisitPatientEducationDocuments(
      visitId: $visitId
      documentIds: $documentIds
    ) {
      id
    }
  }
`

const UPDATE_VISIT_FLOW_TAB_FOR_APPOINTMENT_MUTATION = gql`
  mutation UpdateVisitFlowTabForAppointment(
    $appointmentId: String!
    $visitFlowTab: VisitFlowTab
  ) {
    updateVisitFlowTabForAppointment(
      appointmentId: $appointmentId
      visitFlowTab: $visitFlowTab
    ) {
      id
      userId
      appointmentId
      visitFlowTab
    }
  }
`

export const fieldsToIntakeVitalObservationNames: Record<string, string> = {
  height: 'Body Height',
  weight: 'Body Weight',
  headCircumference: 'Head Occipital-frontal circumference',
  bodyTemperature: 'Body temperature',
  bloodPressure: 'Blood Pressure',
  bloodPressureSystolic: 'Systolic blood pressure',
  bloodPressureDiastolic: 'Diastolic blood pressure',
  pulse: 'Heart rate',
  respiratoryRate: 'Respiratory rate',
}

export const useVisitQuery = (id: string) => {
  const { currentUser } = useEmrAuth()
  const shouldUseDoctorFirst =
    currentUser?.featureFlags.includes('DOCTOR_FIRST')
  const { data, ...rest } = useQuery<FindPatientVisit>(
    FIND_PATIENT_VISIT_QUERY,
    {
      variables: {
        id,
        practitionerId: currentUser.practitionerId,
        shouldUseDoctorFirst,
      },
      skip: !id,
    }
  )

  const visit = data?.appointment
  const currentPractitioner = data?.practitioner

  const intakeVitalsLastUpdated = useMemo(() => {
    const latestIntakeVitals = compact([
      visit?.encounter?.bodyHeight,
      visit?.encounter?.bodyWeight,
      visit?.encounter?.headCircumference,
      visit?.encounter?.bodyTemperature,
      visit?.encounter?.bloodPressure,
      visit?.encounter?.heartRate,
      visit?.encounter?.respiratoryRate,
      visit?.encounter?.bmi,
      visit?.encounter?.pulseOximetry,
    ])

    return latestIntakeVitals?.length > 0
      ? maxBy(latestIntakeVitals, (vital) => vital.updatedAt).updatedAt
      : null
  }, [visit])

  const visitType = useMemo(() => {
    if (isEmpty(visit) || isEmpty(visit.appointmentDefinitions)) return
    return visit.appointmentDefinitions[0].type
  }, [visit])

  return {
    visit,
    visitType,
    intakeVitalsLastUpdated,
    currentPractitioner,
    ...rest,
  }
}

export const useVisit = () => {
  const { id: patientId } = useParams()
  const { appointmentId } = useNestedParams()
  return {
    patientId,
    appointmentId,
    navigatePatientChart: ({
      route,
      shouldOpenNewTab,
    }: {
      route: string
      shouldOpenNewTab?: boolean
    }) =>
      shouldOpenNewTab
        ? openNewTab(routes.patientChartsGlob({ id: patientId, glob: route }))
        : navigate(routes.patientChartsGlob({ id: patientId, glob: route })),
    navigateVisitFlow: (route: VisitFlowRoute) =>
      navigate(
        routes.patientChartsGlob({
          id: patientId,
          glob: `visits/${appointmentId}/${route}`,
        })
      ),
  }
}

export const useUpdateVisitFlowTabForAppointment = (route: VisitFlowRoute) => {
  const { appointmentId } = useNestedParams()
  const [updateVisitFlowTab] = useUpdateVisitFlowTabForAppointmentMutation()

  useEffect(() => {
    updateVisitFlowTab({
      variables: {
        appointmentId,
        visitFlowTab: route.toUpperCase() as VisitFlowTab,
      },
    })
  }, [appointmentId, route, updateVisitFlowTab])
  return
}

export const useUpdateVisitFlowTabForAppointmentMutation = () => {
  return useMutation<UpdateVisitFlowTabForAppointment>(
    UPDATE_VISIT_FLOW_TAB_FOR_APPOINTMENT_MUTATION
  )
}

export const useAddVisitDiagnosisMutation = () => {
  return useMutation<AddVisitDiagnosis>(ADD_VISIT_DIAGNOSIS_MUTATION)
}

export const useUpdateVisitDiagnosisMutation = () => {
  return useMutation<UpdateVisitDiagnosis>(UPDATE_VISIT_DIAGNOSIS_MUTATION)
}

export const useDeleteConditionMutation = () => {
  return useMutation<DeleteVisitDiagnosis>(DELETE_VISIT_DIAGNOSIS_MUTATION)
}

export const useUpdateVisitIntakeVitalsMutation = () => {
  return useMutation<UpdateVisitIntakeVitals>(
    UPDATE_VISIT_INTAKE_VITALS_MUTATION,
    {
      // refetches vitals in patient header
      refetchQueries: ['GetPatient'],
    }
  )
}

export const useUpdateVisitClinicalReviewMutation = () => {
  return useMutation<UpdateVisitClinicalReview>(
    UPDATE_VISIT_CLINICAL_REVIEW_MUTATION
  )
}

export const useUpdateAppointmentDocumentationMutation = () => {
  return useMutation<UpdateAppointmentDocumentation>(
    UPDATE_APPOINTMENT_DOCUMENTATION_MUTATION
  )
}

export const useCompleteInHouseTestOrderMutation = () => {
  return useMutation<CompleteInHouseTestOrder>(
    COMPLETE_IN_HOUSE_TEST_ORDER_MUTATION
  )
}

export const useCompleteScreeningOrderMutation = () => {
  return useMutation<CompleteScreeningOrder>(COMPLETE_SCREENING_ORDER_MUTATION)
}

export const useCompleteVisionScreeningOrderMutation = () => {
  return useMutation<CompleteVisionScreeningOrder>(
    COMPLETE_VISION_SCREENING_ORDER_MUTATION
  )
}

export const useCompleteHearingScreeningOrderMutation = () => {
  return useMutation<CompleteHearingScreeningOrder>(
    COMPLETE_HEARING_SCREENING_ORDER_MUTATION
  )
}

export const useCompleteQuestionnaireOrderMutation = () => {
  return useMutation<CompleteQuestionnaireOrder>(
    COMPLETE_QUESTIONNAIRE_ORDER_MUTATION
  )
}

export const useUpdateVisitChiefComplaintsMutation = () => {
  return useMutation<UpdateAppointmentInput>(
    UPDATE_VISIT_CHIEF_COMPLAINTS_MUTATION
  )
}

export const useUpsertVisitCaregiverPresentMutation = () => {
  return useMutation<UpsertAppointmentCaregiverInput>(
    UPSERT_VISIT_CAREGIVER_PRESENT_MUTATION
  )
}

export const useDeleteVisitCaregiverPresentMutation = () => {
  return useMutation<DeleteAppointmentCaregiverInput>(
    DELETE_VISIT_CAREGIVER_PRESENT_MUTATION
  )
}

const UPDATE_VISIT_CHIEF_COMPLAINTS_MUTATION = gql`
  mutation UpdateVisitChiefComplaintMutation(
    $id: String!
    $input: UpdateAppointmentInput!
  ) {
    updateAppointment(id: $id, input: $input) {
      id
      chiefComplaints
    }
  }
`

export const useUpdateVisitPatientEducationDocumentsMutation = () => {
  return useMutation<UpdateVisitPatientEducationDocuments>(
    UPDATE_VISIT_PATIENT_EDUCATION_DOCUMENTS_MUTATION
  )
}

const UPSERT_VISIT_CAREGIVER_PRESENT_MUTATION = gql`
  mutation UpdateVisitCaregiverPresentMutation(
    $input: UpsertAppointmentCaregiverInput!
  ) {
    upsertAppointmentCaregiver(input: $input) {
      id
      caregiversPresent {
        id
        createdAt
        relationshipType
        patientRelatedPersonRelationship {
          id
          relationshipType
          relatedPerson {
            id
            ...RelatedPersonNameFragment
          }
        }
      }
    }
  }
  ${RELATED_PERSON_NAME_FRAGMENT}
`

const DELETE_VISIT_CAREGIVER_PRESENT_MUTATION = gql`
  mutation DeleteVisitCaregiversPresentMutation(
    $input: DeleteAppointmentCaregiverInput!
  ) {
    deleteAppointmentCaregiver(input: $input) {
      id
      caregiversPresent {
        id
        relationshipType
        patientRelatedPersonRelationship {
          id
          relationshipType
          relatedPerson {
            id
            ...RelatedPersonNameFragment
          }
        }
      }
    }
  }
  ${RELATED_PERSON_NAME_FRAGMENT}
`

const APPLY_VISIT_TEMPLATE_MUTATION = gql`
  mutation ApplyVisitTemplateMutation(
    $visitId: String!
    $visitTemplateId: String!
  ) {
    applyVisitTemplate(visitId: $visitId, visitTemplateId: $visitTemplateId) {
      id
      attestation
      historyPresentIllness
      developmentalSurveillance
      assessment
      planOfCare
      patientEducationAdditionalNotes
      patientEducationAdolescentConfidential
      documentationAdolescentConfidential
      encounter {
        id
      }
      visitTemplates {
        ...VisitTemplateFragment
      }
      ...VisitPatientEducationDocumentsFragment
    }
  }
  ${VISIT_TEMPLATE_FRAGMENT}
  ${VISIT_PATIENT_EDUCATION_DOCUMENTS_FRAGMENT}
`

export const useApplyVisitTemplateMutation = () => {
  return useMutation(APPLY_VISIT_TEMPLATE_MUTATION, {
    onCompleted: () => {
      window.location.reload()
    },
  })
}

const CLEAR_VISIT_TEMPLATES_MUTATION = gql`
  mutation ClearVisitTemplatesMutation($visitId: String!) {
    clearVisitTemplates(visitId: $visitId) {
      id
      attestation
      historyPresentIllness
      developmentalSurveillance
      assessment
      planOfCare
      patientEducationAdditionalNotes
      patientEducationAdolescentConfidential
      documentationAdolescentConfidential
      encounter {
        id
      }
      visitTemplates {
        ...VisitTemplateFragment
      }
      ...VisitPatientEducationDocumentsFragment
    }
  }
  ${VISIT_TEMPLATE_FRAGMENT}
  ${VISIT_PATIENT_EDUCATION_DOCUMENTS_FRAGMENT}
`

export const useClearVisitTemplatesMutation = () => {
  return useMutation(CLEAR_VISIT_TEMPLATES_MUTATION, {
    onCompleted: () => {
      window.location.reload()
    },
  })
}
