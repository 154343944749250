import { useRef } from 'react'

import { NoSymbolIcon } from '@heroicons/react/24/outline'
import { AgGridReact } from 'ag-grid-react'
import { formatDisplayName } from 'common/utils'
import type {
  AppointmentAuditRecordsQuery,
  AppointmentAuditRecordAction,
} from 'types/graphql'

import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import Space from 'src/components/atoms/Space/Space'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import Table from 'src/components/molecules/Table'
import { appointmentAuditRecordActionDisplay } from 'src/data/appointmentAuditRecordActions'

export const QUERY = () => gql`
  query AppointmentAuditRecordsQuery($appointmentId: String!) {
    appointment: appointment(id: $appointmentId) {
      id
      auditRecords {
        id
        createdAt
        practitioner {
          id
          givenName
          familyName
        }
        action
      }
    }
  }
`

export const Loading = () => <div>Loading...</div>

export const Empty = () => (
  <StackView justifyContent="center" alignItems="center" className="h-56">
    <NoSymbolIcon className="h-8 w-8 text-gray-400" />
    <Space space={50} />
    <Typography textStyle="subtitle">No records</Typography>
    <Typography color="text-base-color-fg-muted" className="text-center">
      As this appointment is modified, records will display here.
    </Typography>
  </StackView>
)

export const isEmpty = ({
  appointment,
}: CellSuccessProps<AppointmentAuditRecordsQuery>) =>
  !appointment.auditRecords.length

export const Failure = ({ error }: CellFailureProps) => (
  <div className="text-danger">Error: {error?.message}</div>
)

const formatUserDisplay = (
  value:
    | 'Patient or caregiver'
    | Partial<{
        namePrefix: string
        givenName: string
        middleName: string
        familyName: string
        nameSuffix: string
        credentials: string
      }>
): string => {
  if (value === 'Patient or caregiver') return value
  return formatDisplayName(value)
}

const getUserValue = (params: {
  data: {
    action: AppointmentAuditRecordAction
    practitioner: string
    createdAt: string
  }
}): string => {
  if (params.data.action === 'CANCELLED' && !params.data.practitioner)
    return 'Patient or caregiver'
  return params.data.practitioner
}

export const Success = ({
  appointment,
}: CellSuccessProps<AppointmentAuditRecordsQuery>) => {
  const { auditRecords } = appointment
  const gridRef = useRef<AgGridReact>()

  return (
    <StackView space={125}>
      <Table
        testId="appointment-audit-records-table"
        innerRef={gridRef}
        rowData={auditRecords}
        defaultColDef={{
          cellStyle: {
            border: 'none',
            display: 'flex',
            alignItems: 'center',
          },
          resizable: true,
          sortable: true,
        }}
        columnDefs={[
          {
            headerName: 'Action',
            field: 'action',
            wrapText: true,
            valueFormatter: ({ value }) =>
              appointmentAuditRecordActionDisplay[value],
          },
          {
            headerName: 'User',
            field: 'practitioner',
            valueFormatter: ({ value }) => formatUserDisplay(value),
            valueGetter: (params) => getUserValue(params),
          },
          {
            headerName: 'Timestamp',
            field: 'createdAt',
            valueFormatter: ({ value }) => new Date(value).toLocaleString(),
          },
        ]}
        domLayout="autoHeight"
        rowHeight={72}
      />
    </StackView>
  )
}
