import { formatDisplayName } from 'common/utils'
import {
  CreateDocumentCaseInput,
  CreateFamilyMessageCaseFromEmr,
  CreateFamilyMessageCaseFromEmrVariables,
  FamilyMessageCaseSubject,
  GetPractitionersAndUserPools,
} from 'types/graphql'

import { FieldError, Form, useForm } from '@redwoodjs/forms'
import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'

import Box from 'src/components/atoms/Box/Box'
import Button, { Submit } from 'src/components/atoms/Button/Button'
import { ComboboxField } from 'src/components/atoms/ComboboxField'
import Divider from 'src/components/atoms/Divider/Divider'
import { DropdownField } from 'src/components/atoms/Dropdown/Dropdown'
import FileInput from 'src/components/atoms/FileInput/FileInput'
import StackView from 'src/components/atoms/StackView/StackView'
import TextAreaField from 'src/components/atoms/TextAreaField/TextAreaField'
import Typography from 'src/components/atoms/Typography/Typography'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import EnhancedPatientSearch from 'src/components/Patient/EnhancedPatientSearch/EnhancedPatientSearch'
import { useFeatureFlagIsEnabled } from 'src/hooks/useFeatureFlagIsEnabled'
import { useSidepanel } from 'src/providers/context/SidepanelContext'
import { base64EncodeFile } from 'src/utils'
import { serializeTaskAssignee } from 'src/utils/task'

import { FamilyMessageCaseFormFields } from './FamilyMessageCaseFormFields'

const QUERY = gql`
  query GetPractitionersAndUserPoolsQuery {
    taskUserPools {
      id
      displayName
    }
    users {
      id
      givenName
      familyName
    }
  }
`

const CREATE_DOCUMENT_CASE_MUTATION = gql`
  mutation CreateDocumentCase($input: CreateDocumentCaseInput!) {
    createDocumentCase(input: $input) {
      id
      baseCase {
        id
        createdBy {
          id
          familyName
          givenName
        }
        createdAt
      }
    }
  }
`

const CREATE_FAMILY_MESSAGE_CASE_MUTATION = gql`
  mutation CreateFamilyMessageCaseFromEmr(
    $input: CreateFamilyMessageCaseFromEmrInput!
  ) {
    createFamilyMessageCaseFromEmr(input: $input) {
      id
      baseCase {
        id
        createdBy {
          id
          familyName
          givenName
        }
        createdAt
      }
    }
  }
`

const CREATE_STAFF_MESSAGE_CASE_MUTATION = gql`
  mutation CreateStaffMessageCase($input: CreateStaffMessageCaseInput!) {
    createStaffMessageCase(input: $input) {
      id
      baseCase {
        createdBy {
          id
          familyName
          givenName
        }
        createdAt
      }
    }
  }
`

type FormProps =
  | {
      caseType: 'family-message'
      userOrUserPoolId: string
      patientId: string
      subject: FamilyMessageCaseSubject
      message: string
      assignToPortalUserId: string
      documentReferenceId?: string
    }
  | {
      caseType: 'document' | 'staff-message'
      userOrUserPoolId: string
      documentBinaries: FileList
      caseNotes?: string
      patientId?: string
    }

const SidepanelCreateTask = () => {
  const canCreateFamilyMessageCase = useFeatureFlagIsEnabled('PATIENT_PORTAL')
  const { data } = useQuery<GetPractitionersAndUserPools>(QUERY, {
    fetchPolicy: 'cache-first',
  })
  const [createDocumentCase, { loading: creatingDocumentCase }] = useMutation(
    CREATE_DOCUMENT_CASE_MUTATION
  )
  const [createStaffMessageCase, { loading: creatingStaffMessageCase }] =
    useMutation(CREATE_STAFF_MESSAGE_CASE_MUTATION)
  const [createFamilyMessageCase, { loading: creatingFamilyMessageCase }] =
    useMutation<
      CreateFamilyMessageCaseFromEmr,
      CreateFamilyMessageCaseFromEmrVariables
    >(CREATE_FAMILY_MESSAGE_CASE_MUTATION)
  const { closeSidePanel, sidepanelContext } = useSidepanel()
  const formMethods = useForm<FormProps>()
  const isDocumentCase = formMethods.watch('caseType') === 'document'
  const isStaffMessage = formMethods.watch('caseType') === 'staff-message'
  const isFamilyMessage = formMethods.watch('caseType') === 'family-message'
  const documentBinaries = formMethods.watch('documentBinaries')

  const defaultPatientId =
    typeof sidepanelContext?.patientId === 'string'
      ? sidepanelContext.patientId
      : undefined

  const onSubmit = async (formData: FormProps) => {
    const [idType, id] = formData.userOrUserPoolId.split(':')
    const assignedTaskUserPoolId = idType === 'pool' ? id : null
    const assignedUserId = idType === 'user' ? id : null

    if (formData.caseType === 'document') {
      const binaries = formData.documentBinaries
      let index = 0
      for (const binary of binaries) {
        const binaryInput = {
          content: await base64EncodeFile(binary),
          contentType: binary.type,
        }
        const input: CreateDocumentCaseInput = {
          binaryInput,
          caseNotes: formData.caseNotes,
          assignedTaskUserPoolId,
          assignedUserId,
          patientId: formData.patientId || null,
        }
        index++
        await createDocumentCase({
          variables: { input },
          onCompleted: () => {
            toast(`${binary.name} uploaded`, {
              position: 'top-right',
              duration: 2000,
            })
            if (index === binaries.length) {
              closeSidePanel()
            }
          },
          refetchQueries:
            index === binaries.length
              ? [
                  'FindDocumentCases',
                  'GetNotResolvedCasesCountByType',
                  'GetTaskNotificationCount',
                ]
              : ['GetTaskNotificationCount'],
        })
      }
    }

    if (formData.caseType === 'staff-message') {
      void createStaffMessageCase({
        variables: {
          input: {
            assignedTaskUserPoolId,
            assignedUserId,
            caseNotes: formData.caseNotes,
            patientId: formData.patientId || null,
          },
        },
        refetchQueries: [
          'FindStaffMessageCases',
          'GetNotResolvedCasesCountByType',
          'GetTaskNotificationCount',
        ],
        onCompleted: () => {
          closeSidePanel()
        },
      })
    }

    if (formData.caseType === 'family-message') {
      void createFamilyMessageCase({
        variables: {
          input: {
            patientId: formData.patientId,
            subject: formData.subject,
            message: formData.message,
            assignToPortalUser:
              formData.assignToPortalUserId === 'patient'
                ? { patient: true }
                : { relatedPersonId: formData.assignToPortalUserId },
            documentReferenceId: formData.documentReferenceId,
          },
        },
        refetchQueries: [
          'FindFamilyMessageCases',
          'GetNotResolvedCasesCountByType',
          'GetTaskNotificationCount',
        ],
        onCompleted: () => {
          closeSidePanel()
        },
      })
    }
  }

  return (
    <StackView className="h-full">
      <Box className="h-full max-w-3xl" grow>
        <Form
          autoComplete="off"
          formMethods={formMethods}
          onSubmit={onSubmit}
          className="h-full"
        >
          <StackView className="h-full">
            <Box verticalPadding={75} horizontalPadding={100}>
              <StackView space={25}>
                <Typography textStyle="heading">Create task</Typography>
                <Typography color="text-base-color-fg-muted">
                  Manually create a new task.
                </Typography>
              </StackView>
            </Box>
            <Divider />
            <Box
              horizontalPadding={100}
              verticalPadding={100}
              className="h-full"
            >
              <FormInputList
                title="Task details"
                items={[
                  {
                    name: 'caseType',
                    label: 'Task type',
                    required: true,
                    formInputComponent: DropdownField,
                    inputProps: {
                      options: [
                        {
                          name: 'Document',
                          value: 'document',
                        },
                        {
                          name: 'Staff message',
                          value: 'staff-message',
                        },
                        canCreateFamilyMessageCase
                          ? {
                              name: 'Family message',
                              value: 'family-message',
                            }
                          : null,
                      ].filter(Boolean),
                    },
                  },
                  {
                    name: 'userOrUserPoolId',
                    label: 'User or user pool',
                    required: true,
                    formInputComponent: ComboboxField,
                    inputProps: {
                      options: [
                        { heading: true, name: 'User pools', value: 'pool' },
                        ...(data?.taskUserPools || []).map((pool) => ({
                          name: pool.displayName,
                          value: serializeTaskAssignee({
                            type: 'pool',
                            id: pool.id,
                          }),
                        })),
                        { heading: true, name: 'Users', value: 'user' },
                        ...(data?.users || []).map((user) => ({
                          name: formatDisplayName(user),
                          value: serializeTaskAssignee({
                            type: 'user',
                            id: user.id,
                          }),
                        })),
                      ],
                    },
                  },
                ]}
              />
              {isDocumentCase && (
                <>
                  <Divider />
                  <FormInputList
                    title="Document details"
                    items={[
                      {
                        name: 'documentBinaries',
                        label: 'Document upload',
                        required: true,
                        formInputComponent: FileInput,
                        inputProps: {
                          draggable: true,
                          multiple: true,
                        },
                      },
                      {
                        name: 'patient',
                        formInputComponent: EnhancedPatientSearch,
                        label: 'Patient',
                        required: false,
                        inputProps: {
                          placeholder: '',
                          defaultPatientId,
                        },
                      },
                      {
                        label: 'Task notes',
                        name: 'caseNotes',
                        hide: documentBinaries?.length > 1,
                        formInputComponent: TextAreaField,
                      },
                    ]}
                  />
                </>
              )}
              {isStaffMessage && (
                <>
                  <Divider />
                  <FormInputList
                    items={[
                      {
                        name: 'patient',
                        formInputComponent: EnhancedPatientSearch,
                        label: 'Patient',
                        required: false,
                        inputProps: {
                          placeholder: '',
                          defaultPatientId,
                        },
                      },
                      {
                        name: 'caseNotes',
                        label: 'Task notes',
                        required: false,
                        formInputComponent: TextAreaField,
                        alignItems: 'start',
                        inputProps: {
                          rows: 6,
                        },
                      },
                    ]}
                  />
                </>
              )}
              {isFamilyMessage && (
                <>
                  <Divider />
                  <FamilyMessageCaseFormFields
                    defaultPatientId={defaultPatientId}
                  />
                </>
              )}
            </Box>
          </StackView>
          <Box
            inverse
            verticalPadding={100}
            horizontalPadding={125}
            className="sticky bottom-0 border-l-0"
          >
            <StackView direction="row" justifyContent="end" space={75}>
              <Button
                text="Cancel"
                buttonStyle="secondary"
                onClick={() => closeSidePanel()}
              />
              <Submit
                disabled={
                  creatingStaffMessageCase ||
                  creatingDocumentCase ||
                  creatingFamilyMessageCase
                }
                text="Create task"
                data-testid="sidepanel-create-case-btn"
                buttonStyle="primary"
                loading={
                  creatingStaffMessageCase ||
                  creatingDocumentCase ||
                  creatingFamilyMessageCase
                }
              />
            </StackView>
            <StackView alignItems="end">
              <FieldError name="date" className="pt-2" />
            </StackView>
          </Box>
        </Form>
      </Box>
    </StackView>
  )
}

export default SidepanelCreateTask
