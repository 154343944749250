import {
  ArrowPathIcon,
  BellAlertIcon,
  CalendarIcon,
  CheckCircleIcon,
  UserCircleIcon,
  BellIcon,
  DocumentIcon,
  ArrowsPointingOutIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { isOverdue, alertText, isUpcoming } from 'common/data/baseCases'
import { formatDisplayName } from 'common/utils'
import { add, format, parseISO } from 'date-fns'
import {
  DocumentCaseResolutionStatus,
  Patient,
  PatientRegistrationIntent,
  SchedulingCase,
  SchedulingCaseResolutionStatus,
  ScreeningCase,
  TaskUserPool,
  User,
} from 'types/graphql'

import { navigate, routes, useLocation, useParams } from '@redwoodjs/router'

import Badge from 'src/components/atoms/Badge/Badge'
import Box from 'src/components/atoms/Box/Box'
import Button from 'src/components/atoms/Button/Button'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { Chip, NavChip } from 'src/components/Chip/Chip'
import MultiUserBadge from 'src/components/molecules/MultiUserBadge/MultiUserBadge'
import { documentCaseStatusDisplay } from 'src/data/documentCaseStatus'
import { schedulingCaseStatusDisplay } from 'src/data/schedulingCaseStatus'
import { useUpdateFilter } from 'src/hooks/useScheduleFilter/useScheduleFilter'
import { formatInitials } from 'src/lib/formatters'
import { sidepanelRoute } from 'src/lib/routes'
import { codeGeneration } from 'src/pages/CasesPage/utils/codeGeneration'
import { useAppointmentManager } from 'src/providers/context/AppointmentsManagementContext'
import { useFullScreenContext } from 'src/providers/context/FullScreenContext'
import { selectNextTask, selectPreviousTask } from 'src/utils/task'

import RouteToDropdown from './RouteToDropdown/RouteToDropdown'

interface CaseHeaderProps {
  id: string
  baseCase: {
    id: string
    createdAt: string
    updatedAt: string
    assignedUser?: Partial<User>
    assignedTaskUserPool?: Partial<TaskUserPool>
    dueAt: string
  }
  resolvedAt: string
  caseData?: {
    schedulingCase: SchedulingCase
    screeningCase: ScreeningCase
  }
  code: number
  patient?: Patient
  patientRegistrationIntent?: PatientRegistrationIntent
  resolvedStatus: SchedulingCaseResolutionStatus | DocumentCaseResolutionStatus
  caseType:
    | 'DocumentCase'
    | 'SchedulingCase'
    | 'StaffMessageCase'
    | 'ResultsReviewCase'
    | 'DiagnosticReportReviewCase'
    | 'ScreeningCase'
    | 'FamilyMessageCase'
}

const CaseHeader = ({
  caseData,
  id,
  baseCase,
  caseType,
  code,
  resolvedAt,
  resolvedStatus,
  patient,
  patientRegistrationIntent,
}: CaseHeaderProps) => {
  const {
    id: baseCaseId,
    updatedAt,
    assignedUser,
    assignedTaskUserPool,
  } = baseCase

  const { isFullScreen, setFullScreen } = useFullScreenContext()
  const { setNewAppointmentFormValue } = useAppointmentManager()
  const updateFilter = useUpdateFilter()
  const location = useLocation()
  const params = useParams()
  const { glob } = params
  const caseId = glob.split('/')[1]
  const renderBadgeByType = () => {
    switch (caseType) {
      case 'SchedulingCase':
        return <Badge color="blue" text="Scheduling" />
      case 'DocumentCase':
        return <Badge color="yellow" text="Document" />
      case 'StaffMessageCase':
        return <Badge color="rose" text="Staff message" />
      case 'FamilyMessageCase':
        return <Badge color="rose" text="Family message" />
      case 'DiagnosticReportReviewCase':
      case 'ResultsReviewCase':
        return <Badge color="dark-gray" text="Results review" />
      case 'ScreeningCase':
        return <Badge color="dark-gray" text="Screening" />
      default:
        return null
    }
  }
  const renderResolveStatusBadge = () => {
    if (caseType === 'DocumentCase') {
      return (
        <Badge
          text={`${documentCaseStatusDisplay[resolvedStatus]}`}
          color={resolvedStatus === 'CANCELLED' ? 'red' : 'green'}
        />
      )
    }
    return (
      <Badge
        text={`${schedulingCaseStatusDisplay[resolvedStatus]}`}
        color={resolvedStatus === 'CANCELLED' ? 'red' : 'green'}
      />
    )
  }
  const selectedTaskInboxItem: HTMLElement = document.getElementById(
    `case-inbox-item-${id}`
  )
  const isFirst = selectedTaskInboxItem?.dataset?.first === 'true'
  const isLast = selectedTaskInboxItem?.dataset?.last === 'true'
  const onResolveCase = () => {
    if (caseType === 'SchedulingCase') {
      updateFilter(
        'scheduleLocationFilter',
        [caseData.schedulingCase.location.id],
        { saveSettings: true }
      )
      updateFilter(
        'schedulePractitionerFilter',
        [caseData.schedulingCase.orderPractitioner.id],
        { saveSettings: true }
      )
      const newDate = add(
        parseISO(caseData.schedulingCase.baseCase.createdAt),
        {
          [`${caseData.schedulingCase.targetTimeUnit.toLocaleLowerCase()}s`]:
            caseData.schedulingCase.targetTime,
        }
      )
      setNewAppointmentFormValue('date', newDate)
      updateFilter('scheduleDateFilter', format(newDate, 'yyyy-MM-dd'), {
        saveSettings: false,
      })
      setNewAppointmentFormValue(
        'location',
        caseData.schedulingCase.location.id
      )
      setNewAppointmentFormValue(
        'practitioner',
        caseData.schedulingCase.orderPractitioner.id
      )
      setNewAppointmentFormValue('reason.visitType', undefined)
      setNewAppointmentFormValue(
        'reason.visitType',
        caseData.schedulingCase.appointmentDefinitions.map(({ id }) => id)
      )
      setNewAppointmentFormValue(
        'reason.chiefComplaints',
        caseData.schedulingCase.chiefComplaints
      )
      setNewAppointmentFormValue(
        'reason.visitComment',
        caseData.schedulingCase.priorAppointment?.visitComment ??
          caseData.schedulingCase.baseCase.lastCaseNote?.text ??
          ''
      )
      navigate(
        sidepanelRoute(
          {
            route: `/scheduling-case/${caseId}/resolve`,
          },
          location,
          params
        )
      )
    }
    if (caseType === 'DocumentCase') {
      navigate(
        sidepanelRoute(
          {
            route: `/document-case/${caseId}/resolve`,
          },
          location,
          params
        )
      )
    }
    if (caseType === 'StaffMessageCase') {
      navigate(
        sidepanelRoute(
          {
            route: `/staff-message-case/${caseId}/resolve`,
          },
          location,
          params
        )
      )
    }
    if (caseType === 'FamilyMessageCase') {
      navigate(
        sidepanelRoute(
          {
            route: `/family-message-case/${caseId}/resolve`,
          },
          location,
          params
        )
      )
    }
    if (
      caseType === 'ResultsReviewCase' ||
      caseType === 'DiagnosticReportReviewCase'
    ) {
      navigate(
        sidepanelRoute(
          {
            route: `/results-review-case/${caseId}/resolve/${patient.id}`,
          },
          location,
          params
        )
      )
    }
    if (caseType === 'DiagnosticReportReviewCase') {
      navigate(
        sidepanelRoute(
          {
            route: `/diagnostic-results-review-case/${caseId}/resolve`,
          },
          location,
          params
        )
      )
    }
    if (caseType === 'ScreeningCase') {
      if (patient) {
        navigate(
          sidepanelRoute(
            {
              route: `/screening-case/${caseId}/resolve/${patient.id}`,
            },
            location,
            params
          )
        )
      } else if (patientRegistrationIntent) {
        navigate(
          sidepanelRoute(
            {
              route: `/screening-case/${caseId}/resolve/${patientRegistrationIntent?.id}`,
            },
            location,
            params
          )
        )
      }
    }
  }
  const onStatusUpdate = () => {
    let route
    if (
      caseType === 'ResultsReviewCase' ||
      caseType === 'DiagnosticReportReviewCase'
    ) {
      route = sidepanelRoute(
        {
          route: `/results-review-case/status-update/${patient.id}`,
        },
        location,
        params
      )
    }
    if (caseType === 'DiagnosticReportReviewCase') {
      route = sidepanelRoute(
        {
          route: `/diagnostic-results-review-case/${caseId}/status-update`,
        },
        location,
        params
      )
    }
    if (caseType === 'SchedulingCase') {
      route = sidepanelRoute(
        {
          route: patient
            ? `/scheduling-case/status-update/patient/${patient.id}`
            : `/scheduling-case/status-update/patientRegistrationIntent/${patientRegistrationIntent.id}`,
        },
        location,
        params
      )
    }

    if (caseType === 'DocumentCase') {
      route = sidepanelRoute(
        {
          route: `/document-case/${caseId}/status-update`,
        },
        location,
        params
      )
    }

    navigate(route)
  }
  const FullScreenIcon = isFullScreen ? XMarkIcon : ArrowsPointingOutIcon
  const hasNext = !isLast
  const hasPrev = !isFirst

  return (
    <StackView>
      <Box
        verticalPadding={50}
        horizontalPadding={100}
        id="full-screen-controls"
        className="border-b border-b-gray-100"
      >
        <StackView direction="row" alignItems="center">
          <Box className="border-r pr-3">
            <FullScreenIcon
              data-testid="full-screen-btn"
              className="h-6 w-6 cursor-pointer text-gray-400"
              onClick={() => setFullScreen(!isFullScreen)}
            />
          </Box>
          <StackView direction="row" className="pl-3">
            <ChevronUpIcon
              data-testid="previous-case-item"
              onClick={() => {
                if (hasPrev) {
                  selectPreviousTask({
                    caseId: selectedTaskInboxItem.dataset.caseId,
                  })
                }
              }}
              className={clsx(
                'h-6 w-6',
                isFirst || !hasPrev
                  ? 'text-gray-300'
                  : 'cursor-pointer text-gray-400'
              )}
            />
            <ChevronDownIcon
              data-testid="next-case-item"
              onClick={() => {
                if (hasNext) {
                  selectNextTask({
                    caseId: selectedTaskInboxItem.dataset.caseId,
                  })
                }
              }}
              className={clsx(
                'h-6 w-6',
                isLast || !hasNext
                  ? 'text-gray-300'
                  : 'cursor-pointer text-gray-400'
              )}
            />
          </StackView>
        </StackView>
      </Box>
      <Box verticalPadding={0} horizontalPadding={100} id="case-details-header">
        <StackView space={75} className="pt-2">
          <StackView direction="row" justifyContent="between">
            <StackView space={75} direction="row" alignItems="center" wrap>
              <Typography
                textStyle="heading"
                id="case-details-header-code"
                data-code={code}
                noWrap
              >
                {codeGeneration({ code, __typename: caseType })}
              </Typography>
              {renderBadgeByType()}
              <MultiUserBadge
                totalUsers={
                  assignedUser ? 1 : assignedTaskUserPool.users.length
                }
                usersNameInitials={
                  assignedUser
                    ? `${assignedUser.givenName.charAt(
                        0
                      )}${assignedUser.familyName.charAt(0)}`
                    : assignedTaskUserPool.users
                        .map((user) => formatInitials(user))
                        .slice(0, 4)
                        .join(',')
                }
                badgeTitle={
                  assignedUser
                    ? formatDisplayName(assignedUser)
                    : assignedTaskUserPool.displayName
                }
              />
            </StackView>
            <StackView
              direction="row"
              space={75}
              justifyContent="end"
              alignItems="start"
            >
              <RouteToDropdown
                caseId={caseId}
                baseCaseId={baseCaseId}
                code={code}
                disabled={!!resolvedAt}
              />
              {[
                'SchedulingCase',
                'ResultsReviewCase',
                'DiagnosticReportReviewCase',
                'DocumentCase',
              ].includes(caseType) && (
                <Button
                  testId="task-status-update-btn"
                  disabled={!!resolvedAt}
                  buttonStyle="secondary"
                  onClick={onStatusUpdate}
                  icon={ArrowPathIcon}
                  text="Status update"
                />
              )}
              <Button
                buttonStyle="secondary"
                disabled={!!resolvedAt}
                testId="start-resolve-btn"
                onClick={onResolveCase}
                icon={CheckCircleIcon}
                text="Resolve"
              />
            </StackView>
          </StackView>
          <StackView
            direction="row"
            space={75}
            justifyContent="start"
            className="text-gray-800"
          >
            <StackView
              direction="row"
              fullWidth={false}
              alignItems="center"
              className={
                isOverdue(baseCase)
                  ? 'text-red-600'
                  : isUpcoming(baseCase)
                  ? 'text-gray-600'
                  : 'text-yellow-600'
              }
            >
              {!resolvedAt && (
                <>
                  {isOverdue(baseCase) ? (
                    <BellAlertIcon className="mr-2 h-5 w-5" />
                  ) : (
                    <BellIcon className="mr-2 h-5 w-5" />
                  )}
                  <Typography>{alertText(baseCase)}</Typography>
                </>
              )}
              {resolvedAt && <>{renderResolveStatusBadge()}</>}
            </StackView>
            <StackView direction="row" fullWidth={false} alignItems="center">
              {caseType === 'SchedulingCase' && (
                <CalendarIcon className="mr-2 h-5 w-5" />
              )}
              {caseType === 'DocumentCase' && (
                <DocumentIcon className="mr-2 h-5 w-5" />
              )}
              <Typography>
                {format(parseISO(resolvedAt || updatedAt), "MMM d 'at' h:mm a")}
              </Typography>
            </StackView>

            {patient ? (
              <NavChip
                testId="case-patient-link"
                to={routes.patientCharts({ id: patient.id })}
                text={formatDisplayName(patient)}
                leftContent={{ type: 'icon', icon: UserCircleIcon }}
                newTab
              />
            ) : patientRegistrationIntent ? (
              <Chip
                text={formatDisplayName(patientRegistrationIntent)}
                leftContent={{ type: 'icon', icon: UserCircleIcon }}
                disabled
              />
            ) : null}
            {caseType === 'ScreeningCase' && (
              <StackView direction="row" fullWidth={false} alignItems="center">
                <DocumentIcon className="mr-2 h-5 w-5" />
                <Typography>
                  {caseData?.screeningCase?.order?.questionnaire?.['title']}
                </Typography>
              </StackView>
            )}
          </StackView>
        </StackView>
      </Box>
    </StackView>
  )
}

export default CaseHeader
