import { useEffect, useState } from 'react'

import { formatISO, parseISO } from 'date-fns'
import { Location, VaccineFundingSource } from 'types/graphql'

import { useFieldArray, useForm, useFormContext } from '@redwoodjs/forms'
import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'

import Button from 'src/components/atoms/Button'
import { DropdownField } from 'src/components/atoms/Dropdown/Dropdown'
import InputField from 'src/components/atoms/InputField/InputField'
import StackView from 'src/components/atoms/StackView/StackView'
import TextAreaField from 'src/components/atoms/TextAreaField/TextAreaField'
import Typography from 'src/components/atoms/Typography/Typography'
import { DatePickerField } from 'src/components/molecules/DatePicker'
import FormBox from 'src/components/molecules/FormBox/FormBox'
import { FormInputList } from 'src/components/molecules/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

import AddInventoryTable from './AddInventoryTable'
import NdcSearchDisplay from './NdcSearchDisplay'

const GET_VACCINE_INVENTORY_BY_ID_QUERY = gql`
  query GetVaccineInventoryById($vaccineInventoryId: String!) {
    getVaccineInventoryById(vaccineInventoryId: $vaccineInventoryId) {
      id
      lotNumber
      expirationDate
      status
      comment
      ndcSale
      ndcDetails {
        brandName
        cvxCodeAndDescription
      }
      vaccineInventoryLocations {
        id
        location {
          id
          name
        }
        fundingSource
        currentDoses
      }
    }
  }
`

const ADD_INVENTORY = gql`
  mutation AddVaccineInventory($input: AddVaccineInventoryInput!) {
    addVaccineInventory(input: $input) {
      id
    }
  }
`

const EDIT_INVENTORY = gql`
  mutation EditVaccineInventory($input: EditVaccineInventoryInput!) {
    editVaccineInventory(input: $input) {
      id
    }
  }
`

const LOCATION_QUERY = gql`
  query GetLocationsForPractice {
    locations {
      id
      name
    }
  }
`

type AddInventoryFormBoxProps = {
  index: number
  locations: Location[]
  onSave: (index: number) => void
  onCancel: (index: number) => void
}

export type InventoryRow = {
  id: number
  location: Location
  fundingSource: VaccineFundingSource
  totalDoses: number
}

const AddInventoryFormBox = ({
  index,
  locations = [],
  onSave,
  onCancel,
}: AddInventoryFormBoxProps) => {
  const { watch } = useFormContext()

  const locationId = watch(`inventoryToAdd.${index}.locationId`)
  const fundingSource = watch(`inventoryToAdd.${index}.fundingSource`)
  const totalDoses = watch(`inventoryToAdd.${index}.totalDoses`)

  const isValid = locationId && fundingSource && totalDoses > 0

  const allInventoryToAdd = watch('inventoryToAdd')
  const locationInventory = allInventoryToAdd.filter(
    (i) => i.locationId === locationId
  )

  return (
    <FormBox
      onSave={() => onSave(index)}
      onCancel={() => onCancel(index)}
      isValid={isValid}
      testId="add-inventory-form-box"
    >
      <>
        <FormInputList
          items={[
            {
              name: `inventoryToAdd.${index}.locationId`,
              label: 'Storage location',
              required: true,
              formInputComponent: DropdownField,
              inputProps: {
                options: [
                  { name: 'Select location', value: null },
                  ...locations.map((location) => {
                    return { name: location.name, value: location.id }
                  }),
                ],
              },
            },
            {
              name: `inventoryToAdd.${index}.fundingSource`,
              label: 'Funding source',
              required: true,
              formInputComponent: DropdownField,
              inputProps: {
                placeholder: 'Select source',
                options: [
                  { name: 'Select source', value: null },
                  { name: 'Private', value: 'PRIVATE_FUNDS' },
                  { name: 'VFC', value: 'PUBLIC_VFC' },
                  { name: 'Other public', value: 'PUBLIC_NON_VFC' },
                ].filter((o) => {
                  return locationInventory.reduce((acc, curr) => {
                    if (acc) {
                      acc = curr.fundingSource
                        ? !curr.fundingSource.includes(o.value) || fundingSource
                        : true
                      return acc
                    }
                  }, true)
                }),
              },
            },
            {
              name: `inventoryToAdd.${index}.totalDoses`,
              label: 'Total doses',
              required: true,
              formInputComponent: InputField,
              inputProps: {
                type: 'number',
                min: 1,
                placeholder: 'Total doses',
              },
            },
          ]}
        />
      </>
    </FormBox>
  )
}

const SidepanelVaccineInventory = () => {
  const {
    closeSidePanel,
    sidepanelContext: { vaccineInventoryId },
  } = useSidepanel()

  const [addingInventory, setAddingInventory] = useState(false)
  const [inventoryRows, setInventoryRows] = useState([])
  const [ndcData, setNdcData] = useState()

  const { data: locationData, loading: locationLoading } =
    useQuery(LOCATION_QUERY)

  const {
    data: getVaccineInventoryByIdData,
    loading: getVaccineInventoryByIdLoading,
  } = useQuery(GET_VACCINE_INVENTORY_BY_ID_QUERY, {
    variables: {
      vaccineInventoryId,
    },
    skip: !vaccineInventoryId,
  })

  const [addVaccineInventory, { loading: addingVaccineInventory }] =
    useMutation(ADD_INVENTORY, {
      onCompleted: () => {
        toast.success(`Inventory added`)
        closeSidePanel()
      },
      refetchQueries: ['GetVaccineInventory'],
    })

  const [editVaccineInventory, { loading: editingVaccineInventory }] =
    useMutation(EDIT_INVENTORY, {
      onCompleted: () => {
        toast.success(`Inventory updated`)
        closeSidePanel()
      },
      refetchQueries: ['GetVaccineInventory'],
    })

  const formMethods = useForm()
  const { fields, append, remove } = useFieldArray({
    control: formMethods.control,
    name: 'inventoryToAdd',
  })

  // When we are editing inventory, we need to prepopulate all of the data for our form/state
  useEffect(() => {
    if (getVaccineInventoryByIdData) {
      const editVaccineInventoryData =
        getVaccineInventoryByIdData.getVaccineInventoryById
      formMethods.setValue('lotNumber', editVaccineInventoryData.lotNumber)
      formMethods.setValue(
        'expirationDate',
        parseISO(editVaccineInventoryData.expirationDate)
      )
      formMethods.setValue('comment', editVaccineInventoryData.comment)
      formMethods.setValue('ndcSale', editVaccineInventoryData.ndcSale)

      const prevInventory =
        editVaccineInventoryData.vaccineInventoryLocations.map((row, index) => {
          return {
            id: index,
            locationId: row.location.id,
            locationDisplay: row.location.name,
            fundingSource: row.fundingSource,
            totalDoses: row.currentDoses,
          }
        })

      // Sets the form state
      prevInventory.forEach((row) => {
        append({
          locationId: row.locationId,
          fundingSource: row.fundingSource,
          totalDoses: row.totalDoses,
        })
      })

      // Sets the inventoryRows used for the AddInventoryTable
      setInventoryRows(prevInventory)
    }
  }, [getVaccineInventoryByIdData, formMethods, append])

  const onSubmit = (data) => {
    // if we have vaccineInventoryId, we are editing
    if (vaccineInventoryId) {
      editVaccineInventory({
        variables: {
          input: {
            ...data,
            id: vaccineInventoryId,
          },
        },
      })
    } else {
      // Otherwise, we are adding new inventory
      addVaccineInventory({ variables: { input: data } })
    }
  }

  const createAddInventoryForm = () => {
    append({
      locationId: null,
      fundingSource: null,
      totalDoses: 1,
    })
    setAddingInventory(true)
  }

  const saveAddingInventory = (index) => {
    const inventoryValues = formMethods.getValues(`inventoryToAdd.${index}`)
    setAddingInventory(false)
    setInventoryRows([
      ...inventoryRows,
      {
        ...inventoryValues,
        locationDisplay: locationData.locations.find(
          (location) => location.id === inventoryValues.locationId
        ).name,
        id: index,
      },
    ])
  }

  const cancelAddingInventory = (index) => {
    remove(index)
    setAddingInventory(false)
  }

  const onArchiveInventoryRow = (index) => {
    remove(index)
    setInventoryRows(inventoryRows.filter((r) => r.id !== index))
  }

  const onUpdateCurrentDoses = (index, value, operator) => {
    const inventoryRow = inventoryRows.find((r) => r.id === index)
    let sum
    if (operator === 'plus') {
      sum = inventoryRow.totalDoses + Number(value)
    } else if (operator === 'minus') {
      sum = inventoryRow.totalDoses - Number(value)
    }

    if (sum > 0) {
      formMethods.setValue(`inventoryToAdd.${index}.totalDoses`, sum)

      const updatedRows = inventoryRows.map((r) => {
        if (r.id === index) {
          return {
            ...inventoryRow,
            totalDoses: sum,
          }
        } else {
          return r
        }
      })

      setInventoryRows(updatedRows)
    } else {
      toast.error('Number of doses must be 1 or greater')
    }
  }

  const onNdcDataReceived = (data) => {
    setNdcData(data)
  }

  return (
    <SidepanelPage
      header="Vaccine inventory details"
      loading={locationLoading || getVaccineInventoryByIdLoading}
    >
      <SidepanelForm
        footerProps={{
          cancelText: 'Discard changes',
          submitText: 'Save',
          disabled: inventoryRows.length === 0,
          submitting: addingVaccineInventory || editingVaccineInventory,
        }}
        formMethods={formMethods}
        onSubmit={onSubmit}
      >
        <StackView direction="row" space={100} className="mt-4">
          <StackView space={75}>
            <Typography textStyle="title">Vaccine lot overview</Typography>

            <FormInputList
              items={[
                {
                  name: 'lotNumber',
                  label: 'Lot number',
                  required: true,
                  formInputComponent: InputField,
                  inputProps: {
                    placeholder: 'Add lot number',
                  },
                },
                {
                  name: 'expirationDate',
                  label: 'Expiration date',
                  required: true,
                  formInputComponent: DatePickerField,
                  inputProps: {
                    placeholder: 'Select date',
                    validation: {
                      setValueAs: (value: Date) => formatISO(value),
                    },
                  },
                },
                {
                  name: 'comment',
                  label: 'Inventory comments',
                  required: false,
                  formInputComponent: TextAreaField,
                  inputProps: {
                    placeholder: 'Additional comments',
                  },
                },
              ]}
            />

            <Typography textStyle="title">Inventory details</Typography>

            {inventoryRows.length > 0 && (
              <AddInventoryTable
                rowData={inventoryRows}
                onArchiveRow={onArchiveInventoryRow}
                onUpdateCurrentDoses={onUpdateCurrentDoses}
              />
            )}

            {addingInventory && (
              <AddInventoryFormBox
                index={fields.length - 1}
                locations={locationData.locations}
                onSave={(index) => saveAddingInventory(index)}
                onCancel={(index) => cancelAddingInventory(index)}
              />
            )}

            <Button
              buttonStyle="secondary"
              className="my-4"
              onClick={createAddInventoryForm}
              disabled={!ndcData || addingInventory}
            >
              Add New Inventory
            </Button>
          </StackView>

          <NdcSearchDisplay
            defaultNdc={
              getVaccineInventoryByIdData?.getVaccineInventoryById.ndcSale ||
              null
            }
            onNdcDataReceived={onNdcDataReceived}
          />
        </StackView>
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelVaccineInventory
