import { isUndefined } from 'lodash'

import Modal from 'src/components/molecules/Modal'
import { AppointmentToCancel } from 'src/providers/context/AppointmentsManagementContext'

const CancelAppointmentModal: React.FC<{
  appointmentToCancel: AppointmentToCancel
  onClose: () => void
}> = ({ appointmentToCancel, onClose }) => {
  const { cancellationType, cancelAppointmentCallback } = appointmentToCancel

  if (cancellationType === 'now') {
    return (
      <Modal
        title="Reschedule now"
        modalStyle="warning"
        isOpen={!isUndefined(appointmentToCancel)}
        confirmText="Reschedule"
        content="Cancel the currently scheduled visit, and immediately start the visit reschedule process."
        onConfirm={() => {
          cancelAppointmentCallback()
        }}
        setIsOpen={(isOpen) => !isOpen && onClose()}
        onClose={onClose}
      />
    )
  }
  if (cancellationType === 'later') {
    return (
      <Modal
        title="Reschedule later"
        modalStyle="warning"
        isOpen={!isUndefined(appointmentToCancel)}
        confirmText="Reschedule later"
        content="Cancel the currently scheduled visit and create a task to schedule it later."
        onConfirm={() => {
          cancelAppointmentCallback()
        }}
        setIsOpen={(isOpen) => !isOpen && onClose()}
        onClose={onClose}
      />
    )
  }
  return (
    <Modal
      title="Cancel visit without rescheduling"
      isOpen={!isUndefined(appointmentToCancel)}
      confirmText="Cancel visit"
      content="Cancel the currently scheduled visit without rescheduling."
      onConfirm={() => {
        cancelAppointmentCallback()
      }}
      setIsOpen={(isOpen) => !isOpen && onClose()}
      onClose={onClose}
    />
  )
}

export default CancelAppointmentModal
