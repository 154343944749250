import { useRef } from 'react'

import { ArchiveBoxArrowDownIcon } from '@heroicons/react/24/solid'
import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import Button from 'src/components/atoms/Button'
import NumberSpinner from 'src/components/atoms/NumberSpinner/NumberSpinner'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import Table from 'src/components/molecules/Table/Table'

import { InventoryRow } from './SidepanelVaccineInventory'

type AddInventoryTableProps = {
  rowData: InventoryRow[]
  onArchiveRow: (id: number) => void
  onUpdateCurrentDoses: (
    id: number,
    value: string,
    operator: 'plus' | 'minus'
  ) => void
}

export const fundingSourceDescriptionMap = {
  PRIVATE_FUNDS: 'Private',
  PUBLIC_VFC: 'VFC',
  PUBLIC_NON_VFC: 'Other public',
}

export const fundingSourceColorMap = {
  PUBLIC_NON_VFC: 'purple',
  PUBLIC_VFC: 'blue',
  PRIVATE_FUNDS: 'yellow',
}

const defaultColDef: ColDef = {
  cellStyle: {
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  cellClass: '',
  resizable: true,
  autoHeight: true,
  wrapText: true,
  minWidth: 180,
}

const AddInventoryTable = ({
  rowData = [],
  onArchiveRow,
  onUpdateCurrentDoses,
}: AddInventoryTableProps) => {
  const gridRef = useRef<AgGridReact>()
  const rowHeight = 72
  const headerHeight = 36

  const updateCurrentDoses = (id, value, operator) => {
    onUpdateCurrentDoses(id, value, operator)
  }

  const columnDefinitions = [
    {
      colId: 'locationAndFundingSource',
      headerName: 'Location & Funding Source',
      cellRenderer: ({ data }) => {
        return (
          <StackView>
            <Typography>{data.locationDisplay}</Typography>
            <Typography color="text-base-color-fg-muted">
              {fundingSourceDescriptionMap[data.fundingSource]}
            </Typography>
          </StackView>
        )
      },
      resizable: true,
      wrapText: true,
      autoHeight: true,
      minWidth: 150,
    },
    {
      colId: 'currentDoses',
      headerName: '# Doses',
      cellRenderer: ({ data }) => (
        <Typography fontWeight="bold">{data.totalDoses}</Typography>
      ),
      resizable: true,
      wrapText: true,
      autoHeight: true,
      minWidth: 100,
    },
    {
      colId: 'plusOrMinus',
      headerName: '± Doses',
      cellRenderer: ({ data }) => (
        <NumberSpinner
          callback={(value, operator) =>
            updateCurrentDoses(data.id, value, operator)
          }
        />
      ),
      resizable: true,
      wrapText: true,
      autoHeight: true,
      minWidth: 140,
    },
    {
      colId: 'actions',
      headerName: '',
      cellRenderer: ({ data }) => (
        <Button
          buttonStyle="ghost"
          icon={ArchiveBoxArrowDownIcon}
          onClick={() => onArchiveRow(data.id)}
        />
      ),
      resizable: true,
      wrapText: true,
      autoHeight: true,
      minWidth: 80,
    },
  ]

  return (
    <div
      style={{
        minHeight:
          rowData.length * rowHeight + headerHeight + rowData.length + 1 + 16, // rowData.length + 1 accounts for borders, 16 for scrollbar
      }}
    >
      <Table
        testId="add-inventory-table"
        innerRef={gridRef}
        rowData={rowData}
        rowHeight={rowHeight}
        headerHeight={headerHeight}
        defaultColDef={defaultColDef}
        columnDefs={columnDefinitions}
        animateRows={true}
        pagination={false}
      />
    </div>
  )
}

export default AddInventoryTable
