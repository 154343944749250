import React, { Fragment, ReactElement } from 'react'

import { Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'
import { Route, Routes } from 'react-router-dom'

import { useLocation } from '@redwoodjs/router'

import Box from 'src/components/atoms/Box/Box'
import Button from 'src/components/atoms/Button/Button'
import StackView from 'src/components/atoms/StackView/StackView'
import FeatureFlagged from 'src/components/molecules/FeatureFlagged/FeatureFlagged'
import SidepanelPatientSignedFormUpload from 'src/components/Patient/SidepanelPatientSignedFormUpload/SidepanelPatientSignedFormUpload'
import SidepanelOutboundFax from 'src/pages/OutboundFaxPage/SidepanelOutboundFax/SidepanelOutboundFax'
import SidepanelAllergyCreate from 'src/pages/Sidepanel/SidepanelAllergyCreate/SidepanelAllergyCreate'
import SidepanelAllergyEdit from 'src/pages/Sidepanel/SidepanelAllergyEdit/SidepanelAllergyEdit'
import SidepanelAppointment from 'src/pages/Sidepanel/SidepanelAppointment/SidepanelAppointment'
import SidepanelBillingStatement from 'src/pages/Sidepanel/SidepanelBillingStatement/SidepanelBillingStatement'
import SidepanelBinaryDisplay from 'src/pages/Sidepanel/SidepanelBinaryDisplay/SidepanelBinaryDisplay'
import SidepanelBirthHistoryEdit from 'src/pages/Sidepanel/SidepanelBirthHistoryEdit/SidepanelBirthHistoryEdit'
import SidepanelCaregiverFamilyPortalAccount from 'src/pages/Sidepanel/SidepanelCaregiverFamilyPortalAccount/SidepanelCaregiverFamilyPortalAccount'
import SidepanelCaregiverFinancials from 'src/pages/Sidepanel/SidepanelCaregiverFinancials/SidepanelCaregiverFinancials'
import SidepanelCaregiverLinkPatients from 'src/pages/Sidepanel/SidepanelCaregiverLinkPatients/SidepanelCaregiverLinkPatients'
import SidepanelChargeCaptureRuleView from 'src/pages/Sidepanel/SidepanelChargeCaptureViewRule/SidepanelChargeCaptureRuleView'
import SidepanelChargeItemCreate from 'src/pages/Sidepanel/SidepanelChargeItemCreate/SidepanelChargeItemCreate'
import SidepanelChargeItemEdit from 'src/pages/Sidepanel/SidepanelChargeItemEdit/SidepanelChargeItemEdit'
import SidepanelCompleteHearingScreeningOrderEdit from 'src/pages/Sidepanel/SidepanelCompleteHearingScreeningEdit/SidepanelCompleteHearingScreeningEdit'
import SidepanelCompleteInHouseTestOrderEdit from 'src/pages/Sidepanel/SidepanelCompleteInHouseTestOrderEdit/SidepanelCompleteInHouseTestOrderEdit'
import SidepanelCompleteQuestionnaireOrderEdit from 'src/pages/Sidepanel/SidepanelCompleteQuestionnaireOrderEdit/SidepanelCompleteQuestionnaireOrderEdit'
import SidepanelCompleteScreeningOrderEdit from 'src/pages/Sidepanel/SidepanelCompleteScreeningEdit/SidepanelCompleteScreeningEdit'
import SidepanelCompleteVisionScreeningOrderEdit from 'src/pages/Sidepanel/SidepanelCompleteVisionScreeningEdit/SidepanelCompleteVisionScreeningEdit'
import SidepanelContactCreate from 'src/pages/Sidepanel/SidepanelContactCreate/SidepanelContactCreate'
import SidepanelContactEdit from 'src/pages/Sidepanel/SidepanelContactEdit/SidepanelContactEdit'
import SidepanelContactOrganizationEdit from 'src/pages/Sidepanel/SidepanelContactOrganizationEdit/SidepanelContactOrganizationEdit'
import SidepanelCreateCase from 'src/pages/Sidepanel/SidepanelCreateCase/SidepanelCreateCase'
import SidepanelDiagnosisCreate from 'src/pages/Sidepanel/SidepanelDiagnosisCreate/SidepanelDiagnosisCreate'
import SidepanelDiagnosisEdit from 'src/pages/Sidepanel/SidepanelDiagnosisEdit/SidepanelDiagnosisEdit'
import SidepanelDocumentCaseResolution from 'src/pages/Sidepanel/SidepanelDocumentCaseResolution/SidepanelDocumentCaseResolution'
import SidepanelDocumentCaseStatusUpdate from 'src/pages/Sidepanel/SidepanelDocumentCaseStatusUpdate/SidepanelDocumentCaseStatusUpdate'
import SidepanelDocumentCreate from 'src/pages/Sidepanel/SidepanelDocumentCreate/SidepanelDocumentCreate'
import SidepanelExternalRequest from 'src/pages/Sidepanel/SidepanelExternalRequest/SidepanelExternalRequest'
import SidepanelExternalRequestEdit from 'src/pages/Sidepanel/SidepanelExternalRequestEdit/SidepanelExternalRequestEdit'
import SidepanelFamilyHistoryCreate from 'src/pages/Sidepanel/SidepanelFamilyHistoryCreate/SidepanelFamilyHistoryCreate'
import SidepanelFamilyMemberHistoryEdit from 'src/pages/Sidepanel/SidepanelFamilyMemberHistoryEdit/SidepanelFamilyMemberHistoryEdit'
import SidepanelFamilyMessageCaseResolution from 'src/pages/Sidepanel/SidepanelFamilyMessageCaseResolution/SidepanelFamilyMessageCaseResolution'
import SidepanelImmunizationHistoryAdd from 'src/pages/Sidepanel/SidepanelImmunizationHistory/SidepanelImmunizationHistoryAdd'
import SidepanelImmunizationHistoryEdit from 'src/pages/Sidepanel/SidepanelImmunizationHistory/SidepanelImmunizationHistoryEdit'
import SidepanelMacroPhrase from 'src/pages/Sidepanel/SidepanelMacroPhrase/SidepanelMacroPhrase'
import SidepanelMenstrualHistoryEdit from 'src/pages/Sidepanel/SidepanelMenstrualHistoryEdit/SidepanelMenstrualHistoryEdit'
import SidepanelNewAppointment from 'src/pages/Sidepanel/SidepanelNewAppointment/SidepanelNewAppointment'
import SidepanelOrderCreate from 'src/pages/Sidepanel/SidepanelOrderCreate/SidepanelOrderCreate'
import SidepanelOrderEdit from 'src/pages/Sidepanel/SidepanelOrderEdit/SidepanelOrderEdit'
import SidepanelOrderView from 'src/pages/Sidepanel/SidepanelOrderView/SidepanelOrderView'
import SidepanelPatientChargesAdd from 'src/pages/Sidepanel/SidepanelPatientChargesAdd/SidepanelPatientChargesAdd'
import SidepanelPatientChargeView from 'src/pages/Sidepanel/SidepanelPatientChargeView/SidepanelPatientChargeView'
import SidepanelPatientDiagnosticReport from 'src/pages/Sidepanel/SidepanelPatientDiagnosticReport/SidepanelPatientDiagnosticReport'
import SidepanelPatientDocument from 'src/pages/Sidepanel/SidepanelPatientDocument/SidepanelPatientDocument'
import SidepanelPatientDocumentCreate from 'src/pages/Sidepanel/SidepanelPatientDocumentCreate/SidepanelPatientDocumentCreate'
import SidepanelPatientDocumentEdit from 'src/pages/Sidepanel/SidepanelPatientDocumentEdit/SidepanelPatientDocumentEdit'
import SidepanelPatientDocumentUpload from 'src/pages/Sidepanel/SidepanelPatientDocumentUpload/SidepanelPatientDocumentUpload'
import SidepanelPatientEducationDocument from 'src/pages/Sidepanel/SidepanelPatientEducationDocument/SidepanelPatientEducationDocument'
import SidepanelPatientEducationDocumentEdit from 'src/pages/Sidepanel/SidepanelPatientEducationDocumentEdit'
import SidepanelPatientFamilyPortalAccount from 'src/pages/Sidepanel/SidepanelPatientFamilyPortalAccount/SidepanelPatientFamilyPortalAccount'
import SidepanelPatientFinancials from 'src/pages/Sidepanel/SidepanelPatientFinancials/SidepanelPatientFinancials'
import SidepanelPatientForm from 'src/pages/Sidepanel/SidepanelPatientForm/SidepanelPatientForm'
import SidepanelPatientFormEdit from 'src/pages/Sidepanel/SidepanelPatientFormEdit/SidepanelPatientFormEdit'
import SidepanelPatientInsuranceCoveragesView from 'src/pages/Sidepanel/SidepanelPatientInsuranceCoveragesView/SidepanelPatientInsuranceCoveragesView'
import SidepanelPatientNoInsuranceCoveragesView from 'src/pages/Sidepanel/SidepanelPatientNoInsuranceCoveragesView/SidepanelPatientNoInsuranceCoveragesView'
import SidepanelPatientPaymentEdit from 'src/pages/Sidepanel/SidepanelPatientPaymentEdit/SidepanelPatientPaymentEdit'
import SidepanelPatientPaymentPlanChangePaymentMethod from 'src/pages/Sidepanel/SidepanelPatientPaymentPlanChangePaymentMethod/SidepanelPatientPaymentPlanChangePaymentMethod'
import SidepanelPatientPaymentPlanCreate from 'src/pages/Sidepanel/SidepanelPatientPaymentPlanCreate/SidepanelPatientPaymentPlanCreate'
import SidepanelPatientPaymentPlanView from 'src/pages/Sidepanel/SidepanelPatientPaymentPlanView/SidepanelPatientPaymentPlanView'
import SidepanelPatientPaymentView from 'src/pages/Sidepanel/SidepanelPatientPaymentView/SidepanelPatientPaymentView'
import SidepanelPatientPracticeComment from 'src/pages/Sidepanel/SidepanelPatientPracticeComment/SidepanelPatientPracticeComment'
import SidepanelPatientScoreUpsert from 'src/pages/Sidepanel/SidepanelPatientScoreUpsert/SidepanelPatientScoreUpsert'
import SidepanelPatientScoreView from 'src/pages/Sidepanel/SidepanelPatientScoreView/SidepanelPatientScoreView'
import SidepanelPatientTasks from 'src/pages/Sidepanel/SidepanelPatientTasks/SidepanelPatientTasks'
import SidepanelPatientVitalsAdd from 'src/pages/Sidepanel/SidepanelPatientVitalsAdd/SidepanelPatientVitalsAdd'
import SidepanelPatientVitalsEdit from 'src/pages/Sidepanel/SidepanelPatientVitalsEdit/SidepanelPatientVitalsEdit'
import SidepanelPayerAddressCreate from 'src/pages/Sidepanel/SidepanelPayerAddressCreate/SidepanelPayerAddressCreate'
import SidepanelPaymentPlanPaymentView from 'src/pages/Sidepanel/SidepanelPaymentPlanPaymentView/SidepanelPaymentPlanPaymentView'
import SidepanelPaymentReceipt from 'src/pages/Sidepanel/SidepanelPaymentReceipt/SidepanelPaymentReceipt'
import SidepanelPortalUserAddLoginDetails from 'src/pages/Sidepanel/SidepanelPortalUserAddLoginDetails/SidepanelPortalUserAddLoginDetails'
import SidepanelPortalUserDuplicateResolution from 'src/pages/Sidepanel/SidepanelPortalUserDuplicateResolution/SidepanelPortalUserDuplicateResolution'
import SidepanelPortalUserEditLoginDetails from 'src/pages/Sidepanel/SidepanelPortalUserEditLoginDetails/SidepanelPortalUserEditLoginDetails'
import SidepanelPracticeAdminDocument from 'src/pages/Sidepanel/SidepanelPracticeAdminDocument/SidepanelPracticeAdminDocument'
import SidepanelPracticeAdminDocumentEdit from 'src/pages/Sidepanel/SidepanelPracticeAdminDocumentEdit/SidepanelPracticeAdminDocumentEdit'
import SidepanelPracticeForm from 'src/pages/Sidepanel/SidepanelPracticeForm/SidepanelPracticeForm'
import { SidepanelPracticeFormConfigureFormSchema } from 'src/pages/Sidepanel/SidepanelPracticeFormConfigureFormSchema/SidepanelPracticeFormConfigureFormSchema'
import SidepanelPracticeFormEdit from 'src/pages/Sidepanel/SidepanelPracticeFormEdit/SidepanelPracticeFormEdit'
import SidepanelPracticeLetter from 'src/pages/Sidepanel/SidepanelPracticeLetter/SidepanelPracticeLetter'
import SidepanelPracticeLetterEdit from 'src/pages/Sidepanel/SidepanelPracticeLetterEdit/SidepanelPracticeLetterEdit'
import SidepanelPracticeScheduleAddOverride from 'src/pages/Sidepanel/SidepanelPracticeScheduleAddOverride'
import SidepanelPracticeScheduleEditOverride from 'src/pages/Sidepanel/SidepanelPracticeScheduleEditOverride'
import SidepanelPractitionerScheduleAddOverride from 'src/pages/Sidepanel/SidepanelPractitionerScheduleAddOverride'
import SidepanelPractitionerScheduleEditOverride from 'src/pages/Sidepanel/SidepanelPractitionerScheduleEditOverride'
import SidepanelPrintGrowthCharts from 'src/pages/Sidepanel/SidepanelPrintGrowthCharts/SidepanelPrintGrowthCharts'
import SidepanelPrintImmunizations from 'src/pages/Sidepanel/SidepanelPrintImmunizations/SidepanelPrintImmunizations'
import SidepanelPrintPatientDemographics from 'src/pages/Sidepanel/SidepanelPrintPatientDemographics/SidepanelPrintPatientDemographics'
import SidepanelPrintSchedule from 'src/pages/Sidepanel/SidepanelPrintSchedule/SidepanelPrintSchedule'
import SidepanelProductGuide from 'src/pages/Sidepanel/SidepanelProductGuide/SidepanelProductGuide'
import SidepanelQuestionnaireOrderView from 'src/pages/Sidepanel/SidepanelQuestionnaireOrderView/SidepanelQuestionnaireOrderView'
import SidepanelDiagnosticReportReviewCaseResolution from 'src/pages/Sidepanel/SidepanelResultsReviewCaseResolution/SidepanelDiagnosticReportReviewCaseResolution'
import SidepanelResultsReviewCaseResolution from 'src/pages/Sidepanel/SidepanelResultsReviewCaseResolution/SidepanelResultsReviewCaseResolution'
import SidepanelResultsReviewCaseStatusUpdate from 'src/pages/Sidepanel/SidepanelResultsReviewCaseStatusUpdate/SidepanelResultsReviewCaseStatusUpdate'
import SidepanelResultsReviewEditOrders from 'src/pages/Sidepanel/SidepanelResultsReviewEditOrders/SidepanelResultsReviewEditOrders'
import {
  SidepanelTemplateReviewOfSystemEdit,
  SidepanelVisitReviewOfSystemEdit,
} from 'src/pages/Sidepanel/SidepanelReviewOfSystemEdit/SidepanelReviewOfSystemEdit'
import SidepanelSchedulingCaseResolution from 'src/pages/Sidepanel/SidepanelSchedulingCaseResolution/SidepanelSchedulingCaseResolution'
import SidepanelSchedulingCaseStatusUpdate from 'src/pages/Sidepanel/SidepanelSchedulingCaseStatusUpdate/SidepanelSchedulingCaseStatusUpdate'
import SidepanelScreeningCaseResolution from 'src/pages/Sidepanel/SidepanelScreeningCaseResolution/SidepanelScreeningCaseResolution'
import SidepanelScreeningTool from 'src/pages/Sidepanel/SidepanelScreeningTool/SidepanelScreeningTool'
import SidepanelScreeningToolEdit from 'src/pages/Sidepanel/SidepanelScreeningToolEdit/SidepanelScreeningToolEdit'
import SidepanelSendFax from 'src/pages/Sidepanel/SidepanelSendFax/SidepanelSendFax'
import SidepanelSexualHistoryEdit from 'src/pages/Sidepanel/SidepanelSexualHistoryEdit/SidepanelSexualHistoryEdit'
import SidepanelSocialHistoryEdit from 'src/pages/Sidepanel/SidepanelSocialHistoryEdit/SidepanelSocialHistoryEdit'
import SidepanelStaffMessageCaseResolution from 'src/pages/Sidepanel/SidepanelStaffMessageCaseResolution/SidepanelStaffMessageCaseResolution'
import SidepanelSupervisingPractitionerAssignment from 'src/pages/Sidepanel/SidepanelSupervisingPractitionerAssignment/SidepanelSupervisingPractitionerAssignment'
import SidepanelTaskUserPoolCreate from 'src/pages/Sidepanel/SidepanelTaskUserPoolCreate/SidepanelTaskUserPoolCreate'
import SidepanelTaskUserPoolEdit from 'src/pages/Sidepanel/SidepanelTaskUserPoolEdit/SidepanelTaskUserPoolEdit'
import SidepanelUserChangePassword from 'src/pages/Sidepanel/SidepanelUser/SidepanelUserChangePassword/SidepanelUserChangePassword'
import SidepanelUserCreate from 'src/pages/Sidepanel/SidepanelUser/SidepanelUserCreate/SidepanelUserCreate'
import SidepanelUserDisplay from 'src/pages/Sidepanel/SidepanelUser/SidepanelUserDisplay/SidepanelUserDisplay'
import SidepanelUserEdit from 'src/pages/Sidepanel/SidepanelUser/SidepanelUserEdit/SidepanelUserEdit'
import SidepanelVaccineDoseOverview from 'src/pages/Sidepanel/SidepanelVaccineDoseOverview/SidepanelVaccineDoseOverview'
import SidepanelTransferVaccineInventory from 'src/pages/Sidepanel/SidepanelVaccineInventory/SidepanelTransferVaccineInventory'
import SidepanelVaccineInventory from 'src/pages/Sidepanel/SidepanelVaccineInventory/SidepanelVaccineInventory'
import SidepanelVisitAudioRecordings from 'src/pages/Sidepanel/SidepanelVisitAudioRecordings/SidepanelVisitAudioRecordings'
import SidepanelVisitDiagnosisCreate from 'src/pages/Sidepanel/SidepanelVisitDiagnosisCreate/SidepanelVisitDiagnosisCreate'
import SidepanelVisitDiagnosisEdit from 'src/pages/Sidepanel/SidepanelVisitDiagnosisEdit/SidepanelVisitDiagnosisEdit'
import SidepanelVisitOrderCreate from 'src/pages/Sidepanel/SidepanelVisitOrderCreate/SidepanelVisitOrderCreate'
import SidepanelVisitOrderEdit from 'src/pages/Sidepanel/SidepanelVisitOrderEdit/SidepanelVisitOrderEdit'
import SidepanelVisitPrintAVS from 'src/pages/Sidepanel/SidepanelVisitPrintAVS/SidepanelVisitPrintAVS'
import SidepanelVisitPrintVisitNote from 'src/pages/Sidepanel/SidepanelVisitPrintVisitNote/SidepanelVisitPrintVisitNote'
import SidepanelVisitTemplateDiagnosisCreate from 'src/pages/Sidepanel/SidepanelVisitTemplateDiagnosisCreate/SidepanelVisitTemplateDiagnosisCreate'
import SidepanelVisitTemplateOrderCreate from 'src/pages/Sidepanel/SidepanelVisitTemplateOrderCreate/SidepanelVisitTemplateOrderCreate'
import SidepanelVisitVaccineAdministrationEdit from 'src/pages/Sidepanel/SidepanelVisitVaccineAdministration/SidepanelVisitVaccineAdministrationEdit'
import SidepanelVisitVaccineAdministrationUndo from 'src/pages/Sidepanel/SidepanelVisitVaccineAdministration/SidepanelVisitVaccineAdministrationUndo'
import SidepanelVisitVaccineAdministrationView from 'src/pages/Sidepanel/SidepanelVisitVaccineAdministration/SidepanelVisitVaccineAdministrationView'
import SidepanelVisitVitalsEdit from 'src/pages/Sidepanel/SidepanelVisitVitalsEdit/SidepanelVisitVitalsEdit'
import SidepanelZcodeCreate from 'src/pages/Sidepanel/SidepanelZcodeCreate/SidepanelZcodeCreate'
import SidepanelZcodeEdit from 'src/pages/Sidepanel/SidepanelZcodeEdit/SidepanelZcodeEdit'
import {
  SidepanelRouteContext,
  useSidepanel,
} from 'src/providers/context/SidepanelContext'
import { SidepanelPortalContext } from 'src/utils/sidepanel'
import PrivateSidepanelRoute from 'src/wrappers/PrivateSidepanelRoute/PrivateSidepanelRoute'

export const SidepanelRoutes = () => {
  const location = useLocation()
  const { sidepanelContext } = useSidepanel()

  const routesLocation = { ...location, pathname: sidepanelContext.route }

  return (
    <Routes location={routesLocation}>
      {/* Users */}
      <Route
        path="user/change-password"
        element={<SidepanelUserChangePassword />}
      />
      <Route
        path="user/new"
        element={
          <PrivateSidepanelRoute roles={['ADMIN']}>
            <SidepanelUserCreate />
          </PrivateSidepanelRoute>
        }
      />
      <Route
        path="users/:id/edit"
        element={
          <PrivateSidepanelRoute roles={['ADMIN']}>
            <SidepanelUserEdit />
          </PrivateSidepanelRoute>
        }
      />
      <Route path="users/:id" element={<SidepanelUserDisplay />} />

      {/* Patient chart */}
      <Route
        path="patients/:patientId/scores/new"
        element={<SidepanelPatientScoreUpsert />}
      />
      <Route
        path="patients/:patientId/scores/:patientScoringSystemCode/view"
        element={<SidepanelPatientScoreView />}
      />
      <Route
        path="patients/:patientId/scores/:patientScoringSystemCode/edit"
        element={<SidepanelPatientScoreUpsert />}
      />
      <Route
        path="patients/:patientId/allergies/new"
        element={<SidepanelAllergyCreate />}
      />
      <Route
        path="patients/:patientId/allergies/:allergenCode"
        element={<SidepanelAllergyEdit />}
      />
      <Route
        path="patients/:patientId/diagnoses/new"
        element={<SidepanelDiagnosisCreate />}
      />
      <Route
        path="patients/:patientId/diagnoses/:diagnosisCode"
        element={<SidepanelDiagnosisEdit />}
      />
      <Route
        path="patients/:patientId/zcodes/new"
        element={<SidepanelZcodeCreate />}
      />
      <Route
        path="patients/:patientId/zcodes/:diagnosisCode"
        element={<SidepanelZcodeEdit />}
      />
      <Route
        path="patients/:patientId/documents/:formTemplateId"
        element={<SidepanelPatientSignedFormUpload />}
      />
      <Route
        path="patients/:patientId/orders/:category/create"
        element={<SidepanelOrderCreate />}
      />
      <Route
        path="patients/:patientId/orders/:orderId/edit"
        element={<SidepanelOrderEdit />}
      />
      <Route
        path="patients/:patientId/orders/:orderId"
        element={<SidepanelOrderView />}
      />
      <Route
        path="patients/:patientId/birthHistory/edit"
        element={<SidepanelBirthHistoryEdit />}
      />
      <Route
        path="patients/:patientId/sexualHistory/edit"
        element={<SidepanelSexualHistoryEdit />}
      />
      <Route
        path="patients/:patientId/menstrualHistory/edit"
        element={<SidepanelMenstrualHistoryEdit />}
      />
      <Route
        path="patients/:patientId/socialHistory/edit"
        element={<SidepanelSocialHistoryEdit />}
      />

      <Route
        path="patients/:patientId/familyHistory/create"
        element={<SidepanelFamilyHistoryCreate />}
      />
      <Route
        path="patients/:patientId/familyHistory/:familyHistoryId/edit"
        element={<SidepanelFamilyMemberHistoryEdit />}
      />
      <Route
        path="patients/:patientId/payments/:patientPaymentId"
        element={<SidepanelPatientPaymentView />}
      />
      <Route
        path="patients/:patientId/payment-plan-payments/:paymentPlanPaymentId"
        element={<SidepanelPaymentPlanPaymentView />}
      />
      <Route
        path="patients/:patientId/payments/:patientPaymentId/edit"
        element={<SidepanelPatientPaymentEdit />}
      />
      <Route
        path="patients/:patientId/payments/:date/receipt"
        element={<SidepanelPaymentReceipt />}
      />
      <Route
        path="patients/:patientId/charges/:patientChargeId"
        element={<SidepanelPatientChargeView />}
      />
      <Route
        path="patients/:patientId/charges/add"
        element={<SidepanelPatientChargesAdd />}
      />
      <Route
        path="patients/:patientId/payment-plan/create"
        element={<SidepanelPatientPaymentPlanCreate />}
      />
      <Route
        path="patients/:patientId/payment-plan/:patientPaymentPlanId"
        element={<SidepanelPatientPaymentPlanView />}
      />
      <Route
        path="patients/:patientId/payment-plan/:patientPaymentPlanId/change-payment-method"
        element={<SidepanelPatientPaymentPlanChangePaymentMethod />}
      />
      <Route
        path="patients/:patientId/practice-comment"
        element={<SidepanelPatientPracticeComment />}
      />
      <Route
        path="patients/:patientId/tasks"
        element={<SidepanelPatientTasks />}
      />
      <Route
        path="patients/:patientId/insurance-coverages/:insuranceCoverageId"
        element={<SidepanelPatientInsuranceCoveragesView />}
      />
      <Route
        path="patients/:patientId/insurance-coverages/no-insurance"
        element={<SidepanelPatientNoInsuranceCoveragesView />}
      />

      {/* Visit flow */}
      <Route
        path="encounters/:encounterId/diagnoses/new"
        element={<SidepanelVisitDiagnosisCreate />}
      />
      <Route
        path="encounters/:encounterId/vitals/edit"
        element={<SidepanelVisitVitalsEdit />}
      />
      <Route
        path="encounters/:encounterId/diagnoses/:conditionId/edit"
        element={<SidepanelVisitDiagnosisEdit />}
      />
      <Route
        path="encounters/:encounterId/orders/:category/create"
        element={<SidepanelVisitOrderCreate />}
      />
      <Route
        path="encounters/:encounterId/orders/:orderId/edit"
        element={<SidepanelVisitOrderEdit />}
      />
      <Route
        path="encounters/:encounterId/location/:locationId/vaccine-admin/:orderId"
        element={<SidepanelVisitVaccineAdministrationView />}
      />
      <Route
        path="encounters/:encounterId/location/:locationId/vaccine-admin/:orderId/edit"
        element={<SidepanelVisitVaccineAdministrationEdit />}
      />
      <Route
        path="encounters/:encounterId/location/:locationId/vaccine-admin/:orderId/undo"
        element={<SidepanelVisitVaccineAdministrationUndo />}
      />
      <Route
        path="encounters/:encounterId/ROS/:system/edit"
        element={<SidepanelVisitReviewOfSystemEdit />}
      />
      <Route
        path="encounters/:encounterId/inHouseTests/:orderId/edit"
        element={<SidepanelCompleteInHouseTestOrderEdit />}
      />
      <Route
        path="encounters/:encounterId/screenings/:orderId/edit"
        element={<SidepanelCompleteScreeningOrderEdit />}
      />
      <Route
        path="encounters/:encounterId/visionScreenings/:orderId/edit"
        element={<SidepanelCompleteVisionScreeningOrderEdit />}
      />
      <Route
        path="encounters/:encounterId/hearingScreenings/:orderId/edit"
        element={<SidepanelCompleteHearingScreeningOrderEdit />}
      />

      <Route
        path="encounters/:encounterId/questionnaires/:orderId/edit"
        element={<SidepanelCompleteQuestionnaireOrderEdit />}
      />

      <Route
        path="encounters/:encounterId/questionnaires/:orderId/view"
        element={<SidepanelQuestionnaireOrderView />}
      />
      <Route
        path="encounters/:encounterId/charges/new"
        element={<SidepanelChargeItemCreate />}
      />
      <Route
        path="charges/:chargeId/edit"
        element={<SidepanelChargeItemEdit />}
      />
      <Route
        path="appointments/:appointmentId/visitAudioRecordings"
        element={<SidepanelVisitAudioRecordings />}
      />

      {/* Documents */}
      <Route
        path="documents/binary/:binaryId"
        element={<SidepanelBinaryDisplay />}
      />
      <Route
        path="documents/practice-admin/:practiceAdminDocumentId"
        element={<SidepanelPracticeAdminDocument />}
      />
      <Route
        path="documents/practice-admin/:practiceAdminDocumentId/edit"
        element={<SidepanelPracticeAdminDocumentEdit />}
      />
      <Route
        path="documents/patient-document/upload"
        element={<SidepanelPatientDocumentUpload />}
      />
      <Route
        path="documents/patient-document/create"
        element={<SidepanelPatientDocumentCreate />}
      />
      <Route
        path="documents/patient-document/create/:documentId"
        element={<SidepanelPatientDocumentCreate />}
      />
      <Route
        path="documents/patient-document/:patientDocumentId/edit"
        element={<SidepanelPatientDocumentEdit />}
      />
      <Route
        path="documents/patient-document/:patientDocumentId"
        element={<SidepanelPatientDocument />}
      />
      <Route
        path="documents/diagnostic-report/:diagnosticReportId"
        element={<SidepanelPatientDiagnosticReport />}
      />
      <Route
        path="documents/patient-form/:patientFormId"
        element={<SidepanelPatientForm />}
      />
      <Route
        path="documents/patient-form/:patientFormId/edit"
        element={<SidepanelPatientFormEdit />}
      />
      <Route
        path="documents/external-request/:externalRequestId"
        element={<SidepanelExternalRequest />}
      />
      <Route
        path="documents/external-request/:externalRequestId/edit"
        element={<SidepanelExternalRequestEdit />}
      />
      <Route
        path="documents/screening-tool/:screeningToolId"
        element={<SidepanelScreeningTool />}
      />
      <Route
        path="documents/screening-tool/:screeningToolId/edit"
        element={<SidepanelScreeningToolEdit />}
      />
      <Route
        path="documents/practice-letter/:practiceLetterId"
        element={<SidepanelPracticeLetter />}
      />
      <Route
        path="documents/practice-letter/:practiceLetterId/edit"
        element={<SidepanelPracticeLetterEdit />}
      />
      <Route
        path="documents/practice-form/:practiceFormId"
        element={<SidepanelPracticeForm />}
      />
      <Route
        path="documents/practice-form/:practiceFormId/edit"
        element={<SidepanelPracticeFormEdit />}
      />
      <Route
        path="documents/practice-form/:practiceFormId/configure-form-schema"
        element={<SidepanelPracticeFormConfigureFormSchema />}
      />
      <Route
        path="documents/patient-education/:patientEducationDocumentId"
        element={<SidepanelPatientEducationDocument />}
      />
      <Route
        path="documents/patient-education/:patientEducationDocumentId/edit"
        element={<SidepanelPatientEducationDocumentEdit />}
      />
      <Route path="documents/create" element={<SidepanelDocumentCreate />} />

      {/* Appointments */}
      <Route path="appointments/new" element={<SidepanelNewAppointment />} />
      <Route
        path="appointments/:appointmentId/edit"
        element={<SidepanelNewAppointment />}
      />
      <Route
        path="appointments/:appointmentId/*"
        element={<SidepanelAppointment />}
      />

      {/* Faxes + contacts */}
      <Route
        path="outbound-fax/:outboundFaxId"
        element={<SidepanelOutboundFax />}
      />
      <Route path="send-fax" element={<SidepanelSendFax />} />
      <Route path="contacts/create" element={<SidepanelContactCreate />} />
      <Route
        path="contacts/:contactId/edit"
        element={<SidepanelContactEdit />}
      />
      <Route
        path="contact-organizations/:contactOrganizationId/edit"
        element={<SidepanelContactOrganizationEdit />}
      />

      {/* Task management */}
      <Route path="task/create" element={<SidepanelCreateCase />} />
      <Route
        path="scheduling-case/status-update/patient/:patientId"
        element={<SidepanelSchedulingCaseStatusUpdate />}
      />
      <Route
        path="scheduling-case/status-update/patientRegistrationIntent/:patientRegistrationIntentId"
        element={<SidepanelSchedulingCaseStatusUpdate />}
      />
      <Route
        path="results-review-case/status-update/:patientId"
        element={<SidepanelResultsReviewCaseStatusUpdate />}
      />
      <Route
        path="results-review-case/:resultsReviewCaseId/resolve/:patientId"
        element={<SidepanelResultsReviewCaseResolution />}
      />
      <Route
        path="diagnostic-results-review-case/:diagnosticResultCaseId/resolve"
        element={<SidepanelDiagnosticReportReviewCaseResolution />}
      />
      <Route
        path="diagnostic-results-review-case/:diagnosticResultCaseId/status-update"
        element={<SidepanelDiagnosticReportReviewCaseResolution />}
      />
      <Route
        path="results-review-case/:resultsReviewCaseId/edit/:patientId"
        element={<SidepanelResultsReviewEditOrders />}
      />
      <Route
        path="scheduling-case/:schedulingCaseId/resolve"
        element={<SidepanelSchedulingCaseResolution />}
      />
      <Route
        path="document-case/:documentCaseId/resolve"
        element={<SidepanelDocumentCaseResolution />}
      />
      <Route
        path="document-case/:documentCaseId/status-update"
        element={<SidepanelDocumentCaseStatusUpdate />}
      />
      <Route
        path="staff-message-case/:staffMessageCaseId/resolve"
        element={<SidepanelStaffMessageCaseResolution />}
      />
      <Route
        path="family-message-case/:familyMessageCaseId/resolve"
        element={<SidepanelFamilyMessageCaseResolution />}
      />
      <Route
        path="screening-case/:screeningCaseId/resolve/:patientId"
        element={<SidepanelScreeningCaseResolution />}
      />

      {/* Patient Immunizations */}
      <Route
        path="vaccine-dose-overview"
        element={<SidepanelVaccineDoseOverview />}
      />
      <Route
        path="add-immunization-history/:patientId"
        element={<SidepanelImmunizationHistoryAdd />}
      />
      <Route
        path="edit-immunization-history/:patientId"
        element={<SidepanelImmunizationHistoryEdit />}
      />
      <Route
        path="print-immunizations/:patientId"
        element={<SidepanelPrintImmunizations />}
      />

      {/* Payer addresses */}
      <Route
        path="create-payer-address/:payerId"
        element={<SidepanelPayerAddressCreate />}
      />

      {/* Vaccine Inventory */}
      <Route
        path="vaccine-inventory/add"
        element={<SidepanelVaccineInventory />}
      />
      <Route
        path="vaccine-inventory/edit"
        element={<SidepanelVaccineInventory />}
      />
      <Route
        path="vaccine-inventory/transfer"
        element={<SidepanelTransferVaccineInventory />}
      />

      {/* Visit note / AVS */}
      <Route
        path="visit/:visitId/print-avs"
        element={<SidepanelVisitPrintAVS />}
      />
      <Route
        path="visit/:visitId/print-visit-note"
        element={<SidepanelVisitPrintVisitNote />}
      />

      {/* Macro phrases */}
      <Route path="macro-phrase" element={<SidepanelMacroPhrase />} />

      {/* Visit templates */}
      <Route
        path="visit-template/:templateId/ROS/:system/edit"
        element={<SidepanelTemplateReviewOfSystemEdit />}
      />
      <Route
        path="visit-template/:templateId/orders/:category/create"
        element={<SidepanelVisitTemplateOrderCreate />}
      />
      <Route
        path="visit-template/:templateId/diagnosis/create"
        element={<SidepanelVisitTemplateDiagnosisCreate />}
      />

      {/* Schedule Configuration */}
      <Route
        path="practice-schedule/override/new"
        element={<SidepanelPracticeScheduleAddOverride />}
      />
      <Route
        path="practice-schedule/override/:referenceId/edit"
        element={<SidepanelPracticeScheduleEditOverride />}
      />
      <Route
        path="practitioner-schedule/override/new"
        element={<SidepanelPractitionerScheduleAddOverride />}
      />
      <Route
        path="practitioner-schedule/override/:referenceId/edit"
        element={<SidepanelPractitionerScheduleEditOverride />}
      />

      {/* Print Patient Demographics */}
      <Route
        path="print-patient-demographics/:patientId"
        element={<SidepanelPrintPatientDemographics />}
      />

      {/* Print Schedule */}
      <Route path="print-schedule" element={<SidepanelPrintSchedule />} />

      {/* Help and support */}
      <Route path="product-guides" element={<SidepanelProductGuide />} />

      {/* Billing statement */}
      <Route
        path="billing-statement/:patientId"
        element={<SidepanelBillingStatement />}
      />

      {/* Charge capture rules */}
      <Route
        path="/charge-capture-rule/:ruleId"
        element={<SidepanelChargeCaptureRuleView />}
      />

      {/* Print growth charts */}
      <Route
        path="print-growth-charts/:patientId/:selectedDataset"
        element={<SidepanelPrintGrowthCharts />}
      />

      {/* Task user pool management */}
      <Route
        path="task-user-pool/new"
        element={<SidepanelTaskUserPoolCreate />}
      />
      <Route
        path="task-user-pool/:id/edit"
        element={<SidepanelTaskUserPoolEdit />}
      />

      {/* Supervising practitioner */}
      <Route
        path="supervising-practitioner-assignment/:locationId/:date"
        element={<SidepanelSupervisingPractitionerAssignment />}
      />

      {/*Vitals outside visit */}
      <Route
        path="results/:patientId/vitals/:date/edit"
        element={<SidepanelPatientVitalsEdit />}
      />
      <Route
        path="results/:patientId/vitals/add"
        element={<SidepanelPatientVitalsAdd />}
      />

      {/* Family portal admin */}
      <Route
        path="/family-portal-account/caregiver/:relatedPersonId"
        element={<SidepanelCaregiverFamilyPortalAccount />}
      />
      <Route
        path="/family-portal-account/patient/:patientId"
        element={<SidepanelPatientFamilyPortalAccount />}
      />
      <Route
        path="/family-portal-account/duplicate-resolution/:contactInformationId"
        element={<SidepanelPortalUserDuplicateResolution />}
      />
      <Route
        path="/family-portal-account/login-details/edit/:contactInformationId"
        element={<SidepanelPortalUserEditLoginDetails />}
      />
      <Route
        path="/family-portal-account/login-details/add/:patientId"
        element={
          <FeatureFlagged flagName="ADD_PORTAL_USER_MOBILE_LOGIN">
            <SidepanelPortalUserAddLoginDetails />
          </FeatureFlagged>
        }
      />
      <Route
        path="/family-portal-account/caregiver/:relatedPersonId/link-patients"
        element={<SidepanelCaregiverLinkPatients />}
      />

      {/* Financial */}
      <Route
        path="/caregiver/:relatedPersonId/financials/:tab?"
        element={<SidepanelCaregiverFinancials />}
      />
      <Route
        path="/patient/:patientId/financials/:tab?"
        element={<SidepanelPatientFinancials />}
      />
    </Routes>
  )
}

type RouteBasedSidepanelProps = {
  isPortal?: never
  isPortalOpen?: never
  portalContext?: never
  children?: never
}

type PortalBasedSidepanelProps = {
  isPortal: true
  isPortalOpen: boolean
  portalContext?: SidepanelPortalContext
  children: ReactElement
}

type SidepanelLayoutInternalProps = {
  isSidepanelOpen: boolean
  closeSidePanel: () => void
  sidepanelContext: SidepanelRouteContext | SidepanelPortalContext
  children: ReactElement
}

const SidepanelLayoutInternal = ({
  isSidepanelOpen,
  closeSidePanel,
  sidepanelContext,
  children,
}: SidepanelLayoutInternalProps) => {
  const { width, overlay } = sidepanelContext

  return (
    <Transition
      show={isSidepanelOpen}
      as={Fragment}
      enter="transition ease-in-out duration-300 transform"
      enterFrom="translate-x-full"
      enterTo="-translate-x-0"
    >
      <aside
        className={clsx(
          'border-base-border-subtle relative flex h-full border-l bg-base-color-bg-default',
          width === 'large' ? 'w-full' : width === 'medium' ? 'w-2/3' : 'w-1/3',
          overlay ? '!absolute right-0 top-0 z-50' : ''
        )}
        data-testid="side-panel"
        id="side-panel"
      >
        <section className="flex flex-1 flex-col">
          <header className="bg-base-color-bg-default px-core-space-150 pt-core-space-100">
            <StackView direction="row" alignItems="center" justifyContent="end">
              <Button
                accessibilityText="Close sidepanel"
                buttonStyle="ghost"
                data-testid="toggle-side-panel"
                onClick={closeSidePanel}
                icon={XMarkIcon}
              />
            </StackView>
          </header>
          <Box className="flex-1 overflow-y-auto overflow-x-hidden">
            {children}
          </Box>
        </section>
      </aside>
    </Transition>
  )
}

const SidepanelLayout: React.FC<
  RouteBasedSidepanelProps | PortalBasedSidepanelProps
> = ({ isPortal = false, isPortalOpen = false, portalContext, children }) => {
  const { isSidepanelOpen, closeSidePanel, sidepanelContext } = useSidepanel()

  if (isPortal) {
    return (
      <SidepanelLayoutInternal
        isSidepanelOpen={isPortalOpen}
        closeSidePanel={closeSidePanel}
        sidepanelContext={portalContext}
      >
        {children}
      </SidepanelLayoutInternal>
    )
  }

  return (
    <SidepanelLayoutInternal
      isSidepanelOpen={isSidepanelOpen}
      closeSidePanel={closeSidePanel}
      sidepanelContext={sidepanelContext}
    >
      <SidepanelRoutes />
    </SidepanelLayoutInternal>
  )
}

export default SidepanelLayout
