import { useState } from 'react'

import { ArchiveBoxXMarkIcon } from '@heroicons/react/20/solid'

import Button from 'src/components/atoms/Button'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import Modal from 'src/components/molecules/Modal'

interface DestructiveActionModelProps {
  title: string
  content: string
  confirmText: string
  hideIcon?: boolean
  testId?: string
}

interface CommonProps {
  title: string
  description: string
  buttonText: string
  buttonIcon?: React.FunctionComponent<React.ComponentProps<'svg'>>
  doDestructiveAction: () => Promise<void>
  postDestructiveAction?: () => void
  destructiveActionIsLoading: boolean
}

interface DestructiveActionProps extends CommonProps {
  modal?: DestructiveActionModelProps
}

const DestructiveAction: React.FC<DestructiveActionProps> = ({
  modal,
  ...commonProps
}) => {
  if (modal) {
    return <DestructiveActionWithModal {...commonProps} modal={modal} />
  } else {
    return <DestructiveActionWithoutModal {...commonProps} />
  }
}

const DestructiveActionWithoutModal: React.FC<CommonProps> = ({
  title,
  description,
  buttonText,
  buttonIcon: DeleteButtonIcon,
  doDestructiveAction,
  postDestructiveAction,
  destructiveActionIsLoading,
}) => {
  return (
    <StackView space={50} className="py-4">
      <Typography textStyle="title">{title}</Typography>
      <Typography textStyle="description">{description}</Typography>
      <Button
        icon={DeleteButtonIcon ?? ArchiveBoxXMarkIcon}
        text={buttonText}
        buttonStyle="secondary"
        loading={destructiveActionIsLoading}
        disabled={destructiveActionIsLoading}
        onClick={async () => {
          await doDestructiveAction()
          postDestructiveAction?.()
        }}
      />
    </StackView>
  )
}

const DestructiveActionWithModal: React.FC<
  CommonProps & {
    modal: DestructiveActionModelProps
  }
> = ({
  title,
  description,
  buttonText,
  buttonIcon: DeleteButtonIcon,
  modal: {
    title: modalTitle,
    content: modalContent,
    confirmText: modalConfirmText,
    hideIcon: modalHideIcon = false,
    testId: modalTestId,
  },
  doDestructiveAction,
  postDestructiveAction,
  destructiveActionIsLoading,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  return (
    <>
      <StackView space={100} className="py-core-space-150">
        <StackView space={25}>
          <Typography textStyle="title-xs">{title}</Typography>
          <Typography textStyle="body-s" color="text-base-color-fg-muted">
            {description}
          </Typography>
        </StackView>
        <Button
          icon={DeleteButtonIcon ?? ArchiveBoxXMarkIcon}
          text={buttonText}
          buttonStyle="secondary"
          onClick={() => setModalIsOpen(true)}
          disabled={destructiveActionIsLoading}
          loading={destructiveActionIsLoading}
        />
      </StackView>
      <Modal
        title={modalTitle}
        content={modalContent}
        confirmText={modalConfirmText}
        onConfirm={async () => {
          await doDestructiveAction()
          setModalIsOpen(false)
          postDestructiveAction?.()
        }}
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        hideIcon={modalHideIcon}
        testId={modalTestId}
      />
    </>
  )
}

export default DestructiveAction
