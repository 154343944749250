import {
  DocumentTextIcon,
  BuildingOfficeIcon,
  UserCircleIcon,
} from '@heroicons/react/20/solid'
import compact from 'lodash/compact'

import { routes } from '@redwoodjs/router'

import { useEmrAuth } from 'src/auth'

import {
  primaryLayoutRequiredUserRoles,
  determineRouteAccessAllowed,
} from '../PrimaryLayout/PrimaryLayout'
import SecondaryLayout, {
  GroupedNavConfig,
} from '../SecondaryLayout/SecondaryLayout'

interface SettingsLayoutProps {
  children?: React.ReactNode
}

export default function SettingsLayout({ children }: SettingsLayoutProps) {
  const { logOut, currentUser } = useEmrAuth()

  const navigationConfig: GroupedNavConfig[] = [
    {
      name: 'Practice',
      icon: BuildingOfficeIcon,
      items: compact([
        { name: 'Staff members', to: routes.staff() },
        {
          name: 'Clinic schedule',
          to: routes.practiceSchedule(),
        },
        {
          name: 'Practitioner schedule',
          to: routes.practitionerSchedule(),
        },
        { name: 'Visit types', to: routes.practiceVisitTypes() },
        currentUser.vaccineManagementServiceProvider === 'DEVELO'
          ? {
              name: 'Vaccine inventory',
              to: routes.vaccineInventoryManagement(),
            }
          : undefined,
        {
          name: 'Templates',
          to: routes.visitTemplates(),
        },
        {
          name: 'Macro phrases',
          to: routes.macroPhrases(),
        },
        {
          name: 'Charge capture',
          to: routes.chargeCapture(),
        },
        {
          name: 'Tasks',
          to: routes.taskSettings(),
        },
        currentUser.featureFlags.includes('PATIENT_PORTAL')
          ? {
              name: 'Family portal admin',
              to: routes.familyPortalAdmin({ userType: 'caregiver' }),
            }
          : undefined,
      ]),
    },
    {
      name: 'Documents',
      icon: DocumentTextIcon,
      items: [
        { name: 'Resources', to: routes.resources() },
        {
          name: 'Contacts',
          to: routes.contacts(),
        },
        {
          name: 'Outbound Efax',
          to: routes.outboundFax(),
        },
      ],
    },
    {
      name: 'Account',
      icon: UserCircleIcon,
      items: compact([
        { name: 'Profile', to: routes.profile() },
        currentUser.roles.includes('PRACTITIONER')
          ? {
              name: 'Weekly schedule',
              to: routes.mdWeeklySchedule(),
            }
          : undefined,
        {
          name: 'Logout',
          onClick: logOut,
          to: '/',
        },
      ]),
    },
  ]
    .filter((route) => {
      return determineRouteAccessAllowed({
        lookup: primaryLayoutRequiredUserRoles,
        route: route.name,
        roles: currentUser.roles,
      })
    })
    .map((route) => {
      return {
        ...route,
        items: route.items.filter((item) =>
          determineRouteAccessAllowed({
            lookup: primaryLayoutRequiredUserRoles,
            route: item.name,
            roles: currentUser.roles,
          })
        ),
      }
    })

  return (
    <SecondaryLayout
      navigationConfig={navigationConfig}
      data-testid="settings-layout"
    >
      {children}
    </SecondaryLayout>
  )
}
