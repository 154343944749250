import { useRef, useMemo } from 'react'

import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { temperatureTypeCodeToEnumMap } from 'common/cdr/concepts/observations/vitals/index'
import {
  evaluate,
  extractValue,
  isSplitUnit,
} from 'common/unitConverter/unitConverter'
import { format, parseISO } from 'date-fns'
import groupBy from 'lodash/groupBy'
import isUndefined from 'lodash/isUndefined'
import orderBy from 'lodash/orderBy'
import { FindPatientVitals } from 'types/graphql'

import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import Table from 'src/components/molecules/Table/Table'
import { toDecimal } from 'src/lib/formatters'

import { useVitalsQuery } from './useVitals'

type VitalTableRow = {
  name: string
  unit: string
  dataByDate: {
    [key: string]: string
  }
  type: string
}

const defaultColDef: ColDef = {
  minWidth: 200,
}

const nameColDef: ColDef = {
  colId: 'name',
  pinned: 'left',
  cellStyle: { display: 'inline-block' },
  cellClass: 'bg-gray-100',
  minWidth: 280,
  maxWidth: 400,
  autoHeight: true,
  cellRenderer: ({ data }: ICellRendererParams<VitalTableRow>) => (
    <StackView className="whitespace-pre-line py-2" justifyContent="center">
      <StackView
        direction="row"
        justifyContent="center"
        alignItems="center"
        className="flex-grow"
        space={100}
      >
        <Typography fontWeight="semibold" className="flex-1">
          {data.name}
        </Typography>
        <Typography textStyle="description">{data.unit}</Typography>
      </StackView>
    </StackView>
  ),
}

const VitalCellRenderer = (params: ICellRendererParams<VitalTableRow>) => {
  const key = params.colDef.colId

  return (
    <StackView justifyContent="center">
      <Typography>{params.data.dataByDate[key] || '-'}</Typography>
    </StackView>
  )
}

export const transformVitals = (
  vitals: FindPatientVitals['patient']['vitals']
) => {
  return Object.values(
    orderBy(vitals, (vital) => vital.updatedAt, ['desc']).reduce(
      (vitals, vital) => {
        const name = vital.name
        if (!vitals[name]) {
          vitals[name] = {
            effectiveAt: vital.effectiveAt,
            name: vital.name,
            unit:
              vital.value.unit ||
              vital.components?.map((c) => c.value.unit)?.join(' / '),
            dataByDate: {},
            type: temperatureTypeCodeToEnumMap[vital.coding[1]?.code],
          }
        }
        const date = format(parseISO(vital.effectiveAt), 'yyyy-MM-dd')
        if (vital.value.unit) {
          if (isSplitUnit(vital.value.display)) {
            vitals[name].dataByDate[date] = vital.value.display
          } else if (
            vital.value.unit === vitals[name].unit ||
            vital.value.unit === '{Breaths}/min' || // because evaluate does not handle {} chars well
            vital.value.unit === '{Beats}/min' ||
            vital.value.unit === '/min' // used in our seed data, don't expect data to originate from Develo with this unit
          ) {
            // if the unit is the same as the one in the row header, use the value
            vitals[name].dataByDate[date] = toDecimal(vital.value.value, 2)
          } else {
            // otherwise convert so that the values are consistent
            vitals[name].dataByDate[date] = extractValue(
              evaluate(vital.value.value, vital.value.unit, vitals[name].unit),
              2
            )
          }
        } else {
          // Otherwise, just use the server-generated display value (usually for components)
          vitals[name].dataByDate[date] = vital.value.display
        }

        return vitals
      },
      {}
    ) as { [key: string]: VitalTableRow & { effectiveAt: Date } }
  ).sort((a, b) => a.name.localeCompare(b.name))
}

const buildColDefs = (vitals: FindPatientVitals['patient']['vitals']) => {
  const columnDataKeys = Object.keys(
    groupBy(vitals, (vital) =>
      format(parseISO(vital.effectiveAt), 'yyyy-MM-dd')
    )
  )
    .sort((a, b) => {
      return a.localeCompare(b)
    })
    .reverse()

  return [
    nameColDef,
    ...columnDataKeys.map((key) => {
      return {
        colId: key,
        headerName: format(parseISO(key), 'MMM dd, yyyy'),
        cellRenderer: VitalCellRenderer,
      }
    }),
  ]
}

const VitalsTable = ({ patientId }: { patientId: string }) => {
  const gridRef = useRef<AgGridReact>()
  const { vitals } = useVitalsQuery(patientId)

  const { vitalsByDateAndCode, colDefs } = useMemo(() => {
    if (isUndefined(vitals)) return {}
    return {
      vitalsByDateAndCode: transformVitals(vitals),
      colDefs: buildColDefs(vitals),
    }
  }, [vitals])

  return (
    <Table
      testId="vitals-table"
      innerRef={gridRef}
      rowData={vitalsByDateAndCode}
      onRowDataUpdated={() => gridRef.current?.api?.sizeColumnsToFit()}
      domLayout="autoHeight"
      headerHeight={36}
      defaultColDef={defaultColDef}
      columnDefs={colDefs}
      animateRows={true}
      noRowsOverlayComponentParams={{ message: 'No vitals recorded' }}
      pagination={false}
    />
  )
}

export default VitalsTable
