import { useRef } from 'react'

import { ExclamationCircleIcon } from '@heroicons/react/24/solid'
import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { chiefComplaintDisplay } from 'common/data/chiefComplaints'
import { paymentLineItemTypesDisplay } from 'common/data/paymentLineItemTypes'
import { formatMoneyInCents } from 'common/utils'
import { format, compareDesc, parseISO } from 'date-fns'
import capitalize from 'lodash/capitalize'
import compact from 'lodash/compact'
import { PatientPayments, PaymentLineItemType } from 'types/graphql'

import { navigate, useLocation, useParams } from '@redwoodjs/router'

import Badge, { BadgeColor } from 'src/components/atoms/Badge'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import NoPaymentIcon from 'src/components/illustrations/NoPaymentIcon'
import Alert from 'src/components/molecules/Alert/Alert'
import Table from 'src/components/molecules/Table/Table'
import { usePatientPaymentsQuery } from 'src/hooks/usePatientPayments/usePatientPayments'
import { formatDateDisplay } from 'src/lib/formatters'
import { sidepanelRoute } from 'src/lib/routes'

export const PAYMENT_TYPES: {
  [key in string]: { color: BadgeColor; label: string }
} = {
  PAYMENT: { color: 'purple', label: 'Payment' },
  REFUND: { color: 'orange', label: 'Refund' },
  NET_ZERO: { color: 'light-gray', label: 'Net zero' },
}

export const PAYMENT_STATUS: {
  [key in string]: { color: BadgeColor; label: string }
} = {
  SUCCESSFUL: { color: 'green', label: 'Successful' },
  FAILED: { color: 'red', label: 'Failed' },
  PRE_APPROVED: { color: 'light-gray', label: 'Pre-approved' },
}

const CustomNoRowsOverlay = () => {
  return (
    <StackView
      justifyContent="center"
      alignItems="center"
      space={150}
      className="w-core-space-2000"
    >
      <NoPaymentIcon />
      <StackView>
        <Typography textStyle="title-xs">No recorded payments</Typography>
        <Typography textStyle="body-s" color="text-base-color-fg-muted">
          There are no recorded payments for this patient.
        </Typography>
      </StackView>
    </StackView>
  )
}

export const getPaymentNoteName = (
  payment: PatientPayments['patientPayments'][number]
) => {
  if (!payment) return

  if (payment.paymentLineItemType === 'PAYMENT_PLAN') {
    return 'Payment plan payment'
  }

  if (!payment.appointment && !payment.directPatientChargeEncounter)
    return `Payment made on ${formatDateDisplay(payment.paidAt)}`

  if (payment.appointment) {
    return `${payment.appointment.appointmentDefinitions[0].name}: ${capitalize(
      payment.appointment.chiefComplaints
        .map((complaint) => chiefComplaintDisplay[complaint]?.toLowerCase())
        .join(', ')
    )} on ${formatDateDisplay(payment.appointment.start)}`
  }

  if (payment.directPatientChargeEncounter) {
    return payment.directPatientChargeEncounter.directPatientChargeBillingCode
      .description
  }
}

export const getPaymentNoteDescription = (
  payment: PatientPayments['patientPayments'][number]
) => {
  if (!payment) return

  return compact([
    format(new Date(payment.paidAt), 'PP'),
    payment.paymentLineItemType !== 'PAYMENT_PLAN' &&
      paymentLineItemTypesDisplay[payment.paymentLineItemType],
    payment.note in paymentLineItemTypesDisplay
      ? 'Cash or check'
      : payment.note,
  ]).join(' · ')
}

const defaultColDef: ColDef = {
  cellStyle: { border: 'none', display: 'flex', alignItems: 'center' },
  cellClass: 'cursor-pointer',
  resizable: true,
}

const columnDefs: ColDef[] = [
  {
    colId: 'note',
    headerName: 'Payment note',
    cellRenderer: ({ data }) => {
      if (!data) return
      return (
        <StackView space={25}>
          <StackView direction="row" alignItems="center" space={50}>
            <Typography
              textStyle="interface-strong-s"
              color="text-base-color-fg-muted"
              className="whitespace-normal"
            >
              {getPaymentNoteName(data)}
            </Typography>

            {!data.appointment &&
              !data.directPatientChargeEncounter &&
              data.amountCents >= 0 &&
              data.paymentLineItemType !== 'PAYMENT_PLAN' && (
                <ExclamationCircleIcon className="h-base-size-icon-xs w-base-size-icon-xs fill-base-color-fg-danger" />
              )}
          </StackView>

          <Typography
            textStyle="interface-default-xs"
            color="text-base-color-fg-subtle"
          >
            {getPaymentNoteDescription(data)}
          </Typography>
        </StackView>
      )
    },
    autoHeight: true,
  },
  {
    colId: 'amount',
    headerName: 'Amount',
    cellRenderer: ({ data }) => {
      if (!data) return
      return (
        <Typography
          textStyle="interface-default-s"
          color="text-base-color-fg-muted"
        >
          {formatMoneyInCents(data.amountCents)}
        </Typography>
      )
    },
    autoHeight: true,
    maxWidth: 100,
  },
  {
    colId: 'status',
    headerName: 'Status',
    cellRenderer: ({ data }) => {
      if (!data) return
      const paymentType =
        data.amountCents > 0
          ? 'PAYMENT'
          : data.amountCents === 0
          ? 'NET_ZERO'
          : 'REFUND'

      return (
        <StackView space={25} direction="row">
          <Badge
            testId="payment-type-badge"
            color={PAYMENT_TYPES[paymentType].color}
            text={PAYMENT_TYPES[paymentType].label}
          />
          <Badge
            testId="payment-status-badge"
            color={PAYMENT_STATUS[data.status].color}
            text={PAYMENT_STATUS[data.status].label}
            showDot
          />
        </StackView>
      )
    },
    autoHeight: true,
    maxWidth: 220,
  },
]

const HistoricalPatientPaymentsTable = ({
  patientId,
}: {
  patientId: string
}) => {
  const location = useLocation()
  const params = useParams()
  const gridRef = useRef<AgGridReact>()
  const { candidPayments, candidRefunds, paymentPlanPayments, loading } =
    usePatientPaymentsQuery(patientId)

  if (loading) return <LoadingSpinner />

  const filteredCandidPayments =
    candidPayments
      ?.filter((payment) => payment.paymentLineItemType !== 'PAYMENT_PLAN')
      .map((payment) => ({
        ...payment,
        status: 'SUCCESSFUL',
      })) ?? []

  const orphanedCandidPayments =
    filteredCandidPayments.filter(
      (payment) => !payment.appointment && !payment.directPatientChargeEncounter
    ) ?? []

  const updatedRefunds = candidRefunds?.map((refund) => ({
    id: refund.id,
    note: refund.note,
    amountCents: -refund.amountCents,
    paidAt: refund.refundedAt,
    appointment: null,
    paymentLineItemType: refund.paymentLineItemType,
    status: 'SUCCESSFUL',
  }))

  const updatedPaymentPlanPayments = paymentPlanPayments?.map((payment) => ({
    id: payment.id,
    amountCents: payment.amountCents,
    paidAt: payment.createdAt,
    appointment: null,
    paymentLineItemType: 'PAYMENT_PLAN' as PaymentLineItemType,
    status: payment.status,
  }))

  const paymentsWithRefunds = filteredCandidPayments
    ?.concat(updatedRefunds)
    .concat(updatedPaymentPlanPayments)
    .sort((a, b) => compareDesc(parseISO(a.paidAt), parseISO(b.paidAt)))

  return (
    <>
      {orphanedCandidPayments.length > 0 && (
        <Alert
          style="danger"
          title="One or more recorded payments are not linked to a visit. Please link the below payment(s) to their corresponding visit(s) so the outstanding balance is properly displayed."
          description={orphanedCandidPayments
            .map(
              (payment) =>
                `• Payment ${formatMoneyInCents(
                  payment.amountCents
                )} made on ${format(new Date(payment.paidAt), 'MM/dd/yyyy')}`
            )
            .join('\n')}
        />
      )}

      <Table
        testId="patient-payments-table"
        innerRef={gridRef}
        rowData={paymentsWithRefunds}
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        noRowsOverlayComponentParams={{ message: 'No payments recorded' }}
        domLayout="autoHeight"
        rowHeight={72}
        hideHeader
        context={{
          patientId,
        }}
        noRowsOverlayComponent={CustomNoRowsOverlay}
        noRowsMinHeight={300}
        onRowClicked={({ data }) => {
          if (data.amountCents < 0) return
          navigate(
            sidepanelRoute(
              {
                route:
                  data.paymentLineItemType === 'PAYMENT_PLAN'
                    ? `/patients/${patientId}/payment-plan-payments/${data.id}`
                    : `/patients/${patientId}/payments/${data.id}`,
              },
              location,
              params
            )
          )
        }}
      />
    </>
  )
}

export default HistoricalPatientPaymentsTable
