import { Form, FormProps } from '@redwoodjs/forms'

import Box from 'src/components/atoms/Box/Box'
import StackView from 'src/components/atoms/StackView/StackView'
import SidepanelButtonFooter, {
  SidepanelButtonFooterProps,
} from 'src/components/molecules/SidepanelButtonFooter/SidepanelButtonFooter'

type SidepanelForm<T> = FormProps<T> &
  (
    | {
        footerProps: SidepanelButtonFooterProps
        footerElement?: never
      }
    | {
        footerProps?: never
        footerElement: React.JSX.Element
      }
  ) & { divider?: boolean }

/**
 * SidepanelForm renders a redwoodjs form with its submit and cancel buttons
 * in a footer. This is meant to give all forms in the sidepanel a consistent look
 * and feel across the app.
 */
function SidepanelForm<T>({
  children,
  footerProps,
  footerElement,
  divider = true,
  ...formProps
}: React.PropsWithChildren<SidepanelForm<T>>) {
  return (
    <Form<T>
      data-testid="sidepanel-form"
      className="flex flex-1 flex-col overflow-y-auto"
      {...formProps}
    >
      <StackView className="flex-1 overflow-y-auto px-6" divider={divider}>
        {children}
      </StackView>
      <Box
        verticalPadding={100}
        horizontalPadding={150}
        className="border-0 border-t-base-border-width-container-s border-base-color-border-subtle"
      >
        {footerProps ? (
          <SidepanelButtonFooter
            submitText={footerProps.submitText}
            disabled={footerProps.disabled}
            submitting={footerProps.submitting}
            onCancel={footerProps.onCancel}
            cancelText={footerProps.cancelText}
            submitButtonStyle={footerProps.submitButtonStyle}
          />
        ) : (
          footerElement
        )}
      </Box>
    </Form>
  )
}

export default SidepanelForm
