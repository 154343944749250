import { getPatientStatus, patientStatusDisplay } from 'common/data/patient'
import { match } from 'ts-pattern'
import { PatientStatus } from 'types/graphql'

import Badge, { BadgeColor } from 'src/components/atoms/Badge/Badge'

export const PatientStatusBadge = ({
  patient,
  showActive,
}: {
  patient: { active: boolean; deceased: boolean; inCollections: boolean }
  showActive?: boolean
}) => {
  const status = getPatientStatus(patient)

  if (status === 'ACTIVE' && !showActive) return null

  return (
    <Badge
      text={patientStatusDisplay[status]}
      color={match<PatientStatus, BadgeColor>(status)
        .with('DECEASED', () => 'yellow')
        .with('INACTIVE', () => 'red')
        .with('COLLECTIONS', () => 'orange')
        .with('ACTIVE', () => 'dark-gray')
        .exhaustive()}
      showDot
    />
  )
}
