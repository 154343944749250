import { useState } from 'react'

import {
  convertParagraphToLexical,
  isEmptyLexical,
} from 'common/lexical/lexical'
import { renderToString } from 'react-dom/server'
import {
  FindPatientVisit,
  SignVisitV2,
  SignVisitV2Variables,
} from 'types/graphql'

import { navigate } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/dist/toast'

import { lexicalToHTML } from 'src/components/atoms/WysiwygField/utils/utils'
import { organSystems } from 'src/data/organSystems'
import PrintVisitAVSDocument from 'src/pdf/components/PrintVisitAVSDocument'
import PrintVisitNoteDocument from 'src/pdf/components/PrintVisitNoteDocument'
import { useGeneratePDF } from 'src/pdf/useGeneratePDF'
import { convertImageSrcToBase64 } from 'src/utils'

export type HtmlStrings = {
  attestation?: string
  historyPresentIllness?: string
  developmentalSurveillance?: string
  assessment?: string
  planOfCare?: string
  patientEducationAdditionalNotes?: string
  patientEducationAdolescentConfidential?: string
  documentationAdolescentConfidential?: string
  pastMedicalHistory?: string
  physicalExam?: {
    CON?: string
    HEAD?: string
    EYES?: string
    EARS?: string
    NOSE?: string
    MOUTH?: string
    THROAT?: string
    NECK?: string
    PULM?: string
    CV?: string
    GI?: string
    GU?: string
    MSK?: string
    DERM?: string
    NEURO?: string
    PSYCH?: string
    HLI?: string
    ENDO?: string
  }
  appointmentSignatures: {
    [key: string]: {
      attestationHTML?: string
    }
  }
}

export const containsAdolescentConfidentialInformation = ({
  patientEducationAdolescentConfidential,
  documentationAdolescentConfidential,
}: {
  patientEducationAdolescentConfidential?: string
  documentationAdolescentConfidential?: string
}): boolean => {
  return !!(
    (patientEducationAdolescentConfidential &&
      !isEmptyLexical(patientEducationAdolescentConfidential)) ||
    (documentationAdolescentConfidential &&
      !isEmptyLexical(documentationAdolescentConfidential))
  )
}

const SIGN_VISIT_V2_MUTATION = gql`
  mutation SignVisitV2(
    $visitId: String!
    $visitNoteWithoutAdolescentConfidentialInfoHtml: String!
    $visitNoteWithAdolescentConfidentialInfoHtml: String
    $avsWithoutAdolescentConfidentialInfoHtml: String!
    $avsWithAdolescentConfidentialInfoHtml: String
  ) {
    signVisitV2(
      visitId: $visitId
      visitNoteWithoutAdolescentConfidentialInfoHtml: $visitNoteWithoutAdolescentConfidentialInfoHtml
      visitNoteWithAdolescentConfidentialInfoHtml: $visitNoteWithAdolescentConfidentialInfoHtml
      avsWithoutAdolescentConfidentialInfoHtml: $avsWithoutAdolescentConfidentialInfoHtml
      avsWithAdolescentConfidentialInfoHtml: $avsWithAdolescentConfidentialInfoHtml
    ) {
      id
      attestation
      appointmentSignatures {
        id
        attestation
        updatedAt
        signedBy {
          id
        }
      }
    }
  }
`

const useSignVisitV2Mutation = () => {
  return useMutation<SignVisitV2, SignVisitV2Variables>(
    SIGN_VISIT_V2_MUTATION,
    {
      onCompleted: (result) => {
        toast.success(
          result.signVisitV2?.appointmentSignatures?.length > 1
            ? 'Visit addended'
            : 'Visit signed'
        )
        navigate(`/schedule`)
      },
    }
  )
}

const reactToHtmlForServerSidePdfGeneration = async (
  el: React.ReactNode
): Promise<string> => {
  const placeholder = document.createElement('div')
  placeholder.innerHTML = renderToString(el)

  const images = Array.from(placeholder.getElementsByTagName('img'))
  await Promise.all(
    images.map(async (img) => {
      const newSrc = await convertImageSrcToBase64(img.src)

      img.src = newSrc
    })
  )

  const contentHtml = placeholder.innerHTML

  const [head] = Array.from(document.getElementsByTagName('head'))

  const result = `<html>
      ${head.outerHTML}
      <body>${contentHtml}</body>
    </html>`

  return result
}

export const generateVisitNote = (
  visit: FindPatientVisit['appointment'],
  currentPractitioner: FindPatientVisit['practitioner'],
  opts?: { hideAdolescentConfidentialInfo?: boolean }
) => {
  const hideAdolescentConfidentialInfo = opts?.hideAdolescentConfidentialInfo
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const generatePDF = useGeneratePDF()
  return new Promise<string>((resolve) => {
    void generateHTMLStringsForVisit(visit).then((htmlStrings) => {
      void generatePDF({
        component: (
          <PrintVisitNoteDocument
            visit={visit}
            currentPractitioner={currentPractitioner}
            htmlStrings={htmlStrings}
            hideAdolescentConfidentialInformation={
              hideAdolescentConfidentialInfo
            }
          />
        ),
        callback: (doc) => {
          resolve(doc.output('datauristring'))
        },
      })
    })
  })
}

export const generateAvs = (
  visit: FindPatientVisit['appointment'],
  currentPractitioner: FindPatientVisit['practitioner'],
  opts?: { hideAdolescentConfidentialInfo?: boolean }
) => {
  const hideAdolescentConfidentialInfo = opts?.hideAdolescentConfidentialInfo
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const generatePDF = useGeneratePDF()
  return new Promise<string>((resolve) => {
    void generateHTMLStringsForVisit(visit).then((htmlStrings) => {
      void generatePDF({
        component: (
          <PrintVisitAVSDocument
            visit={visit}
            currentPractitioner={currentPractitioner}
            htmlStrings={htmlStrings}
            hideAdolescentConfidentialInformation={
              hideAdolescentConfidentialInfo
            }
          />
        ),
        callback: (doc) => {
          resolve(doc.output('datauristring'))
        },
      })
    })
  })
}

export const generateVisitNoteHtml = async (
  visit: FindPatientVisit['appointment'],
  currentPractitioner: FindPatientVisit['practitioner'],
  opts?: { hideAdolescentConfidentialInfo?: boolean }
) => {
  const hideAdolescentConfidentialInfo = opts?.hideAdolescentConfidentialInfo

  const htmlStrings = await generateHTMLStringsForVisit(visit)

  const html = await reactToHtmlForServerSidePdfGeneration(
    <PrintVisitNoteDocument
      visit={visit}
      currentPractitioner={currentPractitioner}
      htmlStrings={htmlStrings}
      hideAdolescentConfidentialInformation={hideAdolescentConfidentialInfo}
    />
  )

  return html
}

export const generateAvsHtml = async (
  visit: FindPatientVisit['appointment'],
  currentPractitioner: FindPatientVisit['practitioner'],
  opts?: { hideAdolescentConfidentialInfo?: boolean }
) => {
  const hideAdolescentConfidentialInfo = opts?.hideAdolescentConfidentialInfo

  const htmlStrings = await generateHTMLStringsForVisit(visit)

  const html = await reactToHtmlForServerSidePdfGeneration(
    <PrintVisitAVSDocument
      visit={visit}
      currentPractitioner={currentPractitioner}
      htmlStrings={htmlStrings}
      hideAdolescentConfidentialInformation={hideAdolescentConfidentialInfo}
    />
  )

  return html
}

const useGenerateSignVisitDocuments = (): ((
  visit: FindPatientVisit['appointment'],
  currentPractitioner: FindPatientVisit['practitioner']
) => Promise<{
  visitNoteWithoutAdolescentConfidentialInfoHtml: string
  visitNoteWithAdolescentConfidentialInfoHtml?: string
  avsWithoutAdolescentConfidentialInfoHtml: string
  avsWithAdolescentConfidentialInfoHtml?: string
}>) => {
  return async (visit, currentPractitioner) => {
    const htmlStrings = await generateHTMLStringsForVisit(visit)

    const hasAdolescentConfidentialInfo =
      containsAdolescentConfidentialInformation(visit)

    const documentsToGenerate = [
      <PrintVisitNoteDocument
        key="visitNoteWithoutAdolescentConfidentialInfo"
        visit={visit}
        currentPractitioner={currentPractitioner}
        htmlStrings={htmlStrings}
        hideAdolescentConfidentialInformation
      />,
      hasAdolescentConfidentialInfo ? (
        <PrintVisitNoteDocument
          key="visitNoteWithAdolescentConfidentialInfo"
          visit={visit}
          currentPractitioner={currentPractitioner}
          htmlStrings={htmlStrings}
          hideAdolescentConfidentialInformation={false}
        />
      ) : null,
      <PrintVisitAVSDocument
        key="avsWithoutAdolescentConfidentialInfo"
        visit={visit}
        currentPractitioner={currentPractitioner}
        htmlStrings={htmlStrings}
        hideAdolescentConfidentialInformation
      />,
      hasAdolescentConfidentialInfo ? (
        <PrintVisitAVSDocument
          key="avsWithAdolescentConfidentialInfo"
          visit={visit}
          currentPractitioner={currentPractitioner}
          htmlStrings={htmlStrings}
          hideAdolescentConfidentialInformation={false}
        />
      ) : null,
    ]

    const [
      visitNoteWithoutAdolescentConfidentialInfoHtml,
      visitNoteWithAdolescentConfidentialInfoHtml,
      avsWithoutAdolescentConfidentialInfoHtml,
      avsWithAdolescentConfidentialInfoHtml,
    ] = await Promise.all(
      documentsToGenerate.map((document) =>
        document ? reactToHtmlForServerSidePdfGeneration(document) : null
      )
    )

    return {
      visitNoteWithoutAdolescentConfidentialInfoHtml,
      visitNoteWithAdolescentConfidentialInfoHtml,
      avsWithoutAdolescentConfidentialInfoHtml,
      avsWithAdolescentConfidentialInfoHtml,
    }
  }
}

export const useSignVisitV2 = () => {
  const [signVisitV2, { loading: signingVisitV2 }] = useSignVisitV2Mutation()
  const generateSignVisitDocuments = useGenerateSignVisitDocuments()
  const [isGeneratingDocuments, setIsGeneratingDocuments] = useState(false)

  const signVisit = async ({
    visit,
    currentPractitioner,
  }: {
    visit: FindPatientVisit['appointment']
    currentPractitioner: FindPatientVisit['practitioner']
  }) => {
    setIsGeneratingDocuments(true)
    const {
      visitNoteWithoutAdolescentConfidentialInfoHtml,
      visitNoteWithAdolescentConfidentialInfoHtml,
      avsWithoutAdolescentConfidentialInfoHtml,
      avsWithAdolescentConfidentialInfoHtml,
    } = await generateSignVisitDocuments(visit, currentPractitioner)
    setIsGeneratingDocuments(false)

    void signVisitV2({
      variables: {
        visitId: visit.id,
        visitNoteWithoutAdolescentConfidentialInfoHtml,
        visitNoteWithAdolescentConfidentialInfoHtml,
        avsWithoutAdolescentConfidentialInfoHtml,
        avsWithAdolescentConfidentialInfoHtml,
      },
    })
  }

  return [
    signVisit,
    {
      loading: signingVisitV2 || isGeneratingDocuments,
    },
  ] as const
}

export const generateHTMLStringsForVisit = (
  visit: FindPatientVisit['appointment']
) => {
  const physicalExam = visit?.physicalExam ?? []

  return new Promise<HtmlStrings>((resolve) => {
    void Promise.all([
      lexicalToHTML(convertParagraphToLexical(visit.attestation)),
      lexicalToHTML(convertParagraphToLexical(visit.historyPresentIllness)),
      lexicalToHTML(convertParagraphToLexical(visit.developmentalSurveillance)),
      lexicalToHTML(convertParagraphToLexical(visit.assessment)),
      lexicalToHTML(convertParagraphToLexical(visit.planOfCare)),
      lexicalToHTML(
        convertParagraphToLexical(visit.patientEducationAdditionalNotes)
      ),
      !isEmptyLexical(visit.patientEducationAdolescentConfidential)
        ? lexicalToHTML(
            convertParagraphToLexical(
              visit.patientEducationAdolescentConfidential
            )
          )
        : null,
      !isEmptyLexical(visit.documentationAdolescentConfidential)
        ? lexicalToHTML(
            convertParagraphToLexical(visit.documentationAdolescentConfidential)
          )
        : null,
      lexicalToHTML(
        convertParagraphToLexical(visit.patient.pastMedicalHistory)
      ),
      ...visit.appointmentSignatures.map((signature) => {
        return signature.attestation
          ? lexicalToHTML(convertParagraphToLexical(signature.attestation))
          : null
      }),
      ...organSystems.map((system) => {
        return lexicalToHTML(convertParagraphToLexical(physicalExam[system]))
      }),
    ]).then((res) => {
      const [
        attestation,
        historyPresentIllness,
        developmentalSurveillance,
        assessment,
        planOfCare,
        patientEducationAdditionalNotes,
        patientEducationAdolescentConfidential,
        documentationAdolescentConfidential,
        pastMedicalHistory,
        ...rest
      ] = res

      const signatures = rest.slice(0, visit.appointmentSignatures.length)
      const appointmentSignatures = {}
      visit.appointmentSignatures.forEach((signature, index) => {
        return (appointmentSignatures[signature.id] = {
          attestationHTML: signatures[index],
        })
      })

      const physicalExams = rest.slice(visit.appointmentSignatures.length)
      const peObject = {}
      physicalExams.forEach((html, index) => {
        return (peObject[organSystems[index]] = html)
      })

      const htmlStrings: HtmlStrings = {
        attestation: visit.attestation ? attestation : null,
        historyPresentIllness: visit.historyPresentIllness
          ? historyPresentIllness
          : null,
        developmentalSurveillance: visit.developmentalSurveillance
          ? developmentalSurveillance
          : null,
        assessment: visit.assessment ? assessment : null,
        planOfCare: visit.planOfCare ? planOfCare : null,
        patientEducationAdditionalNotes: visit.patientEducationAdditionalNotes
          ? patientEducationAdditionalNotes
          : null,
        patientEducationAdolescentConfidential:
          visit.patientEducationAdolescentConfidential
            ? patientEducationAdolescentConfidential
            : null,
        documentationAdolescentConfidential:
          visit.documentationAdolescentConfidential
            ? documentationAdolescentConfidential
            : null,
        pastMedicalHistory: visit.patient.pastMedicalHistory
          ? pastMedicalHistory
          : null,
        appointmentSignatures: appointmentSignatures,
        physicalExam: peObject,
      }

      resolve(htmlStrings)
    })
  })
}
