import { useState } from 'react'

import { getBase64ContentFromDataUri } from 'common/utils'
import { jsPDF as JsPDF } from 'jspdf'
import { useParams } from 'react-router-dom'
import { FindOrderToPrint } from 'types/graphql'

import { navigate } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'

import Button from 'src/components/atoms/Button'
import EFaxIcon from 'src/components/icons/EFaxIcon/EFaxIcon'
import { sidepanelRoute } from 'src/lib/routes'
import PrintOrderDocument from 'src/pdf/components/PrintOrderDocument'
import { useGeneratePDF } from 'src/pdf/useGeneratePDF'

export const UPLOAD_ORDER_BINARY_MUTATION = gql`
  mutation UploadOrderBinary($input: SaveOrderBinaryInput!) {
    saveOrderBinary(input: $input) {
      id
    }
  }
`

const usePrepareOrderForFaxing = ({
  order,
}: {
  order: FindOrderToPrint['order']
}): [() => Promise<{ id: string }>, { isLoading: boolean }] => {
  const [uploadOrderBinaryMutation] = useMutation(UPLOAD_ORDER_BINARY_MUTATION)
  const [isLoading, setIsLoading] = useState(false)
  const generatePDF = useGeneratePDF()

  const genPdfPromise = (): Promise<JsPDF> => {
    return new Promise((resolve) => {
      generatePDF({
        component: <PrintOrderDocument order={order} />,
        callback: async (doc) => {
          resolve(doc)
        },
      })
    })
  }

  const uploadOrderBinary = async () => {
    setIsLoading(true)
    const doc = await genPdfPromise()

    const binaryString = getBase64ContentFromDataUri(
      doc.output('datauristring')
    )

    const result = await uploadOrderBinaryMutation({
      variables: {
        input: {
          orderId: order.id,
          binaryString,
        },
      },
    })

    setIsLoading(false)

    return { id: result.data.saveOrderBinary.id }
  }

  return [uploadOrderBinary, { isLoading }]
}

interface FaxOrderButtonProps {
  order: FindOrderToPrint['order']
}

const FaxOrderButton = ({ order }: FaxOrderButtonProps) => {
  const [prepareOrderForFaxing, { isLoading: isPreparingForFaxing }] =
    usePrepareOrderForFaxing({ order })

  const { patientId } = useParams()

  const openFaxSidepanel = async () => {
    const { id: binaryId } = await prepareOrderForFaxing()

    navigate(
      sidepanelRoute(
        {
          route: '/send-fax',
          primaryDocument: JSON.stringify({
            name: `order ${order.name}`,
            binaryId,
            documentType: 'BINARY',
          }),
          orderId: order.id,
          patientId,
        },
        location
      )
    )
  }
  return (
    <Button
      testId="fax-order"
      icon={EFaxIcon}
      loading={isPreparingForFaxing}
      buttonStyle="secondary"
      onClick={openFaxSidepanel}
      text="E-Fax"
    />
  )
}

export default FaxOrderButton
